/* eslint-disable indent */
/* eslint-disable max-len */
import { Image } from 'antd';
import { useState } from 'react';
import SignupForm from './components/SignupForm';
import Success from './components/Success';
import VerifyForm from './components/VerifyForm';
import Images from '../../assests/Images/Image';

export const SIGNUP_FORM_STATUS = {
    SIGN_UP: 'sign up',
    VERIFY: 'verify',
    SUCCESS: 'success',

};
const Signup = () => {

    const [formStatus, setFormStatus] = useState(SIGNUP_FORM_STATUS.SIGN_UP);
    const [imageURL, setImageURL] = useState('');

    const getBody = () => {
        switch (formStatus) {
            case SIGNUP_FORM_STATUS.SIGN_UP:
                return <SignupForm imageURL={imageURL} setImageURL={setImageURL} onFormSubmit={() => { setFormStatus(SIGNUP_FORM_STATUS.VERIFY); }} />;
            case SIGNUP_FORM_STATUS.VERIFY:
                return <VerifyForm onFormVerify={() => { setFormStatus(SIGNUP_FORM_STATUS.SUCCESS); }} />;
            case SIGNUP_FORM_STATUS.SUCCESS:
                return <Success />;
        }
    };
    console.log(imageURL);


    return (
        <div className='login'>
            <div className='login-image-container'>
                <Image className='login-image' preview={false}
                    src={Images.banner2} />
            </div>
            <div>
                {getBody()}
            </div>
            {/* <div className="signature flex-start">
                <p>2023 © Powered by <a className='site-name' target="_blank" href='http://www.ideaboost.tech' rel="noreferrer">IdeaBoost</a></p>
            </div> */}
        </div>
    );
};

export default Signup;