/* eslint-disable max-len */
import { Image } from 'antd';
import LoginForm from './componets/LoginForm';
import Images from '../../assests/Images/Image';

const Login = () => {
    return (
        <>
            <div className='login'>
                <div>
                    <LoginForm />
                </div>
                <div className='login-image-container'>
                    <Image className='login-image' preview={false} src={Images.banner} />
                </div>
                {/* <div className="signature flex-start">
                    <p>2023 © Powered by <a className='site-name' target="_blank" href='http://www.ideaboost.tech' rel="noreferrer">IdeaBoost</a></p>
                </div> */}
            </div>
        </>
    );
};

export default Login;