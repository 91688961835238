/* eslint-disable max-len */
import { Row, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;
const Footer = () => {
    return (
        <Row className='footer'>
            <Text>2023 © Powered by <a className='site-name' target="_blank" 
            href='http://www.ideaboost.tech' 
            rel="noreferrer">iManage</a></Text>
        </Row>
    );
};

export default Footer;