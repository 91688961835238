/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Card, Checkbox, Col, ConfigProvider, Divider, Form, Input, Modal, Row, Table, Typography } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import React, { useContext, useEffect, useState } from 'react';
import { TaskContext } from '../../../../context';
import { GTask, ITask } from '../../../../utils/types';

const { Text } = Typography;

const onChange: TableProps<ITask>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
};

type jobAddTaskTableProps = {
    siteId: number | null | undefined
}
const JobAddTaskTable = (props: jobAddTaskTableProps) => {

    const { siteId } = props;
    const [filteredTasks, setFilteredTasks] = useState<ITask[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState<any>({
        area: '',
        task: '',
        outcome: '',
    });

    const { tasks, selectJobTasks, setSelectManyJobTasks, setSelectedManyJobTasks, selectedJobTasks, resetSelectedJobTasks } = useContext(TaskContext);

    useEffect(() => {
        const filtered = tasks.filter((task) => { return task.siteId === siteId; });
        setFilteredTasks(filtered);
        resetSelectedJobTasks();
    }, [tasks, siteId]);


    const addTaskFormFields = [
        {
            name: 'area',
            label: 'Area',
            rules: [{ required: true, message: 'Please input the area!' }],
        },
        {
            name: 'task',
            label: 'Task',
            rules: [{ required: true, message: 'Please input the task!' }],
        },
        {
            name: 'outcome',
            label: 'Outcome',
            rules: [{ required: true, message: 'Please input the outcome!' }],
        },
    ];

    useEffect(() => {
        const taskList = filteredTasks.map(({ area, task, outcome }) => {
            return {
                area,
                task,
                outcome,
            };
        })
        setSelectManyJobTasks(taskList);
    }, [filteredTasks]);

    const handleAddTask = () => {
        const newTask: GTask = {
            area: formData.area,
            task: formData.task,
            outcome: formData.outcome,
        };
        const updatedTasks = [...selectJobTasks, newTask];
        console.log("Add Task", updatedTasks);
        setSelectManyJobTasks(updatedTasks);
        setSelectedManyJobTasks([...selectedJobTasks, newTask])
        setSelectedRowKeys([...selectedRowKeys, newTask.task]);
        form.resetFields();
        setFormData({
            area: '',
            task: '',
            outcome: '',
        })
        setIsModalVisible(false);
    };

    const addTaskModal = () => {
        return (
            <Modal
                title="Add Task"
                visible={ isModalVisible }
                okText="Submit"
                onOk={ handleAddTask }
                onCancel={ () => {
                    setIsModalVisible(false);
                } }
                centered={ true }
            >
                <Form
                    layout="horizontal"
                    form={ form }
                    labelCol={ { span: 6 } }
                    wrapperCol={ { span: 17 } }
                    className="modal-form"
                    onValuesChange={ (changedValues) => {
                        setFormData((prevData: any) => { return { ...prevData, ...changedValues }; });
                    } }
                >
                    { addTaskFormFields.map((field) => {
                        return (
                            <Form.Item
                                key={ field.name }
                                name={ field.name }
                                label={ field.label }
                                rules={ field.rules }
                            >
                                <Input />
                            </Form.Item>
                        );
                    }) }
                </Form>
            </Modal>
        );
    };

    const columns: ColumnsType<any> = [
        {
            title: 'Area',
            dataIndex: 'area',
            defaultSortOrder: 'descend',
            width: '25%',
            align: 'left',
        },
        {
            title: 'Task',
            dataIndex: 'task',
            defaultSortOrder: 'descend',
            width: '25%',
        },
        {
            title: 'Outcome',
            dataIndex: 'outcome',
            defaultSortOrder: 'descend',
            width: '40%',
        },
    ];

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);

        const selectedTasks = selectJobTasks.filter((task) => { return newSelectedRowKeys.includes(task.task); },
        );
        setSelectedManyJobTasks(selectedTasks);
    };

    const rowSelection: TableRowSelection<ITask> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <>
            <Row className='devider'>
                <Col lg={ 20 } sm={ 16 } xs={ 16 }>
                    <Divider orientation="left" style={ { fontSize: '18px' } }>
                        Task List
                    </Divider>
                </Col>
                <Col lg={ 4 } sm={ 6 } xs={ 6 }>
                    <Button className='view-btn' onClick={ () => { return setIsModalVisible(true); } }>Add Task</Button>
                </Col>
            </Row>
            <Row>
                <div className='other-table job-task-table'>
                    <ConfigProvider theme={ { token: { colorPrimary: '#5a82f9' } } }>
                        <Table columns={ columns }
                            onChange={ onChange }
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            dataSource={ selectJobTasks }
                            pagination={ false }
                            size={ 'small' }
                            rowSelection={ rowSelection }
                            rowKey="task" />
                        { addTaskModal() }
                        <div className='table-card-list'>
                            { filteredTasks.map((item: ITask) => {
                                return (
                                    <Card key={ item.taskId } className="table-card" bordered={ true }>
                                        <Checkbox > </Checkbox>
                                        <Row className='table-card-row'>
                                            <Col span={ 10 }>
                                                <Text>Area:</Text>
                                            </Col>
                                            <Col span={ 14 }>
                                                <Text>{ item.area }</Text>
                                            </Col>
                                        </Row>
                                        <Row className='table-card-row'>
                                            <Col span={ 10 }>
                                                <Text>Task:</Text>
                                            </Col>
                                            <Col span={ 14 }>
                                                <Text>{ item.task }</Text>
                                            </Col>
                                        </Row>
                                        <Row className='table-card-row'>
                                            <Col span={ 10 }>
                                                <Text>Outcome:</Text>
                                            </Col>
                                            <Col span={ 14 }>
                                                <Text>{ item.outcome }</Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                );
                            }) }
                        </div>
                    </ConfigProvider>
                </div >
            </Row >
        </>);
};

export default JobAddTaskTable;
