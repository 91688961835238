/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { Modal } from 'antd';
import { useMemo, useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover } from '@reach/combobox';
import '@reach/combobox/styles.css';
import { is } from '@babel/types';

type locationModalProps = {
    locationModalOpen: boolean;
    setLocationModalOpen: (value: boolean) => void;
    setLocationList: (value: any) => void;
    locationList: any;
    rowIndex: number;
};


function LocationModal(props: locationModalProps) {

    const { locationModalOpen, setLocationModalOpen, locationList, setLocationList, rowIndex } = props;

    const [address, setAddress] = useState(null);
    const [coordinates, setCoordinates] = useState(null);
    const [link, setLink] = useState(null);

    const [isSelected, setIsSelected] = useState(false);

    // console.log(address, coordinates, link); // 👈🏻👈🏻👈🏻 location data

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
        libraries: ['places'],
    });


    const handleModelOk = () => {
        if (isSelected) {
            if (locationList[rowIndex] === undefined) {
                setLocationList([...locationList, { address, coordinates, link }]);
            } else {
                const temp = locationList;
                temp[rowIndex] = { address, coordinates, link };
                setLocationList(temp);
            }
            setLocationModalOpen(false);
        }
    };

    const handleModelCancel = () => {
        if (isSelected) {
            const temp = locationList.filter((item: any) => { return item.address !== address; });
            setLocationList(temp);
        }
        setLocationModalOpen(false);

    };


    return (
        <Modal
            open={locationModalOpen}
            onOk={handleModelOk}
            onCancel={handleModelCancel}
            className='location-modal'
            title='Location'

        >
            {isLoaded ? <Map setAddress={setAddress} coordinates={coordinates} setCoordinates={setCoordinates} setLink={setLink} isSelected={isSelected} setIsSelected={setIsSelected} /> : null}

        </Modal>
    );
}

type MapPropsTypes = {
    setAddress: (value: any) => void;
    coordinates: any;
    setCoordinates: (value: any) => void;
    setLink: (value: any) => void;
    isSelected: boolean;
    setIsSelected: (value: boolean) => void;
};

function Map(props: MapPropsTypes) {
    const { setAddress, coordinates, setCoordinates, setLink, isSelected, setIsSelected } = props;

    const center = useMemo(() => { return { lat: -33.8688, lng: 151.2093 }; }, []);


    const handleMapClick = async (e: any) => {
        const { latLng } = e;
        const lat = latLng.lat();
        const lng = latLng.lng();
        setCoordinates({ lat, lng });

        const results = await getGeocode({ location: { lat, lng } });
        const { formatted_address } = results[0];

        setAddress(formatted_address);
        setLink(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`);

        setIsSelected(true);

    };

    return (
        <div className='new-stacking-context'>
            {/* {
                !isSelected &&
                <div className="warning">
                    <p>Please select a location!</p>
                </div>
            } */}
            <div className="auto-complete">
                <PlacesAutocomplete setCoordinates={setCoordinates} setAddress={setAddress} setLink={setLink} setIsSelected={setIsSelected} />
            </div>
            <GoogleMap onClick={(e) => { handleMapClick(e); }} zoom={10} center={coordinates ?? center} mapContainerClassName='map'>
                {coordinates && <Marker position={coordinates} />}
            </GoogleMap>
        </div>
    );

}

type PlacesAutocompleteTypes = {
    setCoordinates: (value: any) => void;
    setAddress: (value: any) => void;
    setLink: (value: any) => void;
    setIsSelected: (value: boolean) => void;
};

function PlacesAutocomplete(props: PlacesAutocompleteTypes) {
    const { setCoordinates, setAddress, setLink, setIsSelected } = props;

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address: any) => {
        setValue(address, false);
        clearSuggestions();

        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        setCoordinates({ lat, lng });
        setAddress(address);
        setLink(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`);
        setIsSelected(true);

    };

    return (
        <Combobox onSelect={handleSelect}>
            <div className="combobox-input-control">
                <label>Search address </label>
                <ComboboxInput
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    disabled={!ready}
                    placeholder="Enter an address"
                    className='combobox-input'
                ></ComboboxInput>
            </div>
            <ComboboxPopover>
                <ComboboxList
                    className='combobox-list'
                >
                    {status === 'OK' && data.map(({ place_id, description }) => {
                        return (
                            <ComboboxOption key={place_id} value={description} />
                        );
                    })}
                </ComboboxList>
            </ComboboxPopover>
        </Combobox>
    );
}

export default LocationModal;