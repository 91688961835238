import axios from 'axios';
import { baseUrl } from '../../config/config';

interface UserData {
    userType: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    contactNo: string;
    businessName: string;
    businessLogo: string;
    abn: string;
}

export const userSignup = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    businessName: string,
    businessLogo: string,
    abn: string,
    contactNo: string,
): Promise<any> => {
    const userData: UserData = {
        userType: 'manager',
        email,
        password,
        firstName,
        lastName,
        contactNo,
        businessName,
        businessLogo,
        abn,
    };

    try {
        const response = await axios.post(`${baseUrl}/users`, userData);
        return response.data;
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
};
export const userVerifyEmail = async (email: string, otp: string) => {
    const userData = {
        email,
        otp,
    };

    try {
        const response = await axios.post(`${baseUrl}/users/verify`, userData);
        return response.data;
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
};

