/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable max-len */
import {
    BellOutlined, CaretDownOutlined, CloseOutlined, ExclamationCircleFilled,
    LogoutOutlined, MenuOutlined, UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Col, ConfigProvider, Drawer, Dropdown, Layout, Menu, Modal, Row, Typography, type MenuProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { AiOutlineBarChart, AiOutlineFileSearch } from 'react-icons/ai';
import { BiBuildings } from 'react-icons/bi';
import { FiUsers } from 'react-icons/fi';
import { GoLocation, GoTasklist } from 'react-icons/go';
import { MdWorkOutline } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import Image from '../../assests/Images/Image';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useLogout } from '../../hooks/useLogout';
import { AddClient, AddJob, AddStaff, AddTask, Analytics, ClientView, Clients, Jobs, JobsView, MENU_KEYS, Notifications, Profile, Reports, SiteJobs, Staff, Task } from './index';
import ContactSection from '../ContractManager/components/contactBusiness/ContactSection';


const { confirm } = Modal;

const Dashboard = () => {

    const { Header, Content } = Layout;
    const { Text } = Typography;
    const [selectedMenu, setSelectedMenu] = useState(MENU_KEYS.JOBS);
    const [selectedMenuItem, setSelectedMenuItem] = useState(MENU_KEYS.REPORTS);
    const [collapsed, setCollapsed] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);

    const navigate = useNavigate();
    const isTab = useMediaQuery({ maxWidth: 820 });
    const isMobile = useMediaQuery({ maxWidth: 576 });
    const [collapedMenu, setCollapedMenu] = useState(true);
    const { state } = useAuthContext();
    const { user } = state;
    const userType = user.type;
    const { logout } = useLogout();
    const logOutMenu = (
        <Menu>
            <ConfigProvider theme={{ token: { colorPrimary: '#5a82f9' } }}>
                <Menu.Item onClick={() => { setSelectedMenu(MENU_KEYS.PROFILE); }}><UserOutlined /> Profile</Menu.Item>
                {/* <Menu.Item
                    onClick={() => { setSelectedMenu(MENU_KEYS.NOTIFICATIONS); }}><BellOutlined /> Notifications</Menu.Item> */}
                <Menu.Item onClick={() => { showSignOutConfirm(); }}><LogoutOutlined /> Sign Out</Menu.Item>
            </ConfigProvider>
        </Menu>
    );

    useEffect(() => {
        if (isTab) {
            setCollapedMenu(false);
            setCollapsed(true);
        }
    }, [isTab]);

    const sidebar: MenuProps['items'] = [
        // {
        //     key: MENU_KEYS.ANALYTICS,
        //     icon: <AiOutlineBarChart />,
        //     label: MENU_KEYS.ANALYTICS,
        // },
        {
            key: MENU_KEYS.JOBS,
            icon: <MdWorkOutline />,
            label: MENU_KEYS.JOBS,
        },
        {
            key: MENU_KEYS.CLIENTS,
            icon: <BiBuildings />,
            label: MENU_KEYS.CLIENTS,
        },
        {
            key: MENU_KEYS.STAFF,
            icon: <FiUsers />,
            label: MENU_KEYS.STAFF,
        },
        {
            key: MENU_KEYS.TASK_LIST,
            icon: <GoTasklist />,
            label: MENU_KEYS.TASK_LIST,
        },
        {
            key: MENU_KEYS.AUDITREPORTS,
            icon: <GoLocation />,
            label: MENU_KEYS.AUDITREPORTS,
        },
        {
            key: MENU_KEYS.REPORTS,
            icon: <AiOutlineFileSearch />,
            label: MENU_KEYS.REPORTS,
        },
        //add for testing after testing remove
        // {
        //     key: MENU_KEYS.CONTACT,
        //     icon: <AiOutlineFileSearch />,
        //     label: MENU_KEYS.CONTACT,
        // },


        // Return the modified sidebar based on user.userType
        // Modify the array as per your specific conditions and requirements
        // return modifiedSidebar;
    ];
    const [sidebarItems, setsidebarItems] = useState<MenuProps['items'] | undefined>(sidebar);

    const mobileSidebarItems: MenuProps['items'] = [
        // {
        //     key: MENU_KEYS.ANALYTICS,
        //     icon: <AiOutlineBarChart />,
        // },
        {
            key: MENU_KEYS.JOBS,
            icon: <MdWorkOutline />,
        },
        {
            key: MENU_KEYS.CLIENTS,
            icon: <BiBuildings />,
        },
        {
            key: MENU_KEYS.STAFF,
            icon: <FiUsers />,
        },
        {
            key: MENU_KEYS.TASK_LIST,
            icon: <GoTasklist />,
        },
        {
            key: MENU_KEYS.REPORTS,
            icon: <AiOutlineFileSearch />,
        },
    ];

    const onMenuClick: MenuProps['onClick'] = (e) => {
        setSelectedMenu(e.key);
    };

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const handleDashboardBread = () => {
        setSelectedMenu(MENU_KEYS.JOBS);
    };

    useEffect(() => {
        if (selectedMenu === MENU_KEYS.ADD_JOB) {
            setSelectedMenuItem(MENU_KEYS.JOBS);
        } else if (selectedMenu === MENU_KEYS.VIEWJOB) {
            setSelectedMenuItem(MENU_KEYS.JOBS);
        } else if (selectedMenu === MENU_KEYS.ADD_CLIENT) {
            setSelectedMenuItem(MENU_KEYS.CLIENTS);
        } else if (selectedMenu === MENU_KEYS.VIEWCLIENT) {
            setSelectedMenuItem(MENU_KEYS.CLIENTS);
        }
        else if (selectedMenu === MENU_KEYS.ADD_STAFF) {
            setSelectedMenuItem(MENU_KEYS.STAFF);
        }
        else if (selectedMenu === MENU_KEYS.ADD_TASK) {
            setSelectedMenuItem(MENU_KEYS.TASK_LIST);
        } else if (selectedMenu === MENU_KEYS.PROFILE || selectedMenu === MENU_KEYS.NOTIFICATIONS) {
            setSelectedMenuItem(MENU_KEYS.ANALYTICS);
        }
        //add for testing after testing remove
        // else if (selectedMenu == MENU_KEYS.CONTACT) {
        //     setSelectedMenuItem(MENU_KEYS.CONTACT);
        // }
        else {
            setSelectedMenuItem(selectedMenu);
        }
    }, [selectedMenu]);

    const handleReportSuccess = () => {
        setSelectedMenu(MENU_KEYS.REPORTS);
    }
    

    const getBody = () => {
        switch (selectedMenu) {
            case MENU_KEYS.ANALYTICS:
                return <Analytics onBreadClick={ handleDashboardBread } />;
            case MENU_KEYS.REPORTS:
                return <Reports onBreadClick={ handleDashboardBread } />;
            case MENU_KEYS.JOBS:
                return <Jobs onBreadClick={ handleDashboardBread } onViewJobClicked={ () => { setSelectedMenu(MENU_KEYS.VIEWJOB); } } onAddJobClick={ () => { setSelectedMenu(MENU_KEYS.ADD_JOB); } } />;
            case MENU_KEYS.VIEWJOB:
                return <JobsView onJobBreadClick={ () => { setSelectedMenu(MENU_KEYS.JOBS); } } />;
            case MENU_KEYS.ADD_JOB:
                return <AddJob onBreadClick={ handleDashboardBread } onJobClick={ () => { setSelectedMenu(MENU_KEYS.JOBS); } } />;
            case MENU_KEYS.CLIENTS:
                return <Clients onBreadClick={ handleDashboardBread } onViewClientClicked={ () => { setSelectedMenu(MENU_KEYS.VIEWCLIENT); } } onAddClientClick={ () => { return setSelectedMenu(MENU_KEYS.ADD_CLIENT); } } />;
            case MENU_KEYS.VIEWCLIENT:
                return <ClientView onBreadClick={ handleDashboardBread } onClientBreadClick={ () => { setSelectedMenu(MENU_KEYS.CLIENTS); } } />;
            case MENU_KEYS.ADD_CLIENT:
                return <AddClient onBreadClick={ handleDashboardBread } onClientClick={ () => { setSelectedMenu(MENU_KEYS.CLIENTS); } } />;
            case MENU_KEYS.STAFF:
                return <Staff onBreadClick={ handleDashboardBread } onAddStaffClick={ () => { setSelectedMenu(MENU_KEYS.ADD_STAFF); } } />;
            case MENU_KEYS.AUDITREPORTS:
                return <SiteJobs onBreadClick={ handleDashboardBread } onReportCreated={ handleReportSuccess } />;
            case MENU_KEYS.NOTIFICATIONS:
                return <Notifications />;
            case MENU_KEYS.PROFILE:
                return <Profile />;
            case MENU_KEYS.ADD_STAFF:
                return <AddStaff onBreadClick={ handleDashboardBread } onStaffClick={ () => { return setSelectedMenu(MENU_KEYS.STAFF); } } />;
            case MENU_KEYS.TASK_LIST:
                return <Task onBreadClick={ handleDashboardBread } onAddTaskClick={ () => { return setSelectedMenu(MENU_KEYS.ADD_TASK); } } onTaskEditClick={ () => { return setSelectedMenu(MENU_KEYS.ADD_TASK); } } />;
            case MENU_KEYS.ADD_TASK:
                return <AddTask onBreadClick={handleDashboardBread} onTaskClick={() => { return setSelectedMenu(MENU_KEYS.TASK_LIST); }} />;
            //add for testing after testing remove
            // case MENU_KEYS.CONTACT:
            //     return <ContactSection  />;
            default:
                return <></>;
        }
    };

    const showSignOutConfirm = () => {
        confirm({
            title: 'Are you sure you want to sign out?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk () {
                logout();
                navigate('/');
            },
            onCancel () {
                console.log('Cancel');
            },
        });
    };

    return (
        <Layout className='dashboard'>
            <Header className="header">
                <div>
                    { isMobile ?
                        <Button className='icon-dark' onClick={ () => { setShowDrawer(true); } } type="text"><MenuOutlined /> 
                        <img src={ Image.KCWeb } className='header-logo-mobile' alt='logo' style={ { cursor: 'pointer' } } onClick={ () => { setShowDrawer(true); } } />
                        </Button>
                        :
                        <img src={Image.KCWeb} className='header-logo' alt='logo' style={{ cursor: 'pointer' }} onClick={() => { navigate('/'); }} />
                    }
                </div>
                <Dropdown overlay={ logOutMenu }>
                    <Text className='usr-profile'>
                        <Avatar size="large" src={ Image.avatar } />
                        { !isMobile && (
                            <Text style={ { color: '#111111', marginLeft: '10px' } }>   
                                { user.manager?.firstName } { user.manager?.lastName }
                                 <CaretDownOutlined />
                            </Text>) }</Text>
                </Dropdown>
            </Header>
            <Layout>
                <Row className='layout-row'>
                    { isMobile &&
                        <ConfigProvider theme={ { token: { colorPrimary: '#5a82f9' } } }>
                            <Drawer
                                width='280px'
                                open={ showDrawer }
                                placement='left'
                                onClose={ () => { setShowDrawer(false); } }
                            >
                                <div>
                                    <img src={ Image.KCWeb } className='mobile-sidebar-logo' alt='logo' style={ { cursor: 'pointer' } } onClick={ () => { navigate('/'); } } />
                                </div>
                                <Menu
                                    className='mobile-sidebar-menu'
                                    mode="inline"
                                    items={ sidebar }
                                    selectedKeys={ [selectedMenuItem] }
                                    onClick={ (e) => {
                                        setShowDrawer(false);
                                        onMenuClick(e);
                                    } }

                                >
                                </Menu>
                            </Drawer>
                        </ConfigProvider>
                    }
                    <Col className='sidebar'>
                        { collapedMenu && <>{
                            collapsed ? (<Button type='text' className='sidebar-btn' onClick={ toggleCollapsed }>
                                <MenuOutlined />
                            </Button>) :
                                (<Button type='text' className='sidebar-btn'
                                    style={ { float: 'right', marginRight: '.5em' } }
                                    onClick={ toggleCollapsed }>
                                    <CloseOutlined />
                                </Button>) }</> }
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={ ['Reports'] }
                            style={ { height: '100vh', borderRight: 0 } }
                            items={ sidebarItems }
                            className='sidebar'
                            onClick={ onMenuClick }
                            inlineCollapsed={ collapsed }
                            selectedKeys={ [selectedMenuItem] }
                        />
                    </Col>
                    <Layout className='dashboard-layout'>
                        <Content
                            className="site-layout-background"
                        >
                            { getBody() }
                        </Content>
                    </Layout>
                </Row>
            </Layout>
        </Layout>
    );
};

export default Dashboard;


