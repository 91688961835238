import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import apiClient from '../utils/apiClient';

interface AnalyticsContextValue {
    staffCount: number;
    clientCount: number;
    siteCount: number;
}

export const AnalyticsContext = createContext<AnalyticsContextValue>({
    staffCount: 0,
    clientCount: 0,
    siteCount: 0,
  
});

type AnalyticsContextProviderProps = {
    children: ReactNode;
};

export const AnalyticsProvider = ({ children }: AnalyticsContextProviderProps) => { 
    const [staffCount, setStaffCount] = useState<number>(0);
    const [clientCount, setClientCount] = useState<number>(0);
    const [siteCount, setSiteCount] = useState<number>(0);
    const { state } = useAuthContext();
    const { user } = state;

    useEffect(() => {
        if (user) {
            // fetchAnalytics();
        }
    }, [user]);

    const fetchAnalytics = async () => {
        try {
            const response = await apiClient.get('/analytics/counts');
            setStaffCount(response.data.staffCount);
            setClientCount(response.data.clientCount);
            setSiteCount(response.data.siteCount);
        } catch (error) {
            console.error('Error fetching Analytics:', error);
        }
    };

    const contextValue = useMemo(() => {
        return {
            staffCount,
            clientCount,
            siteCount,
            
        };
    }, [staffCount, clientCount, siteCount]);

    return (
        <AnalyticsContext.Provider value={contextValue}>
            {children}
        </AnalyticsContext.Provider>
    );
};
