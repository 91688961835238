/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Card, Col, Collapse, Form, Layout, Modal, Row, Switch, Tag, Typography } from 'antd';
import Footer from './Footer';

import { SettingOutlined } from '@ant-design/icons';
import { useState } from 'react';

const { Title } = Typography;
const { Panel } = Collapse;

const notifications = [
    {
        type: 'Completed',
        title: 'This Job is Completed By John at Wooles Sydney Site',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero eget nunc accum Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero eget nunc accum  ',
        client: 'Wooles',
        siteName: 'John Smith',
        staff: 'John Smith',
        date: '2023-04-23',
        time: '08.30PM',
        status: 'unread',
    },
    {
        type: 'Scheduled',
        title: 'This Job is Schedulded By John at Wooles Sydney Site',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero eget nunc accum Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero eget nunc accum',
        client: 'Wooles',
        siteName: 'John Smith',
        staff: 'John Smith',
        date: '2023-04-23',
        time: '08.30PM',
        status: 'read',
    },
    {
        type: 'Canceled',
        title: 'This Job is Canceled By John at Wooles Sydney Site',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero eget nunc accum Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero eget nunc accum',
        client: 'Wooles',
        siteName: 'John Smith',
        staff: 'John Smith',
        date: '2023-04-23',
        time: '08.30PM',
        status: 'unread',
    },
    {
        type: 'Canceled',
        title: 'This Job is Canceled By John at Wooles Sydney Site',
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero eget nunc accum Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero eget nunc accum',
        client: 'Wooles',
        siteName: 'John Smith',
        staff: 'John Smith',
        date: '2023-04-23',
        time: '08.30PM',
        status: 'unread',
    },
];


const Notifications = () => {

    const [settingsModalOpen, setSettingsModalOpen] = useState(false);


    const handleSettingsModalOk = () => {
        setSettingsModalOpen(false);
    };

    const getExtra = (status: string) => {
        if (status === 'Completed') {
            return (
                <Tag color='green'>
                    Completed
                </Tag>
            );
        } else if (status === 'Scheduled') {
            return (
                <Tag color='yellow'>
                    Scheduled
                </Tag>
            );
        } else if (status === 'Canceled') {
            return (
                <Tag color='red'>
                    Canceled
                </Tag>
            );
        }
    };
    return (
        <Layout className='report-card'>

            <Modal
                open={settingsModalOpen}
                onCancel={() => { setSettingsModalOpen(false); }}
                onOk={() => { handleSettingsModalOk(); }}
                title="Notificaiton Settings"
                okText="Save"
            >
                <div className="settings">
                    <Form
                    >
                        <div className="content">
                            <p>Lorem ipsum dolor sit amet.</p>
                            <Form.Item
                            >
                                <Switch></Switch>
                            </Form.Item>
                        </div>
                        <div className="content">
                            <p>Lorem ipsum dolor sit amet.</p>
                            <Form.Item
                            >
                                <Switch></Switch>
                            </Form.Item>
                        </div>
                        <div className="content">
                            <p>Lorem ipsum dolor sit amet.</p>
                            <Form.Item
                            >
                                <Switch></Switch>
                            </Form.Item>
                        </div>
                        <div className="content">
                            <p>Lorem ipsum dolor sit amet.</p>
                            <Form.Item
                            >
                                <Switch></Switch>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Modal>



            <Row className='notification-page-title'>
                <Col>
                    <Title level={2}>Notifications</Title>
                </Col>
                <div className="all-complete">
                    <Button className='btn link' type="text">Mark as all read</Button>
                    <Button type='text' className='btn' onClick={() => { setSettingsModalOpen(true); }} ><SettingOutlined /></Button>
                </div>
            </Row>
            <div className='notification-tab'>

                <div className='notifications' >
                    {notifications.map((notification, index) => {
                        return (
                            <div className={notification.status === 'read' ? 'notification read' : 'notification'} key={index} >
                                <h1 className='heading'>{notification.title}</h1>
                                <div className="content">
                                    <p className='message'>{notification.message}</p>
                                    <p className='date-time'>On {notification.date} at {notification.time}</p>
                                </div>
                                <div className="status">
                                    {getExtra(notification.type)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Footer />

        </Layout>
    );
};

export default Notifications;