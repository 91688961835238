/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import {
    Breadcrumb,
    Col,
    Layout,
    Row,
    Typography,
} from 'antd';
import BarChart from './BarChart';
import PieChart from './PieChart';

import Footer from '../Others/Footer';
import { useContext } from 'react';
import {AnalyticsContext} from '../../../context'

const { Title } = Typography;

interface ReportProps {
    onBreadClick: () => void,
}

const Analytics = (props: ReportProps) => {

    const { onBreadClick } = props;
    const { staffCount, clientCount, siteCount } = useContext(AnalyticsContext);

    return (
        <Layout className='report-card report-component'>
            <Row>
                <Col>
                    <Title level={2}>Analytics</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={onBreadClick}>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item className='bread-active'>Analytics</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <div className='chart-component'>
                <div className='card-row'>
                    <div className="analytics-card">
                        <p>No of Staff</p>
                        <h1>{staffCount}</h1>
                    </div>

                    <div className="analytics-card">
                        <p>No of Clients</p>
                        <h1>{clientCount}</h1>
                    </div>

                    <div className="analytics-card">
                        <p>No of Sites</p>
                        <h1>{siteCount}</h1>
                    </div>
                </div>
                <Row className='chart-row'>
                    <Col span={12}>
                        <Title level={2} className='chart-title' >Task completion summary</Title>
                        <BarChart />
                    </Col>
                    <Col span={12}>
                        <Title level={2} className='chart-title' >Jobs Completed per Site</Title>
                        <PieChart />
                    </Col>
                </Row>
            </div>
            <Footer />
        </Layout>
    );
};

export default Analytics;