/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { PlusCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, DatePicker, Input, Layout, Row, Spin, Switch, Typography } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { JobContext } from '../../../context';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { IJob } from '../../../utils/types';
import Footer from '../Others/Footer';
import JobsTable from './components/JobsTable';


const { Title } = Typography;
const { Search } = Input;

type JobsProps = {
    onBreadClick: () => void,
    onViewJobClicked: () => void,
    onAddJobClick: () => void
}

const Jobs = (props: JobsProps) => {
    const { onViewJobClicked, onBreadClick, onAddJobClick } = props;

    const { jobs, allJobs } = useContext(JobContext);
    const { state } = useAuthContext();
    const { user } = state;
    const [searchValue, setsearchValue] = useState('');
    const [jobSource, setJobSource] = useState<IJob[]>([]);
    const [handleSource, setHandleSource] = useState(false);
    const [searchResult, setsearchResult] = useState<IJob[]>(jobSource);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (!handleSource) {
            setJobSource(jobs);
            setLoading(false);
        } else {
            if (allJobs.length > 0) {
                setJobSource(allJobs);
                setLoading(false);
            }
        }
    }, [allJobs, handleSource, jobs]);

    const onSearch = useCallback((value: string) => {
        setsearchValue(value);
        if (value === '') {
            setsearchResult(jobSource);
            return;
        }

        const temp: IJob[] = jobSource.filter((item: any) => {
            const fullName = `${item?.staffmember?.firstName} ${item?.staffmember?.lastName}`;
            // eslint-disable-next-line max-len
            return item?.client?.name?.toLowerCase().includes(value.toLowerCase()) || item?.site?.siteName?.toLowerCase().includes(value.toLowerCase()) || fullName.toLowerCase().includes(value.toLowerCase());
        });
        setsearchResult(temp);
    }, [jobSource]);

    useEffect(() => {
        onSearch(searchValue);
    }, [onSearch, searchValue]);

    const handleDateFilter = (date: any, dateString: string) => {
        const temp: IJob[] = jobSource.filter((item: any) => {
            const date = dayjs(item?.date).format('DD-MM-YYYY');
            // eslint-disable-next-line max-len
            return date.includes(dateString);
        });
        setsearchResult(temp);

    };

    return (
        <Layout className='report-card'>
            <div className='header-row'>
                <div className='header-col' >
                    <div className="breadcrumb">
                        <Title level={ 2 }>Jobs</Title>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={ onBreadClick }>Dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item className='bread-active'>Jobs</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='btn-con btn-show-md'>
                        { user?.type === 'manager' && <Button className='view-btn' onClick={ onAddJobClick }><PlusCircleOutlined size={ 24 } />Add Job</Button> }
                    </div>
                </div>
                <div className='header-col' >
                    <Search placeholder="Search by client name or site name or staff name"
                        onSearch={ onSearch }
                        onChange={ (e) => { return setsearchValue(e.target.value); } }
                        value={ searchValue }
                        enterButton />
                    <DatePicker onChange={ (date, dateString) => { handleDateFilter(date, dateString); } } defaultValue={ dayjs() } format={ 'DD-MM-YYYY' } className='date-picker btn-show-lg' style={ { width: '250px' } } />
                    <Switch checkedChildren='All' onChange={ () => setHandleSource(!handleSource) } />
                    <div className='btn-con btn-show-lg'>
                        { user?.type === 'manager' && <Button className='view-btn' onClick={ onAddJobClick }><PlusCircleOutlined size={ 24 } />Add Job</Button> }
                    </div>

                </div>
                <div className="header-col btn-show-md" style={ { justifyContent: 'flex-end' } }>
                    <DatePicker onChange={ (date, dateString) => { handleDateFilter(date, dateString); } } defaultValue={ dayjs() } format={ 'DD-MM-YYYY' } className='date-picker' />
                </div>
            </div>
            <Row >
                { loading ?
                    <Col className='spin-loader' span={ 24 }>
                        <Spin size="large" />
                    </Col>
                    : <JobsTable data={ searchResult } onViewClicked={ onViewJobClicked } /> }
            </Row>
            <Footer />

        </Layout>
    );
};

export default Jobs;