/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { CheckOutlined, CloseOutlined, EditOutlined, EnvironmentOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Form, Input, Layout, Row, Typography, notification } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ClientContext } from '../../../../context/ClientContext';

// import JobViewTaskTable from './JobViewTaskTable';

import Image from '../../../../assests/Images/Image';
import LocationModal from './LocationModal';

const { Title, Text } = Typography;

type JobsViewProps = {
    onBreadClick: () => void;
    onClientBreadClick: () => void;
};

type Location = {
    address: string,
    coordinates: {
        lat: number,
        lng: number,
    },
    link: string,
};

const ClientView = (props: JobsViewProps) => {
    const { client, updateClient, addSite } = useContext(ClientContext);
    const { onClientBreadClick, onBreadClick } = props;
    const [updateForm, setUpdateForm] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 576 });
    const [form] = Form.useForm();

    const [updateLocaitonForm, setUpdateLocationForm] = useState(false);
    const [profilePictureFile, setProfilePictureFile] = useState<any>(null);
    const [locationToEdit, setLocationToEdit] = useState(-1);
    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const [rowIndex, setRowIndex] = useState(0);
    const [locationList, setLocationList] = useState<Location[]>([]);
    const [locations, setlocations] = useState<any>([]);
    const [locationForm] = Form.useForm();

    console.log(client);
    const handleImageChange = async (info: any) => {
        console.log(info);
        try {
            // form.setFieldsValue({ profilePictureFile: info.file });
            setProfilePictureFile(info.file);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCancel = () => {
        form.setFieldsValue({
            abn: client?.abn,
            name: client?.name,
            email: client?.email,
            contactPerson: client?.contactPerson,
            contactNo: client?.contactNo,
        });

        setUpdateForm(false);
    };

    useEffect(() => {
        console.log("Client Sites", client?.site)
        setlocations(client?.site);
    }, [client]);

    const fields = client?.site?.map((site) => {
        return {
            key: site.siteId,
            clientName: client.name,
            siteName: site.siteName,
            siteAddress: site.siteAddress,
            locationLink: site?.locationLink
        };
    });


    const handleEdit = (index: number) => {
        setLocationToEdit(index);
    };

    const handleEditCancel = () => {
        setLocationToEdit(-1);
    };

    const handleSave = async(index: number) => {
        // handle Save
        console.log(index);
        try {
            await form.validateFields();
        } catch (error) {
            console.log('Form validation error:', error);
            return; // Block the function if form validation fails
        }
        const formData = form.getFieldsValue();
        const sites = formData.sights?.map((site: any, idx: number) => {
            return {
                siteName: site?.siteName,
                locationLink: locationList[idx] ? locationList[idx]?.link : site?.locationLink,
                address: locationList[idx] ? locationList[idx]?.address : site?.address,
            };
        })
        console.log('Sights form data:', sites);
        
       if(client?.clientId){
           const siteDetails = {
               siteName: sites[index]?.siteName,
               locationLink: sites[index]?.locationLink,
               siteAddress: sites[index]?.address,
               clientId: client?.clientId,
           }
           const response = addSite(client?.clientId, siteDetails)
          if(response){
              notification.success({
                  message: 'Success',
                  description: 'You have successfully added a site for your client.',
              });
          }
           
       }
        setLocationToEdit(-1);
    };

    const handleDelete = (index: number) => {
        // handle delete

    };

    useEffect(() => {
        form.setFieldsValue({
            abn: client?.abn,
            name: client?.name,
            email: client?.email,
            contactPerson: client?.contactPerson,
            contactNo: client?.contactNo,
        });
    }, [client, form]);

    const handleClientEdit = async () => {
        const { abn, name, contactPerson, contactNo, email } = form.getFieldsValue(['abn', 'name', 'contactPerson', 'contactNo', 'email']);

        if (client) {
            const { clientId, profilePicture, } = client;
            const clientDetails = {
                abn: abn,
                name: name,
                contactPerson: contactPerson,
                contactNo: contactNo,
                email: email,
                profilePicture: profilePicture,
            }
            const response = updateClient(clientId, clientDetails);
            notification.success({
                message: 'Success',
                description: 'You have successfully edited the client details.',
            });
        }
    }


    return (
        <Layout className="report-card">

            <div className='header-row'>
                <div className="header-col" >
                    <div className="breadcrumb">
                        <Title level={ 2 }>Client Details</Title>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={ onBreadClick }>Dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={ onClientBreadClick }>Clients</Breadcrumb.Item>
                            <Breadcrumb.Item className="bread-active">Client details</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {/* <div className='btn-con'>
                        <Button type="primary">Remove Client</Button>
                    </div> */}
                </div>
            </div>


            <Row className="">
                <Title level={ 4 } className=""></Title>
            </Row>
            <div className="client-profile-image-details">
                <div className="client-img-wrapper">
                    <div className="client-image">
                        {/* <img src={client?.profilePicture} alt="client" /> */ }
                        <img src={ Image?.KCWeb } style={ { width: '100%', height: '100%', objectFit: 'fill' } } alt="client" />
                    </div>
                    {/* <div className="change-picture">
                        <ImgCrop>
                            <Upload name="profilePictureFile" onChange={handleImageChange} accept="image/png, image/gif, image/jpeg">
                                <Button type="primary" className="add-btn">
                                    Change Picture
                                </Button>
                            </Upload>
                        </ImgCrop>
                    </div> */}
                </div>

                <div>
                    <Card className="space-3">
                        <Form
                            form={ form }
                            layout={ isMobile ? 'vertical' : 'horizontal' }
                        >
                            <Row className="mb-1">
                                <Col span={ 6 }>
                                    <Text className="client-card-label">ABN :</Text>
                                </Col>
                                <Col span={ 18 }>
                                    <Form.Item name="abn"
                                    // rules={[{ required: true, message: 'Please input the ABN!' }]}
                                    >
                                        <Input className="input-disabled" placeholder="Please enter the ABN!" disabled={ !updateForm } />
                                    </Form.Item>
                                    {/* <Text>{client?.abn}</Text> */ }
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col span={ 6 }>
                                    <Text className="client-card-label">Client Name:</Text>
                                </Col>
                                <Col span={ 18 }>
                                    <Form.Item name="name" rules={ [{ required: true, message: 'Please input the client name!' }] }>
                                        <Input
                                            className="input-disabled"
                                            placeholder="Please enter the client name!"
                                            disabled={ !updateForm }
                                        />
                                    </Form.Item>
                                    {/* <Text>{client?.name}</Text> */ }
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col span={ 6 }>
                                    <Text className="client-card-label">Contact Person:</Text>
                                </Col>
                                <Col span={ 18 }>
                                    <Form.Item
                                        name="contactPerson"
                                        rules={ [{ required: true, message: 'Please input the contact person!' }] }
                                    >
                                        <Input
                                            className="input-disabled"
                                            placeholder="Please enter the contact person!"
                                            disabled={ !updateForm }
                                        />
                                    </Form.Item>
                                    {/* <Text>{client?.contactPerson}</Text> */ }
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col span={ 6 }>
                                    <Text className="client-card-label">Contact Number:</Text>
                                </Col>
                                <Col span={ 18 }>
                                    <Form.Item name="contactNo" rules={ [{ required: true, message: 'Please input the constact number!' }] }>
                                        <Input
                                            className="input-disabled"
                                            placeholder="Please enter the contact number!"
                                            disabled={ !updateForm }
                                        />
                                    </Form.Item>
                                    {/* <Text>{client?.contactNo}</Text> */ }
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col span={ 6 }>
                                    <Text className="client-card-label">Email:</Text>
                                </Col>
                                <Col span={ 18 }>
                                    <Form.Item name="email" rules={ [{ required: true, message: 'Please input the email!' }] }>
                                        <Input className="input-disabled" placeholder="Please enter the email!" disabled={ !updateForm } />
                                    </Form.Item>
                                    {/* <Text>{client?.email}</Text> */ }
                                </Col>
                            </Row>
                            <Row className="mb-1 form-submit-row">
                                { updateForm ? (
                                    <div className="flex gap-2">
                                        <Button type="primary" className="add-btn" htmlType="submit" onClick={ handleClientEdit }>
                                            Update
                                        </Button>{ ' ' }
                                        &nbsp;&nbsp;
                                        <Button type="primary" danger className="add-btn" onClick={ handleCancel }>
                                            Cancel
                                        </Button>
                                    </div>
                                ) : (
                                    <Button
                                        type="primary"
                                        className="add-btn"
                                        onClick={ () => {
                                            return setUpdateForm(true);
                                        } }
                                    >
                                        Edit details
                                    </Button>
                                ) }
                            </Row>
                        </Form>
                    </Card>
                </div>
            </div>

            <hr></hr>
            <Card>

                <Form
                    form={ form }
                    layout={ isMobile ? 'vertical' : 'horizontal' }

                >
                    <Row className='dyanmic-form-input-row' style={ { flexDirection: 'column' } }>
                        <Form.List
                            initialValue={ fields }
                            name="sites"
                        >
                            {
                                (fields, { add, remove }) => {
                                    return (
                                        <>
                                            <div className="heading-add-client-form">
                                                <h1 className="">Site Details</h1>
                                            </div>
                                            { fields?.map((field, index) => {
                                                return (
                                                    <div key={ index } className='location-input' style={ { flexDirection: 'column' } } >
                                                        <div className='col'>
                                                            <label>Site Name</label>
                                                            <Form.Item
                                                                { ...field }
                                                                style={ { flexGrow: '1' } }
                                                                name={ [field.name, 'siteName'] }
                                                                rules={ [{ required: true, message: 'Please input Site Name here' }] }
                                                                wrapperCol={ { span: 24 } }

                                                            >
                                                                <Input
                                                                    className="input-disabled"
                                                                    disabled={ locationToEdit === index ? false : true }
                                                                    placeholder='Please enter site name here!' />
                                                            </Form.Item>
                                                        </div>

                                                        <div className="col">
                                                            <label>Location Link</label>
                                                            <div className="content">
                                                                <Form.Item
                                                                    { ...field }
                                                                    style={ { flexGrow: '1' } }
                                                                    name={ [field.name, 'locationLink'] }
                                                                    rules={ [{ required: true, message: 'Please input location link here' }] }
                                                                    wrapperCol={ { span: 24 } }

                                                                >
                                                                    <Input
                                                                        className="input-disabled"
                                                                        disabled={ locationToEdit === index ? false : true }
                                                                        placeholder='Please enter location link here!' />
                                                                </Form.Item>
                                                            </div>
                                                        </div>

                                                        <div className='col'>
                                                            <label>Address</label>
                                                            <div className="content">
                                                                <Form.Item
                                                                    { ...field }
                                                                    style={ { flexGrow: '1' } }
                                                                    name={ [field.name, 'siteAddress'] }
                                                                    rules={ [{ required: true, message: 'Please input address here' }] }
                                                                    wrapperCol={ { span: 24 } }
                                                                >
                                                                    <Input
                                                                        className="input-disabled"
                                                                        disabled={ locationToEdit === index ? false : true }
                                                                        placeholder='Please enter address here!' />
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    { index === locationToEdit ?
                                                                        <>
                                                                            <Button type="primary" danger 
                                                                            // onClick={ handleSave }
                                                                            ><CheckOutlined /></Button>
                                                                            <Button type="primary" danger style={ { marginLeft: '1rem' } } onClick={ handleEditCancel }><CloseOutlined /></Button>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Button onClick={ () => { handleEdit(index); } } type="primary"><EditOutlined /></Button>
                                                                            <Button type="primary" danger style={ { marginLeft: '1rem' } }
                                                                                onClick={ () => {
                                                                                    remove(field.name);
                                                                                    handleDelete(index);
                                                                                } }
                                                                            ><MinusCircleOutlined /></Button>
                                                                        </>
                                                                    }


                                                                </Form.Item>
                                                            </div>
                                                        </div>

                                                    </div>
                                                );

                                            }) }
                                        </>
                                    );
                                }
                            }

                        </Form.List>
                        <Form.List name="sights"
                        >
                            { (fields, { add, remove }) => {
                                return (
                                    <>
                                        <div>
                                            <Button className='add-site-button' type="primary" onClick={ () => {
                                                return add();
                                            } } >Add site</Button>
                                        </div>
                                        { fields.map((field, index) => {
                                            return (
                                                <div key={ field.key } className='location-input'>
                                                    <div className='col'>
                                                        <label>Site Name</label>
                                                        <Form.Item
                                                            { ...field }
                                                            style={ { flexGrow: '1' } }
                                                            name={ [field.name, 'siteName'] }
                                                            rules={ [{ required: true, message: 'Please input Site Name here' }] }
                                                            wrapperCol={ { span: 24 } }

                                                        >
                                                            <Input placeholder='Please enter site name here!' />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="col">
                                                        <label>Location Link</label>
                                                        <div className="content">
                                                            {
                                                                locationList[index] ?
                                                                    <a target="_blank" href={ locationList[index].link } rel="noreferrer">Google Map Link</a>
                                                                    : (
                                                                        <Form.Item
                                                                            { ...field }
                                                                            style={ { flexGrow: '1' } }
                                                                            name={ [field.name, 'locationLink'] }
                                                                            rules={ [{ required: true, message: 'Please input location link here' }] }
                                                                            wrapperCol={ { span: 24 } }

                                                                        >
                                                                            <Input placeholder='Please enter location link here!' />
                                                                        </Form.Item>
                                                                    )
                                                            }
                                                            <Form.Item>
                                                                <Button type="primary" onClick={ () => {
                                                                    setRowIndex(index);
                                                                    setLocationModalOpen(true);
                                                                } }><EnvironmentOutlined className="form-icon" /></Button>
                                                            </Form.Item>
                                                            <LocationModal
                                                                locationModalOpen={ locationModalOpen }
                                                                setLocationModalOpen={ setLocationModalOpen }
                                                                setLocationList={ setLocationList }
                                                                locationList={ locationList }
                                                                rowIndex={ rowIndex }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col'>
                                                        <label>Address</label>
                                                        <div className="content">
                                                            {
                                                                locationList[index] ?
                                                                    <p>{ locationList[index]?.address }</p>
                                                                    : (
                                                                        <Form.Item
                                                                            { ...field }
                                                                            style={ { flexGrow: '1' } }
                                                                            name={ [field.name, 'address'] }
                                                                            rules={ [{ required: true, message: 'Please input address here' }] }
                                                                            wrapperCol={ { span: 24 } }
                                                                        >
                                                                            <Input placeholder='Please enter address here!' />
                                                                        </Form.Item>
                                                                    )
                                                            }
                                                            <Form.Item style={ { display: 'flex', gap: '2rem' } }>
                                                                <Button type="primary" onClick={() => handleSave(index) }><CheckOutlined /></Button>
                                                                <Button type="primary" danger style={ { marginLeft: '1rem' } } onClick={
                                                                    () => {
                                                                        locationList.splice(index, 1);
                                                                        remove(field.name);
                                                                    }
                                                                }><MinusCircleOutlined /></Button>
                                                            </Form.Item>
                                                        </div>
                                                    </div>

                                                </div>
                                            );
                                        }) }
                                    </>
                                );
                            } }
                        </Form.List>
                    </Row>

                </Form>
            </Card>

        </Layout>
    );
};

export default ClientView;
