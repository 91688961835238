/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Card,
    Col,
    Collapse,
    ConfigProvider,
    Input,
    Row,
    Select,
    Table,
    Typography
} from 'antd';
import imageCompression from 'browser-image-compression';
import React, { useContext, useEffect, useState } from 'react';
import { FcCancel, FcOk } from 'react-icons/fc';
import { SiteAuditContext } from '../../../../context';


interface DataRow {
    oneTask: string;
    images: File[];
    comment: string;
    rating: number;
}
interface TaskListProps {
    task: any;

}

const { Text } = Typography;
const { Panel } = Collapse;


const TaskList = (props: TaskListProps) => {

    const { task } = props;
    const [selectedOption, setSelectedOption] = useState<string>('green');
    const { setInitialTasks, auditData, addImages, addComment, addRating, clicked } = useContext(SiteAuditContext);
    const [selectedArea, setSelectedArea] = useState<string | null>(null);


    const handleClickedPanel = (area: string) => {
        setSelectedArea(area);
        clicked(area);

    };

    //set task for siteAuditContext
    useEffect(() => {
        setInitialTasks(task.map((item: any) => {
            return { ...item, clicked: false };
        }),
        );

    }, [task]);

    const genExtra = (itemState: boolean) => {
        return itemState === false ? (
            <FcCancel style={ { fontSize: '18px', marginTop: '2px' } } />
        ) : (
            <FcOk style={ { fontSize: '18px', marginTop: '2px' } } />
        );
    };



    // const handleImage = async (info: any, id: number) => {
    //     const compressOptions = {
    //         maxSizeMB: 0.1,
    //         maxWidthOrHeight: 1920,
    //         useWebWorker: true,
    //     };

    //     const selectedImages = Array.from(info.fileList).map((img: any) => {
    //         return img.originFileObj;
    //     });

    //     const compressedImages = await Promise.all(
    //         selectedImages.map(async (image: any) => {
    //             console.log(image);
    //             try {
    //                 const compressedImage = await imageCompression(image, compressOptions);
    //                 // console.log(compressedImage);
    //                 // const compressedFile = new File([compressedImage], image.name, { type: image.type });
    //                 // return compressedFile;
    //                 return compressedImage;
    //             } catch (error) {
    //                 console.error('Error compressing image:', error);
    //                 return null;
    //             }
    //         }),
    //     );

    //     const filteredImages = compressedImages.filter((image) => { return image !== null; });
    //     //@ts-ignore
    //     addImages(id, filteredImages);
    // };


    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const compressOptions = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };
        if (event.target.files) {
            const selectedImages = Array.from(event.target.files);


            const compressedImages = await Promise.all(
                selectedImages.map(async (image) => {

                    console.log(image);

                    try {
                        const compressedImage = await imageCompression(image, compressOptions);
                        // console.log(compressedImage);
                        // const compressedFile = new File([compressedImage], image.name, { type: image.type });
                        // return compressedFile;
                        return compressedImage;
                    } catch (error) {
                        console.error('Error compressing image:', error);
                        return null;
                    }
                }),
            );

            const filteredImages = compressedImages.filter((image) => { return image !== null; });
            //@ts-ignore
            addImages(id, filteredImages);
        }
    };


    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {

        console.log(event.target.value);

        if (event.target.value) {
            const comment = event.target.value;
            addComment(id, comment);
        }
    };


    const columns = [
        {
            title: 'Task',
            dataIndex: 'task',
            width: '20%',
        },
        {
            title: 'Outcome',
            dataIndex: 'outcome',
            width: '20%',
        },
        {
            title: 'Photos',
            dataIndex: 'photos',
            width: '20%',
            render: (text: string, record: any) => {
                return (
                    <div>
                        <Input style={ { width: '100%' } } type="file" accept="image/*" multiple onChange={ (e) => { return handleImageChange(e, record.taskId); } } />

                        {/* <Input style={{ color: 'transparent' }} type="file" accept="image/*" multiple onChange={(e) => { return handleImageChange(e, record.taskId); }} /> */ }
                    </div>
                );
            },
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            width: '20%',
            render: (text: string, record: any) => {
                return (

                    <div>
                        {/* <input type="text" onChange={(e) => { return handleCommentChange(e, record.taskId); }} /> */ }
                        <Input onChange={ (e: any) => { return handleCommentChange(e, record.taskId); } } style={ { width: '100%' } } placeholder="Comments" />
                    </div>

                );
            },
        },
        {
            title: 'Select Ratings',
            dataIndex: 'id',
            width: '20%',
            render: (text: string, record: any) => {
                return <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'center' } }>{ handleRateState(record.taskId) }</div>;
            },
        },
    ];


    const handleRateState = (id: number) => {
        const options = [
            { value: 'Poor', label: 'Poor', color: 'red' },
            { value: 'Fair', label: 'Fair', color: 'yellow' },
            { value: 'Good', label: 'Good', color: 'green' },
            { value: 'Excellent', label: 'Excellent', color: '#1d1e4d' },
        ];
        const handleSelectChange = (value: string) => {
            addRating(id, value);
        };
        return (
            <>
                <Select
                    className="select-rate"
                    defaultValue={ options[2].value }
                    showArrow={ true }
                    onChange={ handleSelectChange }
                >
                    { options.map((option) => (
                        <option key={ option.value } value={ option.value }>
                            <div style={ { color: option.color, fontWeight: 'bold' } }>
                                { option.label }
                            </div>
                        </option>
                    )) }
                </Select>
            </>
        );
    };

    return (
        <Collapse accordion expandIconPosition="right">
            { Array.from(new Set(auditData.map((item: any) => item.area))).map((area: string, index: number) => {

                const tasksForArea = auditData.filter((item: any) => item.area === area);
                console.log(tasksForArea);
                return (
                    <Panel
                        header={ <div onClick={ () => handleClickedPanel(area) }>{ area }</div> }
                        key={ index }
                        extra={ genExtra(tasksForArea[0].clicked) }
                    >

                        <ConfigProvider theme={ { token: { colorPrimary: '#5a82f9' } } } >
                            <Table
                                dataSource={ tasksForArea }
                                columns={ columns }
                                pagination={ false }
                                rowKey="taskId"
                            />
                            {
                                tasksForArea.map((item) => {
                                    return (
                                        <Card key={ item.taskId } className="table-card" bordered={ true } >
                                            <Row className="table-card-row">
                                                <Col span={ 8 }>
                                                    <Text>Task:</Text>
                                                </Col>
                                                <Col span={ 16 }>
                                                    <Text>{ item.task }</Text>
                                                </Col>
                                            </Row>
                                            <Row className="table-card-row">
                                                <Col span={ 8 }>
                                                    <Text>Outcome:</Text>
                                                </Col>
                                                <Col span={ 16 }>
                                                    <Text>{ item.outcome }</Text>
                                                </Col>
                                            </Row>
                                            <Row className="table-card-row">
                                                <Col span={ 8 }>
                                                    <Text>Photos</Text>
                                                </Col>
                                                <Col span={ 16 }>
                                                    <div>
                                                        <input type="file" accept="image/*"
                                                            multiple onChange={ (e) => { return handleImageChange(e, item.taskId); } } />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="table-card-row">
                                                <Col span={ 8 }>
                                                    <Text>Comments:</Text>
                                                </Col>
                                                <Col span={ 16 }>
                                                    <Input type="text" onChange={ (e) => { return handleCommentChange(e, item.taskId); } } />
                                                </Col>
                                            </Row>
                                            <Row className="table-card-row">
                                                <Col span={ 8 }>
                                                    <Text>Select Rating:</Text>
                                                </Col>
                                                <Col span={ 16 }>
                                                    <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'start' } }>
                                                        { handleRateState(item.taskId) }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )

                                })
                            }
                        </ConfigProvider>

                    </Panel>
                );
            }) }
        </Collapse>
    );
};
export default TaskList;