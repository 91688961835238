import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Typography, message } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Images from '../../../assests/Images/Image';
import { userVerifyEmail } from '../action';

const { Title, Text } = Typography;

type verifyFormProps = {
    onFormVerify: () => void
}
export default function VerifyForm (props: verifyFormProps) {
    const navigate = useNavigate();
    const { onFormVerify } = props;
    const [loading, setLoading] = useState(false);
    const handleOnVerify = async (values: any) => {
        const { email, code } = values;
        setLoading(true);
        try {
            await userVerifyEmail(email, code);
            setLoading(false);
            onFormVerify();
        } catch (error: any) {
            setLoading(false);
            console.log('Error on Verification', error);
            message.error('Invalid Verification Code');
        }
    };

    return (
        <Row>
            <Col className='form-card'>
                <Row>
                    <Col span={ 24 }>
                        <img src={ Images.logo } alt='logo'/>
                    </Col>
                </Row>
                <Row>
                    <Col span={ 24 }>
                        <Title level={ 2 }>Verify Email Address</Title>
                        <Text type="secondary">Please enter the code recieved to the mail
                            for verify your mail address</Text>
                    </Col>
                    <Col span={ 24 } className='form'>
                        <Form
                            initialValues={ { email: localStorage.getItem('email') } }
                            autoComplete="off"
                            layout="vertical"
                            requiredMark={ false }
                            className='form-container'
                            onFinish={ handleOnVerify }
                        >
                            <Form.Item
                                name="email"
                                rules={ [{ type: 'email', required: true, message: 'Please input your email here!' }] }
                            >
                                <Input placeholder='Email Address' size={ 'large' } prefix={ <MailOutlined /> } />
                            </Form.Item>
                            <Form.Item
                                className='login-password-field'
                                name="code"
                                rules={ [{
                                    required: true,
                                    message: 'Please input your verify code here!',
                                },
                                {
                                    pattern: /^\d{6}$/, 
                                    message: 'Please enter a 6-digit number!',
                                },
                                ] }
                            >
                                <Input placeholder='Verify Code' size={ 'large' } prefix={ <LockOutlined /> } />
                            </Form.Item>
                            <Form.Item>
                                <Button type="text" className='forget-password'>Not Recieved the Code Resend</Button>
                            </Form.Item>
                            <Row>
                                <Col span={ 24 } className='submit'>
                                    <Button type="primary" htmlType="submit" className='submit-btn' loading={ loading }>
                                        Verify Email
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='create-one'>
                                    <p>Do have an account</p>
                                    <Button type="text" onClick={ () => { navigate('/'); } }>Sign In</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
