import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import apiClient from '../utils/apiClient';
import { IClient, ISite } from '../utils/types';


interface ClientContextValue {
    clients: IClient[];
    client: IClient | null;
    addClient: (client: Omit<IClient, 'clientId'>) => void;
    deleteClient: (id: string) => void;
    updateClient: (id: string, updatedClient: Omit<IClient, 'site' | 'clientId'>) => void;
    getOneClient: (id: string) => void;
    setOneClient: (client: IClient | null) => void;
    addSite: (clientId: string, site: Omit<ISite, 'siteId' | 'client' | 'staff'>) => boolean;
}

export const ClientContext = createContext<ClientContextValue>({
    clients: [],
    client: null,
    addClient: () => {
        throw new Error('ClientProvider not found');
    },
    deleteClient: () => {
        throw new Error('ClientProvider not found');
    },
    updateClient: () => {
        throw new Error('ClientProvider not found');
    },
    getOneClient: () => {
        throw new Error('ClientProvider not found');
    },
    setOneClient: () => {
        throw new Error('ClientProvider not found');
    },
    addSite: () => {
        throw new Error('ClientProvider not found');
    }
});

type ClientContextProviderProps = {
    children: ReactNode;
};

export const ClientProvider = ({ children }: ClientContextProviderProps) => {
    const [clients, setClients] = useState<IClient[]>([]);
    const [client, setClient] = useState<IClient | null>(null);
    const { state } = useAuthContext();
    const { user } = state;

    useEffect(() => {
        if (user && user?.type === 'manager') {
            fetchClients();
        }
    }, [user]);

    const fetchClients = async () => {
        try {
            const response = await apiClient.get('/clients');
            setClients(response.data);
        } catch (error) {
            console.error('Error fetching Client:', error);
        }
    };

    const addClient = async (client: Omit<IClient, 'clientId'>) => {
        try {
            const response = await apiClient.post('/clients', client);
            setClients((prevClients) => { return [...prevClients, response.data]; });
        } catch (error) {
            console.error('Error adding Client:', error);
        }
    };

    const deleteClient = async (id: string) => {
        try {
            await apiClient.delete(`/clients/${id}`);
            setClients((prevClients) => { return prevClients.filter((client) => { return client.clientId !== id; }); });
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    const updateClient = async (id: string, updatedClient: Omit<IClient, 'site' | 'clientId'>) => {
        try {
            const response = await apiClient.put(`/clients/${id}`, updatedClient);
            setClients((prevClients) => {
                return prevClients.map((client) => { return (client.clientId === id ? response.data : client); });
            },
            );
        } catch (error) {
            console.error('Error updating Client:', error);
        }
    };

    const addSite = async (clientId: string, siteDetails: Omit<ISite, 'siteId' | 'client' | 'staff'>) => {
        try {
            console.log("Comming");
            const response = await apiClient.post(`/clients/createSite`, siteDetails);
            console.log(response);
            await setClients((prevClients) =>
                prevClients.map((client) =>
                    client.clientId === clientId
                        ? {
                            ...client,
                            site: [...client.site, response.data], // Append the response to the sites array
                        }
                        : client
                )
            );
            setTimeout(() => {
                const filteredClient = clients.find((client) => client.clientId === clientId);
                console.log(filteredClient?.site);
                if (filteredClient) {
                    setClient(filteredClient);
                }
            }, 100);
            return true;
        } catch (error) {
            console.error('Error updating Client:', error);
        }
    };
    const removeSite = async (clientId: string, siteDetails: Omit<ISite, 'client' | 'staff'>) => {
        try {
            console.log("Comming");
            const response = await apiClient.post(`/clients/createSite`, siteDetails);
            console.log(response);
            await setClients((prevClients) =>
                prevClients.map((client) =>
                    client.clientId === clientId
                        ? {
                            ...client,
                            site: [...client.site, response.data], // Append the response to the sites array
                        }
                        : client
                )
            );
            setTimeout(() => {
                const filteredClient = clients.find((client) => client.clientId === clientId);
                console.log(filteredClient?.site);
                if (filteredClient) {
                    setClient(filteredClient);
                }
            }, 100);
            return true;
        } catch (error) {
            console.error('Error updating Client:', error);
        }
    };



    const getOneClient = async (id: string) => {
        try {
            const response = await apiClient.get(`/clients/${id}`);
            setClient(response.data);
        } catch (error) {
            console.error('Error fetching task:', error);
        }
    };

    const setOneClient = (client: IClient | null) => {
        setClient(client);
    };

    const contextValue = useMemo<ClientContextValue>(
        //@ts-ignore
        () => {
            return {
                clients,
                client,
                addClient,
                deleteClient,
                updateClient,
                getOneClient,
                setOneClient,
                addSite,
                removeSite
            };
        },
        [clients, client,]);

    return (
        <ClientContext.Provider value={ contextValue }>
            { children }
        </ClientContext.Provider>
    );
};
