import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Select, Spin, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ClientContext, TaskContext } from '../../../../context';
import { IClient, ISite, ITask } from '../../../../utils/types';
import Footer from '../../Others/Footer';

const { Title } = Typography;
const { Option } = Select;

type AddTaskProps = {
    onBreadClick: () => void,
    onTaskClick: () => void,
}

const { TextArea } = Input;
const AddTask = (props: AddTaskProps) => {
    const { onBreadClick, onTaskClick } = props;
    const { task, addTask, updateTask } = useContext(TaskContext);
    const [isUpdateForm, setIsUpdateForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 576 });
    const [form] = Form.useForm();
    const { clients } = useContext(ClientContext);
    const [selectedClient, setSelectedClient] = useState<string | undefined>(undefined);
    const [taskId, setTaskId] = useState<null | number>(null);
    const [selectedClientsSites, setSelectedClientsSites] = useState<ISite[] | undefined>(undefined);

    useEffect(() => {
        const selectedClientData = selectedClient ? clients.find((client) => { return client.clientId === selectedClient; }) : undefined;
        if (selectedClientData) {
            setSelectedClientsSites(selectedClientData.site);
        } else {
            setSelectedClientsSites([]);
        }
    }, [clients, selectedClient]);

    useEffect(() => {
        if (task === null) {
            setIsUpdateForm(false);
            form.resetFields();
        } else {
            console.log(task);
            setLoading(true);
            form.setFieldsValue({
                clientId: task?.clientId,
                siteId: task?.site?.siteName,
                area: task.area,
                task: task.task,
                outcome: task.outcome,
            });
            setTaskId(task.taskId);
            setIsUpdateForm(true);
            setLoading(false);
        }
    }, [task, form]);

    const handleOnSubmit = async (formValues: ITask) => {
        if (!isUpdateForm) {
            await addTask(formValues);
        } else {
            if (taskId) {
                await updateTask(taskId, formValues);
            }
        }
        onTaskClick();
    };

    const handleClientChange = (value: string) => {
        setSelectedClient(value);
    };

    return (
        <Layout className='report-card'>
            <Row className='header-row'>
                <Col span={ 20 }>
                    <Title level={ 2 }>{ isUpdateForm ? 'Update Task' : 'Add Task' }</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={ onBreadClick }>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={ onTaskClick }>Tasks</Breadcrumb.Item>
                        <Breadcrumb.Item className='bread-active'>{ isUpdateForm ? 'Update Task' : 'Add Task' }</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col span={ 24 }>
                    { loading ? <Spin /> : (
                        <Form
                            form={ form }
                            labelCol={ { span: 3 } }
                            wrapperCol={ { span: 21 } }
                            requiredMark={ false }
                            className='basic-form'
                            layout={ isMobile ? 'vertical' : 'horizontal' }
                            onFinish={ handleOnSubmit }
                        >
                            <Form.Item label="Client Name" name="clientId"
                                rules={ [{ required: true, message: 'Please select the client!' }] }
                            >

                                <Select placeholder='Please select the client'
                                    disabled={ isUpdateForm }
                                    onChange={ handleClientChange }
                                    style={ { width: '100%' } }>
                                    { clients.sort((a, b) => `${a?.name}`.localeCompare(`${b?.name}`)).map((client: IClient) => {
                                        return (
                                            <Option key={ client.clientId } value={ client.clientId }>
                                                { client.name }
                                            </Option>
                                        );
                                    }) }

                                </Select>
                            </Form.Item>
                            <Form.Item label="Site Name" name="siteId"
                                rules={ [{ required: true, message: 'Please select the site!' }] }
                            >
                                <Select placeholder='Please select the site'
                                    style={ { width: '100%' } }
                                    disabled={ !selectedClient || isUpdateForm }>
                                    { (selectedClientsSites && selectedClient !== undefined && selectedClientsSites.length > 0) ?
                                        selectedClientsSites.sort((a, b) => `${a?.siteName}`.localeCompare(`${b?.siteName}`)).map((site) => {
                                            return (
                                                <Option key={ site.siteId } value={ site.siteId }>
                                                    { site.siteName }
                                                </Option>
                                            );
                                        })
                                        : <Option key="empty" value="" disabled>
                                            No sites available
                                        </Option>
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Area"
                                name="area"
                                rules={ [{ required: true, message: 'Please input the area!' }] }
                            >
                                <Input autoComplete="on" placeholder='Please enter area here!' />
                            </Form.Item>
                            <Form.Item
                                label="Task"
                                name="task"
                                rules={ [{ required: true, message: 'Please input the task!' }] }
                            >
                                <Input autoComplete="on" placeholder='Please enter task here!' />
                            </Form.Item>
                            <Form.Item
                                label="Outcome"
                                name="outcome"
                                rules={ [{ required: true, message: 'Please input the outcome!' }] }
                            >
                                <TextArea rows={ 4 } placeholder='Please enter outcome here!' />
                            </Form.Item>
                            <Row className='form-submit-row'>
                                <Button className='view-btn-hover' onClick={ onTaskClick } style={{marginRight:'1em'}}>
                                    Cancel
                                </Button>
                                <Button className='view-btn' htmlType="submit">
                                    { isUpdateForm ? 'Update' : 'Submit' }
                                </Button>
                            </Row>
                        </Form>
                    ) }
                </Col>
            </Row>
            <Footer />
        </Layout>
    );
};

export default AddTask;
