import { InboxOutlined } from '@ant-design/icons';
import { Button, Col, ConfigProvider, Form, Input, Row, Spin, Typography, message, notification } from 'antd';
import ImgCrop from 'antd-img-crop';
import Dragger from 'antd/es/upload/Dragger';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { uploadToS3 } from '../../../utils/sampleImageUpload';
import { userSignup } from '../action';

const { Title, Text } = Typography;

type signupFormProps = {
    imageURL: string,
    setImageURL: (url: string) => void,
    onFormSubmit: () => void,
}

const SignupForm = (props: signupFormProps) => {


    const navigate = useNavigate();
    const { onFormSubmit, imageURL, setImageURL } = props;
    const [uploading, setUploading] = useState(false);
    const [formData, setFormData] = useState({
        businessName: '', abn: '', contactNo: '',
        businessLogo: '', firstName: '', lastName: '', email: '', password: '', confirmPassword: '',
    });
    const [currentStep, setCurrentStep] = useState(1);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: 'image/*',
        customRequest: async ({ file, onSuccess, onError }: any) => {
            try {
                setUploading(true);
                const extension = file.name.split('.').pop();
                const fileName = `${formData.businessName}_${Date.now()}.${extension}`;
                console.log(fileName);
                const uploadUrl = await uploadToS3(file);
                setImageURL(uploadUrl);
                onSuccess(null, { url: uploadUrl });
                message.success('Logo Successfully Uploaded');
                setUploading(false);
            } catch (error) {
                console.error('S3 upload error: ', error);
                onError({ error });
                message.error(`${file.name} file upload failed.`);
            }
        },
        onDrop(e: { dataTransfer: { files: any; }; }) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const handleFormSubmit = async (values: any) => {
        const data = { ...formData, ...values };
        setFormData(data);
        const { email, password, firstName, lastName, abn, contactNo, businessName } = data;
        if (currentStep === 2) {
            setLoading(true);
            try {
                const user = await userSignup(email,
                    password,
                    firstName,
                    lastName,
                    businessName,
                    imageURL,
                    abn,
                    contactNo);
                console.log(user);
                if (user) {
                    notification.success(
                        {
                            message: 'Success',
                            description: 'Registration successful! Please check your email for verification.',
                        });
                    localStorage.setItem('email', email);
                    setLoading(false);
                    onFormSubmit();
                }
            } catch (error: any) {
                setLoading(false);
                if (error.response.data.message === 'User with this email already exists') {
                    notification.error(
                        {
                            message: 'Error',
                            description: 'User with this email already exists',
                        });
                }
                else {
                    notification.error(
                        {
                            message: 'Error',
                            description: 'Registration failed.Please try again.',
                        });
                }
            }
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleGoBack = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleNext = async () => {
        try {
            const values = await form.validateFields();
            const data = { ...formData, ...values };
            data.businessLogo = imageURL;
            setFormData(data);
            setCurrentStep(currentStep + 1);
        } catch (error) {
            console.log('Validation error: ', error);
        }
    };

    const getTitleFields = () => {
        switch (currentStep) {
        case 1:
            return (
                <Text type="secondary">Register Your Business Account</Text>
            );
        case 2:
            return (
                <Text type="secondary">Register Your Personal Account</Text>
            );
        }
    };
    const getFormFields = () => {
        switch (currentStep) {
        case 1:
            return (
                <>
                    <Form.Item
                        label="Business Name"
                        name="businessName"
                        rules={[{ required: true, message: 'Please enter your business name' }]}
                        initialValue={formData.businessName}
                    >
                        <Input placeholder="Business Name" size="middle" />
                    </Form.Item>
                    <Form.Item
                        label="ABN"
                        name="abn"
                        initialValue={formData.abn}
                    >
                        <Input placeholder="ABN" size="middle" />
                    </Form.Item>
                    <Form.Item
                        label="Contact No"
                        name="contactNo"
                        initialValue={formData.contactNo}
                        rules={[{ required: true, message: 'Please enter your business contact no' }]}
                    >
                        <Input placeholder="Contact No" size="middle" />
                    </Form.Item>

                    <Form.Item
                        label="Business Logo"
                        name="businessLogo"
                        style={{ marginBottom: '1rem' }}
                        // rules={ [{ required: true, message: 'Please add your business logo' }] }
                    >
                        <ConfigProvider theme={{ token: { colorPrimary: '#5a82f9' } }}>
                            <ImgCrop >
                                <Dragger {...uploadProps} >
                                    <p className="ant-upload-drag-icon">
                                        {!uploading ? <InboxOutlined color='#1d1e4d' /> : <Spin size="large" />}
                                    </p>
                                    <p className="ant-upload-text">
                                            Click or drag file to this area to upload
                                    </p>
                                </Dragger>
                            </ImgCrop>
                        </ConfigProvider>
                    </Form.Item>

                </>
            );
        case 2:
            return (
                <>
                    <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[{ required: true, message: 'Please enter your first name' }]}
                        initialValue={formData.firstName}
                    >
                        <Input placeholder="First Name" size="middle" />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[{
                            required: true, message: 'Please enter your last name',
                        }]}
                        initialValue={formData.lastName}
                    >
                        <Input placeholder="Last Name" size="middle" />
                    </Form.Item >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { required: true, message: 'Please enter your email' },
                            { type: 'email', message: 'Please enter a valid email' },
                        ]}
                        initialValue={formData.email}
                    >
                        <Input placeholder="Email" size="middle" />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            { required: true, message: 'Please enter your password' },
                            { min: 8, message: 'Password must be at least 8 characters' },
                        ]}
                        initialValue={formData.password}
                    >
                        <Input.Password placeholder="Password" size="middle" />
                    </Form.Item>
                    <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: 'Please confirm your password' },
                            ({ getFieldValue }) => {
                                return {
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Passwords do not match'));
                                    },
                                };
                            },
                        ]}
                        initialValue={formData.confirmPassword}
                    >
                        <Input.Password placeholder="Confirm Password" size="middle" />
                    </Form.Item>
                </>
            );
        default:
            return null;
        }
    };


    return (
        <Row justify="center">
            <Col className='form-card'>
                <Row>
                    <Col span={24} className='form-header'>
                        <Title level={1} >Welcome to iManage</Title>
                        {getTitleFields()}
                    </Col>
                    <Col span={24} className='form signup-form'>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleFormSubmit}
                            scrollToFirstError
                            initialValues={formData}
                            className='form-container'
                        >
                            {getFormFields()}
                            <Form.Item>
                                {currentStep === 1 ? (
                                    <Row>
                                        <Col span={24} className='submit'>
                                            <Button onClick={handleNext}
                                                style={{ marginBottom: 0 }}
                                                htmlType="submit" type="primary" className='submit-btn'>
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row justify="space-between">
                                        <Button onClick={handleGoBack} className='view-btn-hover'>
                                            Back
                                        </Button>
                                        <Button htmlType="submit" className='submit-btn' loading={loading}>
                                            {currentStep === 2 ? 'Sign Up' : 'Next'}
                                        </Button>
                                    </Row>
                                )}
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col className='create-one'>
                        <p style={{ marginBlock: 0 }} >Do you already have an account?</p>
                        <Button type="text" onClick={() => { navigate('/'); }}>Sign In</Button>
                    </Col>
                </Row>
            </Col >
        </Row >
    );
};

export default SignupForm;




