/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-len */
import {
    Breadcrumb,
    Button,
    Card,
    // Card,
    Col,
    ConfigProvider,
    DatePicker,
    Layout,
    Modal,
    Row,
    Select,
    Table,
    Typography,
} from 'antd';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { ClientContext, SiteAuditContext } from '../../../context';
import { useAuthContext } from '../../../hooks/useAuthContext';
import Footer from '../Others/Footer';

const { Title, Text } = Typography;
const { confirm } = Modal;

interface ReportProps {
    onBreadClick: () => void,
}

const Reports = (props: ReportProps) => {
    const { auditReports, deleteAuditReport } = useContext(SiteAuditContext);
    const [filteredAuditReports, setFilteredAuditReports] = useState(auditReports);
    const { clients } = useContext(ClientContext);
    const [selectedClient, setSelectedClient] = useState<string>();
    const [selectedSite, setSelectedSite] = useState<number>();
    const [selectedStartDate, setSelectedStartDate] = useState<string>();
    const [selectedEndDate, setSelectedEndDate] = useState<string>();
    const [loading, setLoading] = useState(false);
    const { onBreadClick } = props;
    const { state } = useAuthContext();
    const { user, business } = state;

    useEffect(() => {
        // Filter the audit reports based on selected criteria
        const filteredReports = auditReports.filter((report) => {

            // Check if the report matches the selected site
            if (selectedSite && report.siteId !== selectedSite) {
                return false;
            }

            // Check if the report matches the selected client
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            if (selectedClient && report.clientId !== selectedClient) {
                return false;
            }

            // Check if the report is within the selected date range
            if (selectedStartDate) {
                const startDate = moment(selectedStartDate);
                const reportDate = moment(report.reportTime);

                if (!(reportDate.isSameOrAfter(startDate, 'day'))) {
                    return false;
                }
            }

            if (selectedEndDate) {
                const endDate = moment(selectedEndDate);
                const reportDate = moment(report.reportTime);

                if (!(reportDate.isSameOrBefore(endDate, 'day'))) {
                    return false;
                }
            }
            return true;
        });

        setFilteredAuditReports(filteredReports);
    }, [auditReports, selectedSite, selectedClient, selectedStartDate, selectedEndDate]);

    useEffect(() => {
        setLoading(true);
        if (auditReports) {
            setFilteredAuditReports(auditReports);
            setLoading(false);
        }
    }, [auditReports])




    const handleChangeClient = (value: string) => {
        if (value === '') {
            // Reset selected client and site
            setSelectedClient(undefined);
            setSelectedSite(undefined);
        } else {
            setSelectedSite(0);
            setSelectedClient(value);
        }


    };
    const handleChangeSite = (value: number) => {
        if (value === 0) {
            // Reset selected site
            setSelectedSite(undefined);
        } else {
            setSelectedSite(value);
        }
    };

    //@ts-ignore
    const handleStartDateChange = (date, dateString) => {
        setSelectedStartDate(dateString);
    };
    //@ts-ignore
    const handleEndDateChange = (date, dateString) => {
        setSelectedEndDate(dateString);
    };
    const clientOptions = [{ value: '', label: 'Select client name' },
    ...clients.sort((a, b) => `${a?.name}`.localeCompare(`${b?.name}`) ).map((client: { clientId: any; name: any; }) => {
        return {
            value: client.clientId,
            label: client.name,
        };
    })
    ]

    const siteOptions = [
        { value: 0, label: 'Select site name' }, // Reset option
        ...clients.flatMap((client) => {
            if (client.clientId === selectedClient) {
                return client.site.map((site) => ({
                    value: site.siteId,
                    label: site.siteName,
                }));
            }
            return [];
        }),
    ];
    // const handlePDFDownload = (url) => {
    //     const pdfUrl = url;
    //     window.open(pdfUrl, '_blank');
    // };
    function showDeleteConfirm (auditId: number) {
        confirm({
            title: 'Are you really want to delete this PDF?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk () {
                deleteAuditReport(auditId);
                console.log('OK');
            },
            onCancel () {
                console.log('Cancel');
            },
        });
    }

    const columns = [
        {
            title: 'Client Name',
            dataIndex: 'clientName',
        },
        {
            title: 'Client Site',
            dataIndex: 'siteName',
        },
        {
            title: 'Uploaded Date',
            dataIndex: 'reportTime',
            render: (reportTime: string) => {
                return moment(reportTime?.slice(0,10),'YYYY-MM-DD').format('DD-MM-YYYY');
            },
        },
        {
            title: 'Uploaded Time',
            dataIndex: 'reportTime',
            render: (reportTime: string) => {
                return moment(reportTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A');;

            },
        },
        {
            title: 'PDF Report',
            dataIndex: 'reportURL',
            render: (text: any, record: any) => {
                const { reportURL, auditId } = record;
                return <div className="gap-1" style={ { justifyContent: 'center' } }>
                    <a href={ reportURL }> <Button className='view-btn'>Download</Button> </a>

                    { user?.type === 'manager' && <Button danger className='danger-btn' onClick={ () => { return showDeleteConfirm(auditId); } }>Remove</Button> }
                </div>;
            },
        },
    ];

    const handleClientDefaultValues = () => {
        if (user?.type === 'siteManager') {
            return business?.client?.name;
        } else if (user?.type === 'client') {
            return user?.client.name;
        }
        const clients: string = clientOptions[0].value;
        return clients;
    }
    const handleSiteDefaultValues = () => {
        if (user?.type === 'siteManager') {
            console.log("Site Name ", business?.site?.siteName)
            return business?.site?.siteName;
        }
        return siteOptions[0].value;
    }

    return (
        <Layout className='report-card report-component'>
            <Row>
                <Col>
                    <Title level={ 2 }>Reports</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={ onBreadClick }>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item className='bread-active'>Reports</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <div className='chart-component'>
                {/* <Title level={4} style={{ marginLeft: '.8em' }}>Summary</Title> */ }
                {/* <Row className='chart-row'>
                    <Col span={12}>
                        <Title level={2} style={{ textAlign: 'center', fontSize: '16px', fontWeight: 400 }}>Task
                            completion summary</Title>
                        <BarChart />
                    </Col>
                    <Col span={12}>
                        <Title level={2} style={{ textAlign: 'center', fontSize: '16px', fontWeight: 400 }}>Work
                            distribution based on clients</Title>
                        <PieChart />
                    </Col>
                </Row> */}
                {/* <Title level={4} style={{ marginLeft: '.8em' }}>Report Details</Title> */ }
                <Row gutter={ 2 } className='report-select'>
                    <Col span={ 6 }>
                        <Text className="input-label-text">Client Name : </Text>
                        <Select
                            defaultValue={ handleClientDefaultValues() }
                            onChange={ handleChangeClient }
                            options={ user.type === 'manager' ? clientOptions : [] }
                        />
                    </Col>
                    <Col span={ 6 }>
                        <Text className="input-label-text">Site Name : </Text>
                        { user.type !== 'siteManager' ? <Select
                            defaultValue={ handleSiteDefaultValues() }
                            options={ siteOptions }
                            onChange={ handleChangeSite }
                            //@ts-ignore
                            value={ selectedSite === undefined ? siteOptions[0].value : selectedSite }
                        />
                            :
                            <Select
                                defaultValue={ handleSiteDefaultValues() }
                                options={ siteOptions }
                                onChange={ handleChangeSite }
                                //@ts-ignore
                                value={ selectedSite === undefined ? siteOptions[0].value : selectedSite }
                            /> }
                    </Col>
                    <Col span={ 6 }>
                        <Text className="input-label-text">Start Date : </Text>
                        <ConfigProvider theme={ { token: { colorPrimary: '#5a82f9' } } }>
                            <DatePicker onChange={ handleStartDateChange } />
                        </ConfigProvider>
                    </Col>
                    <Col span={ 6 }>
                        <Text className="input-label-text">End Date : </Text>
                        <ConfigProvider theme={ { token: { colorPrimary: '#5a82f9' } } }>
                            <DatePicker onChange={ handleEndDateChange } />
                        </ConfigProvider>
                    </Col>
                </Row>
                <Row>
                    <div className='other-table'>
                        <ConfigProvider theme={ { token: { colorPrimary: '#5a82f9' } } }>
                            <Table columns={ columns } loading={ loading }
                                dataSource={ filteredAuditReports }
                            />
                            <div className='table-card-list' style={ { marginTop: '1rem' } }>
                                { filteredAuditReports.slice(0, 10).map((item: any) => {
                                    return (<Card key={ item.auditId } className="table-card" bordered={ true }>
                                        <Row className='table-card-row'>
                                            <Col span={ 10 }>
                                                <Text>Client Name:</Text>
                                            </Col>
                                            <Col span={ 14 }>
                                                <Text>{ item.clientName }</Text>
                                            </Col>
                                        </Row>
                                        <Row className='table-card-row'>
                                            <Col span={ 10 }>
                                                <Text>Client Site:</Text>
                                            </Col>
                                            <Col span={ 14 }>
                                                <Text>{ item?.siteName }</Text>
                                            </Col>
                                        </Row>
                                        <Row className='table-card-row'>
                                            <Col span={ 10 }>
                                                <Text>Reported Date:</Text>
                                            </Col>
                                            <Col span={ 14 }>
                                                <Text>{ moment(item.reportTime?.slice(0, 10), 'YYYY-MM-DD').format('DD-MM-YYYY') }</Text>
                                            </Col>
                                        </Row>

                                        <Row className='table-card-row'>
                                            <Col span={ 10 }>
                                                <Text>Reported Time:</Text>
                                            </Col>
                                            <Col span={ 14 }>
                                                <Text>{ moment(item.reportTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</Text>
                                            </Col>
                                        </Row>
                                        <Row className='table-card-row'>
                                            <Col span={ 10 }>
                                                <Text>PDF Report:</Text>
                                            </Col>
                                            <Col span={ 14 }>
                                                <div className="gap-1">
                                                    <a href={ item.reportURL }><Button className='view-btn'>Download</Button></a>
                                                    { user?.type === 'manager' && <Button danger className='danger-btn' style={ { margin: 0 } }
                                                        onClick={ () => showDeleteConfirm(item.auditId) }>Remove</Button>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>);
                                }) }
                            </div>
                        </ConfigProvider>
                    </div>
                </Row>
            </div>
            <Footer />

        </Layout>
    );
};

export default Reports;