/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Col, Image, Layout, Modal, Row, Spin, Typography } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import imageslist from '../../../../assests/Images/Image';
import { JobContext } from '../../../../context';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import JobViewTaskTable from './JobViewTaskTable';

const { Text, Title } = Typography;
const { confirm } = Modal;

type JobsViewProps = {
    onJobBreadClick: () => void
}

const JobsView = (props: JobsViewProps) => {
    const { job, cancelOnlyJob, cancelRecurringJob } = useContext(JobContext);
    const { state } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const { onJobBreadClick } = props;

    const { user } = state;

    useEffect(() => {
        console.log(job);
        if (job) {
            setLoading(false);
        }
    }, [job])

    const showOneJobCancelConfirm = () => {
        confirm({
            title: 'Are you sure you want to cancel this job?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk () {
                if (job) {
                    cancelOnlyJob(job?.jobId);
                    onJobBreadClick();
                }
            },
            onCancel () {
                console.log('Cancel');
            },
        });
    };
    const showRecurringJobCancelConfirm = () => {
        confirm({
            title: 'Are you sure you want to cancel all recurring jobs related to this job?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk () {
                if (job) {
                    cancelRecurringJob(job?.jobId);
                    onJobBreadClick();
                }
            },
            onCancel () {
                console.log('Cancel');
            },
        });
    };

    return (
        <Layout className='report-card'>
            <Row className='job-view-modal-header'>
                <Col lg={ 14 } md={ 24 } sm={ 24 }>
                    <Title level={ 2 }>Job Details</Title>
                    {/* <Breadcrumb>
                        <Breadcrumb.Item >Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={ onJobBreadClick }>Jobs</Breadcrumb.Item>
                        <Breadcrumb.Item className='bread-active'>Job details</Breadcrumb.Item>
                    </Breadcrumb> */}
                </Col>
                <Col lg={ 8 } md={ 24 } sm={ 24 } className='cancel-btn-col'>
                    { user?.type === 'manager' && job?.jobStatus === 'Scheduled' && <Row align='middle' justify='end'>
                        { job?.frequency !== 'Once' && <Button className='view-btn-hover cancel-reccuring-btn' onClick={ showRecurringJobCancelConfirm }>Cancel Recurring Jobs</Button> }
                        <Button className='view-btn cancel-btn' onClick={ showOneJobCancelConfirm }>Cancel One-time Job</Button>
                    </Row> }
                </Col>
            </Row>

            { loading ?
                <Row align='middle' justify='center' style={ { height: '60vh' } }>
                    <Col >
                        <Spin size="large">
                            <div className="content" />
                        </Spin>
                    </Col>
                </Row>
                : <div className="job-view-cards">
                    { (job?.jobStatus === 'In_Progress' || job?.jobStatus === 'Completed') && <div className="job-view-card">
                        <h3 className='card-header'>CHECK-IN</h3>
                        <div className="card-content">
                            { user?.type === 'manager' && <div className="row">
                                <h5>Check In time : </h5>
                                <p>{ moment(job?.checkInTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</p>
                            </div> }
                            { user?.type === 'manager' && job?.checkInLocation && <div className="row">
                                <h5>Check-In Location : </h5>
                                { job?.checkInLocation === 'Not Available' ? <p>Not Available</p> :
                                    <a target='_blank' rel="noreferrer" href={ job?.checkInLocation }>Location Link</a> }
                            </div> }
                            { job?.incidentType && <div className="row">
                                <h5>Incident type : </h5>
                                <p>{ job?.incidentType }</p>
                            </div> }
                            { job?.incidentDescription && <div className="row">
                                <h5>Incident : </h5>
                                <p>{ job?.incidentDescription }</p>
                            </div> }
                            { job?.rejectedReason && <div className="row">
                                <h5>Excuse : </h5>
                                <p>{ job?.rejectedReason }</p>
                            </div> }
                        </div>
                        <div className="img-row">
                            { job?.beforePhotos?.split(', ').length > 0 &&
                                <h5 className="heading">Before photos : </h5> }
                            { job?.beforePhotos !== '' ?
                                <div className="imgs">
                                    { job?.beforePhotos?.split(', ').length > 0 && job?.beforePhotos !== '' &&
                                        job?.beforePhotos?.split(', ').map((img) => {
                                            return (
                                                <Image width={ 75 } key={ img } src={ img } alt="img" className="img" />
                                            );
                                        }) }
                                </div> :
                                <div className="imgs">
                                    <Image width={ 100 } src={ imageslist.noimage } alt="img" className="img" />
                                </div>
                            }
                        </div>
                    </div> }

                    { (job?.jobStatus === 'Completed') && <div className="job-view-card">
                        <h3 className='card-header'>CHECK-OUT</h3>
                        <div className="card-content">
                            { user?.type === 'manager' && <div className="row">
                                <h5>Checkout Time : </h5>
                                <p>{ moment(job?.checkOutTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</p>
                            </div> }
                            { user?.type === 'manager' && job?.checkOutLocation && <div className="row">
                                <h5>Check-Out Location: </h5>
                                { job?.checkOutLocation === 'Not Available' ? <p>Not Available</p> :
                                    <a target='_blank' rel="noreferrer" href={ job?.checkOutLocation }>Location Link</a> }
                            </div> }
                        </div>
                        <div className="img-row">
                            { job?.afterPhotos?.split(', ').length && <h5 className="heading">After photos : </h5> }
                            { job?.afterPhotos !== '' ?
                                <div className="imgs">
                                    { job?.afterPhotos?.split(', ').length && job?.afterPhotos?.split(', ').map((img) => {
                                        return (
                                            <Image width={ 75 } key={ img } src={ img } alt="img" className="img" />
                                        );
                                    }) }
                                </div> : <div className="imgs">
                                    <Image width={ 100 } src={ imageslist.noimage } alt="img" className="img" />
                                </div> }
                        </div>
                    </div> }

                    <div className="job-view-card">
                        <h3 className='card-header'>Job Details</h3>
                        <div className="card-content">
                            <div className="row">
                                <h5>Job Date : </h5>
                                <p>{ moment(job?.date).format('DD-MM-YYYY') }</p>
                            </div>
                            <div className="row">
                                <h5>Start Time: </h5>
                                <p>{ moment(job?.startTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</p>
                            </div>
                            <div className="row">
                                <h5>End Time: </h5>
                                <p>{ moment(job?.endTime?.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</p>
                            </div>
                            <div className="row">
                                <h5>Job Status: </h5>
                                <p>{ job?.jobStatus }</p>
                            </div>
                            <div className="row">
                                <h5>Frequency: </h5>
                                <p>{ job?.frequency }</p>
                            </div>
                            <div className="row">
                                <h5>Assigned Member : </h5>
                                <p>{ job?.staffmember?.firstName } { job?.staffmember?.lastName }</p>
                            </div>
                        </div>
                    </div>

                    <div className="job-view-card">
                        <h3 className='card-header'>Client Details</h3>
                        <div className="card-content">
                            <div className="row">
                                <h5>Client Name : </h5>
                                <p>{ job?.client?.name }</p>
                            </div>
                            <div className="row">
                                <h5>Client Site : </h5>
                                <p>{ job?.site?.siteName }</p>
                            </div>
                            <div className="row">
                                <h5>Address : </h5>
                                <p>{ job?.site?.siteAddress }</p>
                            </div>
                            <div className="row">
                                <h5>Contact Name : </h5>
                                <p>{ job?.client?.contactPerson }</p>
                            </div>
                            <div className="row">
                                <h5>Contact Phone : </h5>
                                <p>{ job?.client?.contactNo }</p>
                            </div>
                            <div className="row">
                                <h5>Contact Email : </h5>
                                <p>{ job?.client?.email }</p>
                            </div>
                        </div>
                    </div>

                    { job?.task && <div className="full-row">
                        <JobViewTaskTable tasks={ job.task } />
                    </div> }
                </div> }
        </Layout>
    );
};

export default JobsView;
