import { PlusCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Input, Layout, Row, Typography } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ClientContext } from '../../../context';
import { IClient } from '../../../utils/types';
import ClientsTable from '../Clients/components/ClientsTable';
import Footer from '../Others/Footer';

const { Title } = Typography;
const { Search } = Input;


type ClientsViewProps = {
    onBreadClick: () => void
    onAddClientClick: () => void
    onViewClientClicked: () => void
}
const Clients = (props: ClientsViewProps) => {
    const { onBreadClick, onAddClientClick, onViewClientClicked } = props;
    const { clients } = useContext(ClientContext);
    const [searchResult, setSearchResult] = useState<IClient[]>(clients);
    const [searchValue, setSearchValue] = useState('');

    const onSearch = useCallback((value: string) => {
        setSearchValue(value);
        if (value === '') {
            setSearchResult(clients);
            return;
        }
        const temp: IClient[] = clients.filter((item: IClient) => {
            return (
                item.contactPerson.toLowerCase().includes(value.toLowerCase()) ||
                item.name.toLowerCase().includes(value.toLowerCase())
            );
        });
        setSearchResult(temp);
    }, [clients]);

    useEffect(() => {
        onSearch(searchValue);
    }, [onSearch, searchValue]);

    return (
        <Layout className='report-card'>
            <div className='header-row'>
                <div className="header-col" >
                    <div className="breadcrumb">
                        <Title level={ 2 }>Clients</Title>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={ onBreadClick }>Dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item className='bread-active'>Clients</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='btn-con btn-show-md'>
                        <Button className='view-btn' onClick={ onAddClientClick }><PlusCircleOutlined size={ 24 } />Add Client</Button>
                    </div>
                </div>
                <div className="header-col" >
                    <Search
                        className="search-bar"
                        placeholder="Search by client name or contact name"
                        onSearch={ onSearch }
                        enterButton
                        value={ searchValue }
                        onChange={ (e) => { return setSearchValue(e.target.value); } }
                    />
                    <div className='btn-con btn-show-lg'>
                        <Button className='view-btn' onClick={ onAddClientClick }><PlusCircleOutlined size={ 24 } />Add Client</Button>
                    </div>
                </div>
            </div>
            <Row>
                <ClientsTable data={ searchResult } onViewClientClicked={ onViewClientClicked } />
            </Row>
            <Footer />
        </Layout>
    );
};

export default Clients;