import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import apiClient from '../utils/apiClient';
import { GTask, ITask } from '../utils/types';

interface TaskContextValue {
    tasks: ITask[];
    task: ITask | null;
    jobTasks: ITask[];
    selectJobTasks: GTask[];
    selectedJobTasks: GTask[];
    addTask: (task: Omit<ITask, 'id'>) => void;
    deleteTask: (id: number) => void;
    updateTask: (id: number, updatedTask: ITask) => void;
    getOneTask: (id: number) => void;
    setOneTask: (task: ITask | null) => void;
    setManyJobTasks: (tasks: ITask[]) => void;
    setSelectedManyJobTasks: (tasks: GTask[]) => void;
    setSelectManyJobTasks: (tasks: GTask[]) => void;
    resetSelectedJobTasks: () => void;
}

export const TaskContext = createContext<TaskContextValue>({
    tasks: [],
    jobTasks: [],
    task: null,
    selectJobTasks: [],
    selectedJobTasks: [],
    addTask: () => {
        throw new Error('TaskProvider not found');
    },
    deleteTask: () => {
        throw new Error('TaskProvider not found');
    },
    updateTask: () => {
        throw new Error('TaskProvider not found');
    },
    getOneTask: () => {
        throw new Error('TaskProvider not found');
    },
    setOneTask: () => {
        throw new Error('TaskProvider not found');
    },
    setManyJobTasks: () => {
        throw new Error('TaskProvider not found');
    },
    setSelectedManyJobTasks: () => {
        throw new Error('TaskProvider not found');
    },
    setSelectManyJobTasks: () => {
        throw new Error('TaskProvider not found');
    },
    resetSelectedJobTasks: () => {
        throw new Error('TaskProvider not found');
    },
});

type TaskContextProviderProps = {
    children: ReactNode;
};

export const TaskProvider = ({ children }: TaskContextProviderProps) => {
    const [tasks, setTasks] = useState<ITask[]>([]);
    const [jobTasks, setJobTasks] = useState<ITask[]>([]);
    const [selectJobTasks, setSelectJobTasks] = useState<GTask[]>([]);
    const [selectedJobTasks, setSelectedJobTasks] = useState<GTask[]>([]);
    const [task, setTask] = useState<ITask | null>(null);
    const { state } = useAuthContext();
    const { user } = state;

    useEffect(() => {
        if (user && user?.type === 'manager') {
            fetchTasks();
        }
    }, [user]);

    const fetchTasks = async () => {
        try {
            const response = await apiClient.get('/tasks');
            setTasks(response.data);
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    const addTask = async (task: Omit<ITask, 'id'>) => {
        console.log(task);
        try {
            const response = await apiClient.post('/tasks', task);
            console.log(response.data);
            setTasks((prevTasks) => { return [...prevTasks, response.data]; });
        } catch (error) {
            console.error('Error adding task:', error);
        }
    };

    const deleteTask = async (id: number) => {
        try {
            await apiClient.delete(`/tasks/${id}`);
            setTasks((prevTasks) => { return prevTasks.filter((task) => { return task.taskId !== id; }); });
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    const updateTask = async (id: number, updatedTask: ITask) => {
        try {
            const response = await apiClient.put(`/tasks/${id}`, updatedTask);
            setTasks((prevTasks) => { return prevTasks.map((task) => { return (task.taskId === id ? response.data : task); }); },
            );
        } catch (error) {
            console.error('Error updating task:', error);
        }
    };

    const getOneTask = async (id: number) => {
        try {
            const response = await apiClient.get(`/tasks/${id}`);
            setTask(response.data);
        } catch (error) {
            console.error('Error fetching task:', error);
        }
    };

    const setOneTask = (task: ITask | null) => {
        setTask(task);
    };

    const setManyJobTasks = (tasks: ITask[]) => {
        setJobTasks(tasks);
    };

    const setSelectManyJobTasks = (tasks: GTask[]) => {
        console.log("Task Context", tasks)
        setSelectJobTasks(tasks);
    };

    const resetSelectedJobTasks = () => {
        setSelectedJobTasks([]);
    }

    const setSelectedManyJobTasks = (tasks: GTask[]) => {
        console.log("Selected Task Context", tasks)
        setSelectedJobTasks(tasks);
    };

    const contextValue = useMemo<TaskContextValue>(
        () => {
            return {
                tasks,
                jobTasks,
                task,
                selectJobTasks,
                selectedJobTasks,
                addTask,
                deleteTask,
                updateTask,
                getOneTask,
                setOneTask,
                setManyJobTasks,
                setSelectedManyJobTasks,
                setSelectManyJobTasks,
                resetSelectedJobTasks
            };
        },
        [tasks, jobTasks, task, selectJobTasks, selectedJobTasks]);

    return (
        <TaskContext.Provider value={ contextValue }>
            { children }
        </TaskContext.Provider>
    );
};
