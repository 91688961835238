/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Card, Col, ConfigProvider, Modal, Row, Spin, Table, Tag } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { JobContext, TaskContext } from '../../../../context';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { IJob } from '../../../../utils/types';
import JobsView from './JobsView';


const onChange: TableProps<IJob>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
};

interface JobsTableProps {
    data: IJob[],
    onViewClicked: () => void
}

const JobsTable = (props: JobsTableProps) => {

    const { state } = useAuthContext();
    const { user, business } = state;
    const [loading, setLoading] = useState(true);
    const { data: allData, onViewClicked } = props;
    const { jobs, job, setOneJob, getOneJob } = useContext(JobContext);
    const { setManyJobTasks } = useContext(TaskContext);
    const [csvData, setCsvData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isJobViewModalShow, setIsJobViewModalShow] = useState(false);
    // const itemsPerPage = 15;
    // const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const data = allData.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        const jobTasks = job?.jobtask?.map((item: any) => { return item.task; }) || [];
        setManyJobTasks(jobTasks);
    }, [job, setManyJobTasks]);

    useEffect(() => {
        if (jobs.length > 0) {
            setLoading(false);
        }
        if (user?.type === 'manager') {
            const temp: any = jobs.map((job) => {
                return {
                    'date': moment(job.date).format('DD-MM-YYYY'),
                    'schedule start time': moment(job.startTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A'),
                    'schedule end time': moment(job.endTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A'),
                    'frequency': job.frequency,
                    'client name': job.client.name,
                    'site name': job.site?.siteName,
                    'staff member': job.staffmember.firstName + ' ' + job.staffmember.lastName,
                    'job status': job.jobStatus,
                };
            });
            setLoading(false);
            setCsvData(temp);
        };
        if (user?.type === 'client') {
            const siteFilteredJobs = jobs.filter((item: IJob) => item?.client.clientId === user?.userId);
            const temp: any = siteFilteredJobs.map((job) => {
                return {
                    'date': moment(job.date).format('DD-MM-YYYY'),
                    'schedule start time': moment(job.startTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A'),
                    'schedule end time': moment(job.endTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A'),
                    'frequency': job.frequency,
                    'client name': job.client.name,
                    'site name': job.site?.siteName,
                    'staff member': job.staffmember.firstName + ' ' + job.staffmember.lastName,
                    'job status': job.jobStatus,
                };
            });
            setLoading(false);
            setCsvData(temp);
        };
        if (user?.type === 'siteManager') {
            const siteFilteredJobs = jobs.filter((item: IJob) => item?.siteId === business?.siteId);
            const temp: any = siteFilteredJobs.map((job) => {
                return {
                    'date': moment(job.date).format('DD-MM-YYYY'),
                    'schedule start time': moment(job.startTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A'),
                    'schedule end time': moment(job.endTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A'),
                    'frequency': job.frequency,
                    'client name': job.client.name,
                    'site name': job.site?.siteName,
                    'staff member': job.staffmember.firstName + ' ' + job.staffmember.lastName,
                    'job status': job.jobStatus,
                };
            });
            setLoading(false);
            setCsvData(temp);
        };
    }, [jobs, user?.type, user?.userId]);



    const columns: ColumnsType<IJob> = [
        {
            title: 'Date',
            dataIndex: 'date',
            sorter: (a, b) => {
                const dateA: any = new Date(moment(a.date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                const dateB: any = new Date(moment(b.date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                return dateB - dateA; // Reverse the comparison result
            },
            sortDirections: ['descend', 'ascend'],
            // defaultSortOrder: 'ascend',
            render: (date) => { return moment(date).format('DD-MM-YYYY'); },
            width: '8%',
        },
        {
            title: 'Sched Start Time',
            dataIndex: 'startTime',
            sorter: (a, b) => {
                const startTimeA: any = moment(a.startTime, 'YYYY-MM-DDTHH:mm:ss');
                const startTimeB: any = moment(b.startTime, 'YYYY-MM-DDTHH:mm:ss');
                return startTimeA - startTimeB;
            },
            width: '8%',
            render: (startTime) => { return moment(startTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A'); },
        },
        {
            title: 'Sched End Time',
            dataIndex: 'endTime',
            sorter: (a, b) => {
                const endTimeA: any = moment(a.endTime, 'YYYY-MM-DDTHH:mm:ss');
                const endTimeB: any = moment(b.endTime, 'YYYY-MM-DDTHH:mm:ss');
                return endTimeA - endTimeB;
            },
            width: '8%',
            render: (endTime) => { return moment(endTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A'); },
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            defaultSortOrder: 'descend',
            width: '4%',
            responsive: ['lg'],
            filters: [
                {
                    text: 'Once',
                    value: 'Once',
                },
                {
                    text: 'Daily',
                    value: 'Daily',
                },
                {
                    text: 'Weekly',
                    value: 'Weekly',
                },
                {
                    text: 'Fortnightly',
                    value: 'Fortnightly',
                },
                {
                    text: 'Monthly',
                    value: 'Monthly',
                },
                {
                    text: 'Quarterly',
                    value: 'Quarterly',
                },
                {
                    text: 'Half Yearly',
                    value: 'Half_Yearly',
                },
                {
                    text: 'Annually',
                    value: 'Annually',
                },
            ],
            onFilter: (value, record) => { return record.frequency === value; },
            render: (value) => {
                if (value === 'Half_Yearly') {
                    return 'Half Yearly';
                }
                return value; // Return the value directly for other cases
            },
        },
        {
            title: 'Client Name',
            dataIndex: 'client',
            width: '8%',
            render: (client) => { return client?.name || 'N/A'; },
        },
        {
            title: 'Site Name',
            dataIndex: 'site',
            defaultSortOrder: 'descend',
            width: '6%',
            responsive: ['lg'],
            render: (site) => { return site?.siteName || 'N/A'; },
        },
        {
            title: 'Staff Name',
            dataIndex: 'staffmember',
            defaultSortOrder: 'descend',
            width: '6%',
            responsive: ['lg'],
            render: (staffmember) => {
                return staffmember ? `${staffmember.firstName} ${staffmember.lastName}` : 'N/A';
            },
        },
        {
            title: 'Job Status',
            dataIndex: 'jobStatus',
            defaultSortOrder: 'ascend',
            width: '5%',
            render: (status) => {
                let tagColor = '';
                switch (status) {
                    case 'Scheduled':
                        tagColor = 'orange';
                        break;
                    case 'In_Progress':
                        tagColor = 'yellow';
                        break;
                    case 'Completed':
                        tagColor = 'green';
                        break;
                    case 'Declined':
                        tagColor = 'black';
                        break;
                    case 'Cancelled':
                        tagColor = 'red';
                        break;
                    default:
                        tagColor = 'default';
                }
                return <Tag color={ tagColor }>{ status }</Tag>;
            },
            filters: [
                {
                    text: 'Scheduled',
                    value: 'Scheduled',
                },
                {
                    text: 'In-Progress',
                    value: 'In_Progress',
                },
                {
                    text: 'Completed',
                    value: 'Completed',
                },
                {
                    text: 'Declined',
                    value: 'Declined',
                },
                {
                    text: 'Cancelled',
                    value: 'Cancelled',
                },
            ],
            onFilter: (value, record) => {
                if (value === 'In_Progress') {
                    return record.jobStatus === 'In-Progress' || record.jobStatus === 'In_Progress';
                }
                return record.jobStatus === value;
            },
            sorter: (a, b) => {
                const completedOrder = a.jobStatus === 'Completed' ? -1 : b.jobStatus === 'Completed' ? 1 : 0;
                if (completedOrder !== 0) {
                    return completedOrder;
                }
                return a.jobStatus.localeCompare(b.jobStatus);
            },
        },
    ];
    return (
        <div className='jobs-table'>
            <ConfigProvider theme={ { token: { colorPrimary: '#5a82f9' } } }>
                <div className='jobs-item-table'>
                    <Table columns={ columns } onChange={ onChange }
                        // pagination={ {
                        //     current: currentPage,
                        //     pageSize: itemsPerPage,
                        //     total: allData.length,
                        //     onChange: (page) => setCurrentPage(page),
                        // } }
                        // pagination={ {
                        //     pageSize: 15,
                        // } }
                        pagination={ {
                            defaultPageSize: 15, // Set the default page size to 15
                            pageSizeOptions: ['15', '30', '50'], // Optional: Specify additional page size options
                            showSizeChanger: true, // Optional: Allow users to change page size
                            // showQuickJumper: true, // Optional: Allow users to jump to a specific page
                            // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, // Optional: Display total number of items
                        } }
                        loading={ {
                            spinning: loading,
                            indicator: <Spin size="large" />,
                        } }
                        size={ 'small' }
                        dataSource={ allData }
                        onRow={ (record, rowIndex) => {
                            return {
                                onClick: (event) => {
                                    if (user?.type === 'manager' || user?.type === 'client') {
                                        setOneJob(null);
                                        getOneJob(record.jobId);
                                        // onViewClicked();
                                        setIsJobViewModalShow(true);
                                    }
                                },
                            };
                        } }
                    />
                </div>
                <Modal
                    // title="Modal 1000px width"
                    centered
                    open={ isJobViewModalShow }
                    // onOk={ () => setIsJobViewModalShow(true) }
                    onCancel={ () => setIsJobViewModalShow(false) }
                    width={ 1200 }
                    footer={null}
                    className='jobview-modal'
                    // style={ { width: 'max-content' } }
                >
                    <JobsView onJobBreadClick={ function (): void {
                        console.log("Bread Clicked");
                    } } />
                </Modal>
                <div className="table-card-list">
                    { allData.slice(0, 15).map((item) => {
                        return (
                            <Card key={ item.jobId } className='table-card' onClick={ () => {
                                setOneJob(item);
                                // onViewClicked();
                                setIsJobViewModalShow(true);
                            } }>
                                <Row>
                                    <Col className="input-label-text" span={ 10 }>Date</Col>
                                    <Col span={ 14 }>{ moment(item.endTime.split('T')[0]).format('DD-MM-YYYY') }</Col>
                                </Row>
                                <Row>
                                    <Col className="input-label-text" span={ 10 }>Sched Start Time</Col>
                                    <Col span={ 14 }>{ moment(item.startTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</Col>
                                </Row>
                                <Row>
                                    <Col className="input-label-text" span={ 10 }>Sched End Time</Col>
                                    <Col span={ 14 }>{ moment(item.endTime.slice(11, 19), 'HH:mm:ss').format('hh:mm A') }</Col>
                                </Row>
                                <Row>
                                    <Col className="input-label-text" span={ 10 }>Frequency</Col>
                                    <Col span={ 14 }>{ item.frequency }</Col>
                                </Row>
                                <Row>
                                    <Col className="input-label-text" span={ 10 }>Client Name</Col>
                                    <Col span={ 14 }>{ item.client?.name }</Col>
                                </Row>
                                <Row>
                                    <Col className="input-label-text" span={ 10 }>Site Name</Col>
                                    <Col span={ 14 }>{ item.site?.siteName }</Col>
                                </Row>
                                {/* <Row>
                                    <Col className="input-label-text" span={ 10 }>Job Status</Col>
                                    <Col span={ 14 }>{ item.jobStatus }</Col>
                                    <Col className="input-label-text" span={ 10 }>Staff Name</Col>
                                    <Col span={ 14 }>{ item.site?.staff }</Col>
                                </Row> */}
                                <Row>
                                    <Col className="input-label-text" span={ 10 }>Job Status</Col>
                                    <Col span={ 14 }><MobileViewTag jobStatus={ item.jobStatus } /></Col>
                                </Row>
                                {/* <br></br>
                                <div className="float-right">
                                    <Button className='view-btn' onClick={onViewClicked}>View</Button>
                                </div> */}

                            </Card>
                        );
                    }) }
                </div>
                <Button className='export-btn'> <CSVLink data={ csvData } filename={ 'jobs details.csv' }>Export To CSV</CSVLink>
                </Button>
            </ConfigProvider>
        </div>);
};


const MobileViewTag = (props: any) => {
    let { jobStatus } = props;
    let tagColor = '';
    switch (jobStatus) {
        case 'Scheduled':
            tagColor = 'orange';
            break;
        case 'In_Progress':
            tagColor = 'yellow';
            jobStatus = 'In-Progress';
            break;
        case 'Completed':
            tagColor = 'green';
            break;
        case 'Declined':
            tagColor = 'red';
            break;
        case 'Cancelled':
            tagColor = 'red';
            break;
        default:
            tagColor = 'default';
    }
    return <Tag color={ tagColor }>{ jobStatus }</Tag>;
};

export default JobsTable;
