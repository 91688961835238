/* eslint-disable indent */
import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: 'AKIAZIGW4DC2PXRSU57J',
    secretAccessKey: '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',
    region: 'ap-southeast-2',
    signatureVersion: 'v4',
});

const s3 = new AWS.S3();
const s3BucketName = 'smikappbucket';

export const uploadImageToS3 = async (file: File, folderName: string): Promise<string> => {
    let fileName = '';
    if (folderName === 'siteAuditReports') {
        fileName = `${folderName}/image_${Date.now()}_${file.name}` + '.pdf';
    } else {
        fileName = `${folderName}/image_${Date.now()}_${file.name}`;
    }

    const params: AWS.S3.PutObjectRequest = {
        Bucket: s3BucketName,
        Key: fileName,
        Body: file,
    };

    try {
        const response = await s3.upload(params).promise();
        const imageUrl = response.Location;
        return imageUrl;
    } catch (error) {
        console.error('Error uploading image to S3:', error);
        throw error;
    }
};
