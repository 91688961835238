import { InboxOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Typography, Upload, UploadFile, message, notification } from 'antd';
import AWS from 'aws-sdk';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { baseUrl } from '../../../../config/config';
import { useAuthContext } from '../../../../hooks/useAuthContext';

const { Dragger } = Upload;
const { Title } = Typography;

type contactProps = {
    onBreadClick: () => void,
}

AWS.config.update({
    accessKeyId: 'AKIAZIGW4DC2PXRSU57J',
    secretAccessKey: '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',
    region: 'ap-southeast-2',
    signatureVersion: 'v4',
});

const s3 = new AWS.S3();
// const uploadBlobToS3 = async (blobUrl: string) => {
//     const response = await fetch(blobUrl);
//     const blob = await response.blob();
//     const fileName = `image_${Date.now()}`;
//     const params = {
//         Bucket: 'smikappbucket',
//         Key: `logo/${fileName}`,
//         Body: blob,
//     };

//     console.log('Image Uploading');
//     try {
//         const { Location } = await s3.upload(params).promise();
//         console.log('Uploaded to S3:', Location);
//         return Location;
//     } catch (error) {
//         console.error('Error uploading to S3:', error);
//         throw error;
//     }
// };

const ContactSection = (props: contactProps) => {
    const [form] = Form.useForm();
    const { onBreadClick } = props;
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const { state } = useAuthContext();
    const { user, business } = state;
    const [imageUrlList, setImageUrlList] = useState<string[]>([]);
    const [reset, setReset] = useState(false);

    const onFinish = async (values: any) => {
        // You can customize this function to handle form submission
        console.log(values);
        form.resetFields();
        // setFileList([]);
        const { name, message } = values;
        const contactDetails = {
            name: name,
            message: message,
            businessId: business?.businessId,
            email: user?.email,
            fileList: imageUrlList,
        }
        const response = await axios.post(`${baseUrl}/users/contractContact`, contactDetails);
        if (response.status === 200) {
            notification.success({
                message: 'Success',
                description: 'You have contact the business manager successfully.',
            });
        }
        setReset(true);
        setImageUrlList([]);
        setFileList([]);
    };

    const onClear = () => {
        // You can customize this function to handle cancel action
        form.resetFields();
        setReset(true);
        setImageUrlList([]);
        setFileList([]);
    };

    // const handleImageUpload = async (items: any) => {
    //     const updatedImageUrlList: string[] = [...imageUrlList];
    //     //@ts-ignore
    //     console.log("Items", items);
    //     for (const item of items) {
    //         const imageUrl = await uploadBlobToS3(item.url);
    //         console.log('Image Url', imageUrl);
    //         updatedImageUrlList.push(imageUrl);
    //     }
    //     // console.log('Image Url', imageUrl);
    //     //@ts-ignore
    //     // const updatedUrl = imageUrl + '.' + items?.file.name.split('.').pop();
    //     updatedImageUrlList.push(imageUrl);

    //     setFileList(items);
    //     setImageUrlList(updatedImageUrlList);
    // };

    const handleImageUpload = async (file: File) => {
        setReset(false);
        try {
            const fileName = `image_${Date.now()}_${file.name}`;
            const params = {
                Bucket: 'smikappbucket',
                Key: `logo/${fileName}`,
                Body: file,
            };

            console.log('Image Uploading');
            const { Location } = await s3.upload(params).promise();
            console.log('Uploaded to S3:', Location);
            message.success(`${file.name} file uploaded successfully.`);
            const updatedImageUrlList = [...imageUrlList, Location];
            setImageUrlList(updatedImageUrlList);
        } catch (error) {
            console.error('Error uploading to S3:', error);
            throw error;
        }
    };

    useEffect(() => {
        console.log('Image Url List', imageUrlList);
    }, [imageUrlList]);


    return (
        <Layout className='report-card' >
            <Row className='header-row'>
                <Col span={ 20 }>
                    <Title level={ 2 }>Contact Form</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={ onBreadClick }>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item className='bread-active'>Contact</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col span={ 24 }>
                    <Form form={ form } onFinish={ onFinish } layout='vertical'>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={ [{ required: true, message: 'Please enter your name!' }] }
                        >
                            <Input placeholder="Your Name" required />
                        </Form.Item>

                        <Form.Item label='Message' name="message" rules={ [{ required: true, message: 'Please enter a message!' }] }>
                            <Input.TextArea placeholder="Message" required showCount rows={ 10 } maxLength={ 1500 } />
                        </Form.Item>
                        <Form.Item>
                        <Dragger
                            name="files"
                            beforeUpload={ (file) => {
                                handleImageUpload(file);
                                return false;
                            } }
                            multiple
                            showUploadList={!reset}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                        </Form.Item>
                        <div style={ { display: 'flex', justifyContent: 'flex-end', marginTop: '16px' } }>
                            <Form.Item style={ { marginRight: 8 } }>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={ onClear }>
                                    Clear
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Layout>
    );
};

export default ContactSection;
