/* eslint-disable react/no-unescaped-entities */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { EnvironmentOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Form, Input, Layout, Modal, Row, Spin, Typography, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ClientContext } from '../../../../context';
import { uploadImageToS3 } from '../../../../utils/s3Utils';
import { IClient } from '../../../../utils/types';
import Footer from '../../Others/Footer';
import LocationModal from './LocationModal';

const { Title } = Typography;

type AddClientProps = {
    onBreadClick: () => void;
    onClientClick: () => void;
};

type Location = {
    address: string,
    coordinates: {
        lat: number,
        lng: number,
    },
    link: string,
};

const AddClient = (props: AddClientProps) => {
    const [form] = Form.useForm();
    const { onBreadClick, onClientClick } = props;
    const isMobile = useMediaQuery({ maxWidth: 576 });
    const { addClient } = useContext(ClientContext);
    const [isUploading, setIsUploading] = useState(false);

    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const [rowIndex, setRowIndex] = useState(0);
    const [locationList, setLocationList] = useState<Location[]>([]);

    const handleImageChange = async (info: any) => {
        try {
            form.setFieldsValue({ profilePictureFile: info.file });
        } catch (error) {
            console.error(error);
        }
    };

    const handleFinish = async (values: any) => {
        setIsUploading(true);
        const { abn, name, contactPerson, contactNo, email, sights, profilePictureFile } = values;
        console.log(values);

        try {
            let url = "no image";
            if (profilePictureFile) {
                url = await uploadImageToS3(profilePictureFile, 'clientProfilePictures');
            }

            const clientData: Omit<IClient, 'clientId'> = {
                abn,
                name,
                contactPerson,
                contactNo,
                email,
                profilePicture: url, // Use the updated profilePicture value
                site: sights.map((site: any, idx: number) => {
                    return {
                        siteName: site?.siteName,
                        locationLink: locationList[idx] ? locationList[idx]?.link : site.locationLink,
                        address: locationList[idx] ? locationList[idx]?.address : site.address,
                    };
                }),
            };

            addClient(clientData);
            onClientClick();
        } catch (error) {
            console.error('Error uploading image to S3:', error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleLocationModalOk = () => {
        return setLocationModalOpen(false);
    };

    const locationModal = () => {
        // const { isLoaded } = useLoadScript({
        //     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
        // });
        return (
            <Modal
                open={ locationModalOpen }
                onOk={ handleLocationModalOk }
                onCancel={ () => {
                    return setLocationModalOpen(false);
                } }
            >
                {/* {isLoaded ? (
                    <GoogleMap zoom={10} center={{ lat: 33.8, lng: 151.2 }} mapContainerClassName='map'>
                        <Marker position={{ lat: 33.8, lng: 151.2 }} />
                    </GoogleMap>
                ) : null} */}
            </Modal>
        );
    };

    return (
        <Layout className="report-card">
            <Row className="header-row">
                <Col span={ 20 }>
                    <Title level={ 2 }>Add Client</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={ onBreadClick }>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={ onClientClick }>Clients</Breadcrumb.Item>
                        <Breadcrumb.Item className="bread-active">Add Client</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col span={ 24 }>
                    <Form
                        form={ form }
                        labelCol={ { span: 10 } }
                        wrapperCol={ { span: 14 } }
                        requiredMark={ true }
                        className="basic-form double-col-form"
                        layout={ isMobile ? 'vertical' : 'horizontal' }
                        onFinish={ handleFinish }
                    >
                        <Row className="double-column-from-row" gutter={ 48 }>
                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item label="ABN" name="abn" rules={ [{ required: false, message: 'Please input the ABN!' }] }>
                                    <Input placeholder="Please enter ABN here!" />
                                </Form.Item>
                            </Col>
                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item
                                    label="Client Name"
                                    name="name"
                                    rules={ [
                                        {
                                            required: true,
                                            message: 'Please input the Client Name!',
                                        },
                                    ] }
                                >
                                    <Input placeholder="Please enter Client Name here!" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="double-column-from-row" gutter={ 48 }>
                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item
                                    label="Contact Person"
                                    name="contactPerson"
                                    rules={ [
                                        {
                                            required: true,
                                            message: 'Please input the Client Name!',
                                        },
                                    ] }
                                >
                                    <Input placeholder="Please enter Contact person Name here" />
                                </Form.Item>
                            </Col>
                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item
                                    label="Contact No"
                                    name="contactNo"
                                    rules={ [{ required: true, message: 'Please input the Contact No!' }] }
                                >
                                    <Input placeholder="Please enter Contact No here!" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="double-column-from-row" gutter={ 48 }>
                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={ [
                                        {
                                            required: true,
                                            message: 'Please input the email!',
                                        },
                                        {
                                            type: 'email',
                                            message: 'Please input a valid email!',
                                        },
                                    ] }
                                >
                                    <Input placeholder="Please enter email here!" />
                                </Form.Item>
                            </Col>

                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item label="Profile picture" name="profilePictureFile">
                                    {/* <ProfilePictureUpload /> */ }
                                    <ImgCrop>
                                        <Upload
                                            beforeUpload={ () => {
                                                return false;
                                            } } // Prevent automatic file upload
                                            onChange={ handleImageChange }
                                            accept="image/png, image/gif, image/jpeg"
                                        >
                                            <Button icon={ <UploadOutlined /> }>Click to Upload</Button>
                                        </Upload>
                                    </ImgCrop>
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* <Row>
                            <Divider orientation="left">
                                Site Details
                            </Divider>
                        </Row> */}

                        <h1 className="heading-add-client-form">Site Details</h1>

                        <Row className='dyanmic-form-input-row'>
                            <Form.List name="sights"
                                initialValue={ [{}] }
                            >
                                { (fields, { add, remove }) => {
                                    return (
                                        <>
                                            { fields.map((field, index) => {
                                                return (
                                                    <div key={ field.key } className='location-input'>

                                                        <div className='col'>
                                                            <label>Site Name</label>
                                                            <Form.Item
                                                                { ...field }
                                                                style={ { flexGrow: '1' } }
                                                                name={ [field.name, 'siteName'] }
                                                                rules={ [{ required: true, message: 'Please input Site Name here' }] }
                                                                wrapperCol={ { span: 24 } }

                                                            >
                                                                <Input placeholder='Please enter site name here!' />
                                                            </Form.Item>
                                                        </div>

                                                        <div className="col">
                                                            <label>Location Link</label>
                                                            <div className="content">
                                                                {
                                                                    locationList[index] ?
                                                                        <a target="_blank" href={ locationList[index].link } rel="noreferrer">Google Map Link</a>
                                                                        : (
                                                                            <Form.Item
                                                                                { ...field }
                                                                                style={ { flexGrow: '1' } }
                                                                                name={ [field.name, 'locationLink'] }
                                                                                rules={ [{ required: true, message: 'Please input location link here' }] }
                                                                                wrapperCol={ { span: 24 } }

                                                                            >
                                                                                <Input placeholder='Please enter location link here!' />
                                                                            </Form.Item>
                                                                        )
                                                                }
                                                                <Form.Item>
                                                                    <Button type="primary" onClick={ () => {
                                                                        setRowIndex(index);
                                                                        setLocationModalOpen(true);
                                                                    } }><EnvironmentOutlined className="form-icon" /></Button>
                                                                </Form.Item>
                                                                <LocationModal
                                                                    locationModalOpen={ locationModalOpen }
                                                                    setLocationModalOpen={ setLocationModalOpen }
                                                                    setLocationList={ setLocationList }
                                                                    locationList={ locationList }
                                                                    rowIndex={ rowIndex }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='col'>
                                                            <label>Address</label>
                                                            <div className="content">
                                                                {
                                                                    locationList[index] ?
                                                                        <p>{ locationList[index]?.address }</p>
                                                                        : (
                                                                            <Form.Item
                                                                                { ...field }
                                                                                style={ { flexGrow: '1' } }
                                                                                name={ [field.name, 'address'] }
                                                                                rules={ [{ required: true, message: 'Please input address here' }] }
                                                                                wrapperCol={ { span: 24 } }
                                                                            >
                                                                                <Input placeholder='Please enter address here!' />
                                                                            </Form.Item>
                                                                        )
                                                                }
                                                                { !(index === 0 && fields.length <= 1) && (
                                                                    <Form.Item>
                                                                        <Button onClick={ () => {
                                                                            locationList.splice(index, 1);
                                                                            remove(field.name);
                                                                        } }>
                                                                            <MinusCircleOutlined className="form-icon" />
                                                                        </Button>
                                                                    </Form.Item>
                                                                ) }
                                                            </div>
                                                        </div>

                                                    </div>
                                                );
                                            }) }
                                            <Row style={ { display: 'flex', justifyContent: 'center' } }>
                                                <Col lg={ 12 }>
                                                    <Form.Item>
                                                        <Button
                                                            className="add-btn"
                                                            type="dashed"
                                                            onClick={ () => {
                                                                return add();
                                                            } }
                                                            block
                                                            icon={ <PlusOutlined /> }
                                                        >
                                                            Add Sites
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    );
                                } }
                            </Form.List>
                        </Row>

                        <Row className="form-submit-row" style={ { marginBottom: '2em' } }>
                            <Button className="view-btn" htmlType="submit" disabled={ isUploading }>
                                { isUploading ? <Spin /> : 'Submit' }
                            </Button>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Footer />
        </Layout>
    );
};

export default AddClient;
