import moment from "moment";

export function formatDateAndTime (dateTimeString: string) {
    const dateTime = new Date(dateTimeString);
    const date = dateTime.toLocaleDateString();
    const time = dateTime.toLocaleTimeString();
    return `${date} ${time}`;
}

export const getCurrentDateTime = () => {
    const currentDateTime = moment();
    const formattedTime = currentDateTime.format('YYYY-MM-DD HH:mm:ss');
    const date = formattedTime.slice(0, 10);
    const dateTime = date + 'T' + moment(formattedTime).format('HH:mm:ss') + '.00Z';
    return dateTime;
};

export const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}