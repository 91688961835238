export function pervious7Days() {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const days = [];
    const dates = [];

    for (let i = 0; i < 7; i++) {
        const day = new Date();
        day.setDate(yesterday.getDate() - i);
        dates.push(day);
        const dayName = day.toLocaleDateString(undefined, { weekday: 'long' });
        days.push(dayName);
    }

    return { days };
}

