import axios from 'axios';
import { baseUrl } from '../../../config/config';

export const userResetPassword = async (email: string, password: string) => {
    const userData = {
        email,
        password,
    };
    try {
        const response = await axios.post(`${baseUrl}/users/reset`, userData);
        return response.data;
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
};

export const managerUpdateData = async (values: any, userId: string) => {
    const userData = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        contactNo: values.contactNo,
        abn: values.abn,
        businessName: values.businessName,
        logo: values.logo,
    };
    try {
        const response = await axios.put(`${baseUrl}/managers/${userId}`, userData);
        return response.data;
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
};