import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import './assests/style/main.scss';
import CMDashboard from './components/ContractManager/CMDashBoard';
import Dashboard from './components/Dashboard/Dashboard';
import Login from './components/Login';
import Signup from './components/Signup/Signup';
import SMDashboard from './components/SiteManager/SMDashboard';
import { AnalyticsProvider } from './context/AnalyticsContext';
import { AuthContextProvider } from './context/AuthContext';
import { ClientProvider } from './context/ClientContext';
import { JobProvider } from './context/JobContext';
import { SiteAuditProvider } from './context/SiteAuditContext';
import { StaffProvider } from './context/StaffContext';
import { TaskProvider } from './context/TaskContext';
import { useAuthContext } from './hooks/useAuthContext';


const App = () => {
    const { state } = useAuthContext();
    const { user } = state;
   
    return (
        <SiteAuditProvider>
            <AnalyticsProvider>
                <ClientProvider>
                    <StaffProvider>
                        <TaskProvider>
                            <JobProvider>
                                <TaskProvider>
                                    <Routes>
                                        <Route path="/" element={ !user ? <Login /> : <Navigate to="/dashboard" /> } />
                                        <Route path="/signup" element={ !user ? <Signup /> : <Navigate to="/dashboard" /> } />
                                        <Route path="/dashboard" element={ user?.type === 'manager' ? (
                                            <Dashboard />
                                        ) : user?.type === 'client' ? (
                                            <CMDashboard />
                                        ) : user?.type === 'siteManager' ?
                                            (
                                                <SMDashboard />
                                            ) : <Navigate to="/" />
                                        } />

                                    </Routes>
                                </TaskProvider>
                            </JobProvider>
                        </TaskProvider>
                    </StaffProvider>
                </ClientProvider>
            </AnalyticsProvider>
        </SiteAuditProvider>
    );
};

const AppWithRouter = () => {
    return (
        <AuthContextProvider>
            <HashRouter>
                <App />
            </HashRouter>
        </AuthContextProvider>
    );
};
export default AppWithRouter;
