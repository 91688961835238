/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { Column } from '@ant-design/plots';
import { each, groupBy } from '@antv/util';

import { pervious7Days } from '../../../utils/getPervious7Days';


const BarChart = () => {
    const { days } = pervious7Days();

    const data = [
        {
            type: 'Completed',
            client: 'Monday',
            value: 2,
        },
        {
            type: 'Uncompleted',
            client: 'Tuesday',
            value: 1,
        },
        {
            type: 'Uncompleted',
            client: 'Wednesday',
            value: 4,
        },
        {
            type: 'Completed',
            client: 'Thursday',
            value: 1,
        }, {
            type: 'Completed',
            client: 'Friday',
            value: 1,
        },
        {
            type: 'Uncompleted',
            client: 'Saturday',
            value: 3,
        },
        
        {
            type: 'Completed',
            client: 'Sunday',
            value: 1,
        },
        {
            type: 'Uncompleted',
            client: 'Monday',
            value: 5,
        }, {
            type: 'Completed',
            client: 'Tuesday',
            value: 4,
        }, {
            type: 'Uncompleted',
            client: 'Wednesday',
            value: 3,
        }, {
            type: 'Completed',
            client: 'Thursday',
            value: 1,
        },
    ];



    const annotations: Array<{ type: string; position: any[]; content: string; style: { textAlign: string; fontSize: number; fill: string; }; offsetY: number; }> = [];
    each(groupBy(data, 'client'), (values, k) => {
        const value = values.reduce((a: any, b: { value: any; }) => { return a + b.value; }, 0);
        annotations.push({
            type: 'text',
            position: [k, value],
            content: `${value}`,
            style: {
                textAlign: 'center',
                fontSize: 14,
                fill: 'rgba(0,0,0,0.85)',
            },
            offsetY: -10,
        });
    });
    const config = {
        data,
        isStack: true,
        xField: 'client',
        yField: 'value',
        color: ['#ffa502', '#2ed573'],
        seriesField: 'type',
        label: {
            position: 'middle',
            layout: [
                {
                    type: 'interval-adjust-position',
                }, {
                    type: 'interval-hide-overlap',
                }, {
                    type: 'adjust-color',
                },
            ],
        },
        annotations,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return (<div className='chart' style={{ borderRight: '1px solid #ccc' }}><Column {...config} /></div>);
};

export default BarChart;
