/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { pdf } from '@react-pdf/renderer';
import { Breadcrumb, Button, Col, Layout, Row, Select, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { ClientContext, SiteAuditContext, TaskContext } from '../../../context';
import { uploadImageToS3 } from '../../../utils/s3Utils';
import { getCurrentDateTime } from '../../../utils/timeUtils';
import { IClient, ISite } from '../../../utils/types';
import Footer from '../Others/Footer';
import PDFFile from './components/PDFFile';
import TaskList from './components/TaskList';

const { Title, Text } = Typography;

type SiteJobsProps = {
    onBreadClick: () => void;
    onReportCreated: () => void;
};

const SiteJobs: React.FC<SiteJobsProps> = (props) => {
    const { clients } = useContext(ClientContext);
    const { tasks } = useContext(TaskContext);
    const [client, setClient] = useState<IClient>();
    const [site, setSite] = useState<ISite>();
    const [selectedClient, setSelectedClient] = useState<string>();
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [selectedSite, setSelectedSite] = useState<number>();
    const { auditData, addAuditReport } = useContext(SiteAuditContext);
    const [uploadingImages, setUploadingImages] = useState(false); // Track image upload status
    const [clickedAll, setClickedAll] = useState(false); // Track image upload status
    const { onBreadClick, onReportCreated } = props;

    const handleChangeClient = (value: string) => {
        console.log(value);
        setSelectedTasks([]);
        // @ts-ignore
        setSelectedSite(0);
        // @ts-ignore
        setSelectedClient(value);
        //@ts-ignore
        setClient(clients.find((client) => { return client.clientId === value; }));

    };

    const handleChangeSite = (value: number) => {
        //@ts-ignore
        setSite(client?.site?.find((site: any) => { return site.siteId === value; }));
        setSelectedSite(value);
        //@ts-ignore
        setSelectedTasks(tasks.filter((task) => task.siteId === value));
    };


    const handlePDFDownload = () => {
        setUploadingImages(true);
    };

    //generate reports
    useEffect(() => {
        const generatePDF = async () => {
            console.log(auditData);
            if (uploadingImages) {
                const userData = localStorage.getItem('userData');
                const user = userData ? JSON.parse(userData) : {};
                const reportTime = getCurrentDateTime();
                //@ts-ignore
                const pdfContent = <PDFFile dataRows={ auditData } client={ client }
                    site={ site } />;
                const pdfBlob = await pdf(pdfContent).toBlob();

                const url = URL.createObjectURL(pdfBlob);

                // Create a link element
                const link = document.createElement('a');
                link.href = url;
                link.download = 'siteAuditReport.pdf';

                // Simulate a click event to trigger the download
                link.click();

                // Clean up by revoking the object URL
                URL.revokeObjectURL(url);
                //@ts-ignore
                const reportURL = await uploadImageToS3(pdfBlob, 'siteAuditReports');
                if (selectedSite && site?.siteName && client?.name && selectedClient) {
                    //@ts-ignore
                    const res = addAuditReport({
                        'siteId': selectedSite,
                        'businessId': user.business.businessId,
                        'reportTime': reportTime,
                        'siteName': site?.siteName,
                        'clientName': client?.name,
                        'reportURL': reportURL,
                        'clientId': selectedClient,
                    });
                    onReportCreated();
                }
                // console.log(pdfBlob);
                // const pdfUrl = URL.createObjectURL(pdfBlob);
                // window.open(pdfUrl);
                setUploadingImages(false); // Reset image upload status
            }

        };
        generatePDF();

    }, [uploadingImages, auditData]);

    useEffect(() => {
        if (auditData.length !== 0) {
            const clickedAll = auditData.every((data) => { return data.clicked === true; });
            setClickedAll(clickedAll);
            console.log(clickedAll);
        }

    }, [auditData]);


    //get client options array
    const clientOptions = clients.sort((a, b) => `${a?.name}`.localeCompare(`${b?.name}`)).map((client) => {
        return {
            value: client.clientId,
            label: client.name,
        };
    });

    const siteOptions = clients.flatMap((client) => {
        if (client.clientId === selectedClient) {
            return client.site.sort((a, b) => `${a?.siteName}`.localeCompare(`${b?.siteName}`)).map((site) => {
                return {
                    value: site.siteId,
                    label: site?.siteName,
                };
            });
        }
        return []; // Return an empty array for non-selected clients
    });

    return (
        <Layout className="report-card">
            <Row>
                <Col>
                    <Title level={ 2 }>Site Auditing</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={ onBreadClick }>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item className="bread-active">Site-Auditing</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row className="client-site-select">
                <Col lg={ { span: 10 } } md={ { span: 12 } } sm={ { span: 24 } } xs={ { span: 24 } }>
                    <Text className="input-label-text">Client Name : </Text>
                    <Select
                        className="w-fix"
                        defaultValue="Select client name"
                        onChange={ handleChangeClient }
                        options={ [...clientOptions] }
                    />
                </Col>
                <Col lg={ { span: 10 } } md={ { span: 12 } } sm={ { span: 24 } } xs={ { span: 24 } }>
                    <Text className="input-label-text">Site Name : </Text>
                    <Select className="w-fix"
                        //@ts-ignore
                        defaultValue='Select site name'
                        onChange={ handleChangeSite }
                        options={ [...siteOptions] }
                        //@ts-ignore
                        value={ selectedSite === 0 ? 'Select site name' : selectedSite }
                    />

                </Col>
            </Row>
            <Row style={ { overflowY: 'scroll' } } className="site-audit-report">
                <Col
                    lg={ { span: 24 } }
                    md={ { span: 24 } }
                    sm={ { span: 24 } }
                    xs={ { span: 24 } }
                    style={ { marginTop: '1em' } }
                    className="acordian-container"
                >
                    <TaskList
                        task={ selectedTasks }
                    />
                </Col >
            </Row >

            <Row className="export-btn-row">
                <Button className={ `export-btn ${uploadingImages || !clickedAll ? 'disabled-btn' : ''}` } disabled={ uploadingImages || !clickedAll } onClick={ handlePDFDownload }>
                    Create Report
                </Button>
            </Row>

            <Footer />
        </Layout >
    );
};

export default SiteJobs;
