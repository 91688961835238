/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import apiClient from '../utils/apiClient';

interface SiteAuditValue {
    taskId: number;
    area: string;
    businessId: number;
    jobId: number;
    outcome: string;
    securityStatus: string;
    status: string;
    task: string;
    comment: string;
    rating: string;
    images: File[];
    clicked: boolean;
}

interface AuditReprotValue {
    siteId: number;
    auditId: number;
    businessId: number;
    clientId: string;
    reportTime: string;
    siteName: string;
    clientName: string;
    reportURL: string;
    securityStatus: string;
}

interface SiteAuditProps {
    auditData: SiteAuditValue[];
    auditReports: AuditReprotValue[];
    addImages: (taskId: number, images: File[]) => void;
    addRating: (taskId: number, rating: string) => void;
    addComment: (taskId: number, comment: string) => void;
    clicked: (area: string) => void;
    setInitialTasks: (tasks: SiteAuditValue[]) => void;
    addAuditReport: (report: AuditReprotValue) => void;
    deleteAuditReport: (auditId: number) => void;
}

export const SiteAuditContext = createContext<SiteAuditProps>({
    auditData: [],
    auditReports: [],
    addImages: () => {
        throw new Error('SiteAuditProvider not found');
    },
    addRating: () => {
        throw new Error('SiteAuditProvider not found');
    },
    addComment: () => {
        throw new Error('SiteAuditProvider not found');
    },
    clicked: () => {
        throw new Error('SiteAuditProvider not found');
    },
    setInitialTasks: () => {
        throw new Error('SiteAuditProvider not found');
    },
    addAuditReport: () => {
        throw new Error('SiteAuditProvider not found');
    },
    deleteAuditReport: () => {
        throw new Error('SiteAuditProvider not found');
    },

});

type SiteAuditContextProviderProps = {
    children: ReactNode;
};

export const SiteAuditProvider = ({ children }: SiteAuditContextProviderProps) => {
    const [auditData, setAuditData] = useState<SiteAuditValue[]>([]);
    const [auditReports, setAuditReports] = useState<AuditReprotValue[]>([]);
    const { state } = useAuthContext();
    const { user, business } = state;

    useEffect(() => {
        if (user) {
            fetchAuditReports();
        }
    }, [business, user]);

    const fetchAuditReports = async () => {
        try {
            const response = await apiClient.get(`/siteAudits/business/${business.businessId}`);
            if (user?.type === 'siteManager') {
                setAuditReports(response.data.filter((report: { siteId: any; }) => report.siteId === business?.siteId))
            } else if (user?.type === 'client') {
                setAuditReports(response.data.filter((report: { clientId: number; }) => report.clientId === user.userId))
            }else {
                setAuditReports(response.data);
            }
        } catch (error) {
            console.error('Error fetching Audit Reports:', error);
        }
    };

    const addAuditReport = async (report: AuditReprotValue) => {
        try {
            const response = await apiClient.post('/siteAudits', report);
            console.log(response.data);
            setAuditReports((prevAuditReports) => { return [...prevAuditReports, response.data]; });
            console.log(auditReports);


        } catch (error) {
            console.error('Error adding Audit Report:', error);
        }
    };

    const deleteAuditReport = async (auditId: number) => {
        try {
            await apiClient.delete(`/siteAudits/${auditId}`);
            setAuditReports((prevAuditReports) => { return prevAuditReports.filter((report) => { return report.auditId !== auditId; }); });
        } catch (error) {
            console.error('Error deleting Audit Report:', error);
        }
    };

    const addImages = (taskId: number, images: File[]) => {
        setAuditData((prevAuditData) => {
            const updatedData = prevAuditData.map((audit) => {
                if (audit.taskId === taskId) {
                    return { ...audit, images: audit.images ? [...audit.images, ...images] : [...images] };
                }
                return audit;
            });
            return updatedData;
        });
    };

    const addRating = (taskId: number, rating: string) => {
        setAuditData((prevAuditData) => {
            const updatedData = prevAuditData.map((audit) => {
                if (audit.taskId === taskId) {
                    return { ...audit, rating };
                }
                return audit;
            });
            return updatedData;
        });
    };

    const addComment = (taskId: number, comment: string) => {
        setAuditData((prevAuditData) => {
            const updatedData = prevAuditData.map((audit) => {
                if (audit.taskId === taskId) {
                    return { ...audit, comment };
                }
                return audit;
            });
            return updatedData;
        });
    };

    const clicked = (area: string) => {
        const updatedData = auditData.map((audit) => {
            if (audit.area === area) {
                return { ...audit, clicked: true };
            }
            return audit;
        });

        setAuditData(updatedData);
    };

    const setInitialTasks = (tasks: SiteAuditValue[]) => {
        setAuditData(tasks);
    };

    const contextValue = useMemo<SiteAuditProps>(() => {
        return {
            auditReports,
            auditData,
            addImages,
            addRating,
            addComment,
            clicked,
            setInitialTasks,
            addAuditReport,
            deleteAuditReport,
        };
    }, [auditData, auditReports]);

    return <SiteAuditContext.Provider value={contextValue}>{children}</SiteAuditContext.Provider>;
};
