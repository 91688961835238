/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { EyeOutlined } from '@ant-design/icons';
import { Button, Card, Col, ConfigProvider, Input, Modal, Row, Table, Typography } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { StaffContext } from '../../../../context';
import { formatDateAndTime } from '../../../../utils/timeUtils';
import { IStaff } from '../../../../utils/types';

const { Title, Text } = Typography;

const onChange: TableProps<IStaff>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
};

type staffPropTypes = {
    data: IStaff[],
};

const StaffTable = (props: staffPropTypes) => {
    const { data } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { staffs, setOneStaff, staff, deleteStaff, resetStaffPassword } = useContext(StaffContext);
    const [csvData, setCsvData] = useState([]);
    useEffect(() => {
        const temp: any = staffs.map((staff) => {
            console.log(staff);
            return {
                'First Name': staff?.firstName,
                'Last Name': staff?.lastName,
                'Phone Number': staff?.contactNo,
                'Email Address': staff?.email,
                'Signup Date': moment(staff?.user?.createdTime).format('DD-MM-YYYY'),

            };
        },
        );
        setCsvData(temp);
    }, [staffs]);
    const userModal = () => {
        return <Modal title="Staff Details" okText={ 'Close' } open={ isModalOpen }
            onOk={ () => { setIsModalOpen(false); } } onCancel={ () => { setIsModalOpen(false); } }
            cancelButtonProps={ { style: { display: 'none' } } }>
            <Row className='user-content-row'>
                <Col span={ 8 }>
                    <Title level={ 5 } className='content-title'>Staff Name:</Title>
                </Col>
                <Col span={ 16 }>
                    <Input value={ `${staff?.firstName} ${staff?.lastName}` } className='disabled-input' />
                </Col>
            </Row>
            <Row className='user-content-row'>
                <Col span={ 8 }>
                    <Title level={ 5 } className='content-title'>Email:</Title>
                </Col>
                <Col span={ 16 }>
                    <Input value={ `${staff?.email}` } className='disabled-input' />
                </Col>
            </Row>
            <Row className='user-content-row'>
                <Col span={ 8 }>
                    <Title level={ 5 } className='content-title'>Address:</Title>
                </Col>
                <Col span={ 16 }>
                    <Input value={ `${staff?.address}` } className='disabled-input' />
                </Col>
            </Row>
            <Row className='user-content-row'>
                <Col span={ 8 }>
                    <Title level={ 5 } className='content-title'>Type:</Title>
                </Col>
                <Col span={ 16 }>
                    <Input value={ `${staff?.type}` } className='disabled-input' />
                </Col>
            </Row>
            { staff?.type === 'siteManager' && <Row className='user-content-row'>
                <Col span={ 8 }>
                    <Title level={ 5 } className='content-title'>Client:</Title>
                </Col>
                <Col span={ 16 }>
                    <Input value={ `${staff?.clientName}` } className='disabled-input' />
                </Col>
            </Row> }
            { staff?.type === 'siteManager' && <Row className='user-content-row'>
                <Col span={ 8 }>
                    <Title level={ 5 } className='content-title'>Site:</Title>
                </Col>
                <Col span={ 16 }>
                    <Input value={ `${staff?.siteName}` } className='disabled-input' />
                </Col>
            </Row> }
            <Row className='user-content-row'>
                <Col span={ 8 }>
                    <Title level={ 5 } className='content-title'>SignedUp Time:</Title>
                </Col>
                <Col span={ 16 }>
                    <Input value={ formatDateAndTime(staff?.user?.createdTime) } className='disabled-input' />
                </Col>
            </Row>
            <Row className='user-content-row'>
                <Col span={ 8 }>
                    <Title level={ 5 } className='content-title'>Status:</Title>
                </Col>
                <Col span={ 16 }>
                    <Input value={ `Active` } className='disabled-input' />
                </Col>
            </Row>
        </Modal >;
    };

    const deactivateStaffModal = () => {
        Modal.confirm({
            title: 'Remove Staff',
            content: ('Are you sure you want to remove this staff?'
            ),
            okText: 'Remove',
            onOk: () => {
                deleteStaff(`${staff?.staffId}`);
            },
            okType: 'danger',
            onCancel: () => {
                // Handle cancel event if needed
            },
        });
    };
    const resetPasswordModal = () => {
        Modal.confirm({
            title: 'Reset Password',
            content: (
                ' Are you sure you want to reset this staffs password?'
            ),
            okText: 'Reset',
            onOk: () => {
                resetStaffPassword(`${staff?.staffId}`);
            },
            okType: 'danger',
            onCancel: () => {
                // Handle cancel event if needed
            },
        });
    };

    const columns: ColumnsType<IStaff> = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            defaultSortOrder: 'descend',
            width: '8%',
            // ...getColumnSearchProps('firstName'),
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            defaultSortOrder: 'descend',
            width: '8%',
            // ...getColumnSearchProps('lastName'),
        },
        {
            title: 'Phone Number',
            dataIndex: 'contactNo',
            defaultSortOrder: 'descend',
            width: '10%',
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            defaultSortOrder: 'descend',
            width: '12%',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: '6%',
            responsive: ['lg'],
            render: (type) => {
                return type === 'siteManager' ? `Site Manager` : 'Staff Member';
            },
        },
        {
            title: 'Action',
            dataIndex: 'staffId',
            key: 'x',
            width: '5%',
            responsive: ['lg'],
            render: (text, record) => {

                return (
                    <Button className='view-btn' onClick={ () => { handleViewClick(record?.staffId); } }>
                        <EyeOutlined />
                    </Button>
                );
            },
        },
        {
            title: '',
            dataIndex: 'staffId',
            key: 'x',
            width: '6%',
            responsive: ['lg'],
            render: (text, record) => {
                return (<Button className='view-btn-hover' onClick={ () => { handleRestPasswordClick(record?.staffId); } }>Reset Password</Button>);
            },
        },
        {
            title: '',
            dataIndex: 'staffId',
            key: 'x',
            width: '6%',
            responsive: ['lg'],
            render: (text, record) => {
                return <Button className='delete-btn-hover' onClick={ () => { handleDeactivateClick(record?.staffId); } }>Remove Staff</Button>;
            },
        },
    ];
    // console.log(staffs);
    const handleViewClick = (staffId: string) => {
        const staffData = staffs.find((staff) => { return staff?.staffId === staffId; });
        if (staffData) {
            setOneStaff(staffData);
            console.log(staff);
            setIsModalOpen(true);
        }
    };

    const handleRestPasswordClick = (staffId: string) => {
        const staffData = staffs.find((staff) => { return staff?.staffId === staffId; });
        if (staffData) {
            setOneStaff(staffData);
            resetPasswordModal();
        }

    };

    const handleDeactivateClick = (staffId: string) => {
        const staffData = staffs.find((staff) => { return staff?.staffId === staffId; });
        if (staffData) {
            setOneStaff(staffData);
            deactivateStaffModal();
        }
    };





    return (
        <div className='other-table'>
            <ConfigProvider theme={ { token: { colorPrimary: '#5a82f9' } } }>
                <Table columns={ columns } onChange={ onChange }
                    pagination={ { pageSize: 8 } } size={ 'small' } dataSource={ data } />

                <div className="table-card-list">
                    {
                        data.map((staff: IStaff) => {
                            return (
                                <Card key={ staff?.staffId } className="table-card" bordered={ true }>
                                    <Row className="table-card-row">
                                        <Col span={ 10 }>
                                            <Text className="input-label-text">First name</Text>
                                        </Col>
                                        <Col span={ 14 }>
                                            <Text>{ staff?.firstName }</Text>
                                        </Col>
                                    </Row>
                                    <Row className="table-card-row">
                                        <Col span={ 10 }>
                                            <Text className="input-label-text">Last name</Text>
                                        </Col>
                                        <Col span={ 14 }>
                                            <Text>{ staff?.lastName }</Text>
                                        </Col>
                                    </Row>
                                    <Row className="table-card-row">
                                        <Col span={ 10 }>
                                            <Text className="input-label-text">Email</Text>
                                        </Col>
                                        <Col span={ 14 }>
                                            <Text>{ staff?.email }</Text>
                                        </Col>
                                    </Row>
                                    <Row className="table-card-row">
                                        <Col span={ 10 }>
                                            <Text className="input-label-text">Address</Text>
                                        </Col>
                                        <Col span={ 14 }>
                                            <Text>{ staff?.address }</Text>
                                        </Col>
                                    </Row>
                                    {/* <Row className="table-card-row">
                                        <Col span={10}>
                                            <Text className="input-label-text">Client</Text>
                                        </Col>
                                        <Col span={14}>
                                            <Text>{staff.client?.name}</Text>
                                        </Col>
                                    </Row> */}
                                    {/* <Row className="table-card-row">
                                        <Col span={10}>
                                            <Text className="input-label-text">Site</Text>
                                        </Col>
                                        <Col span={14}>
                                            <Text>{staff.site?.siteName}</Text>
                                        </Col>
                                    </Row> */}
                                    <br></br>
                                    <div className="float-right">
                                        <Row gutter={ 16 }>
                                            <Col>
                                                <Button className='view-btn' onClick={ () => { return handleViewClick(staff?.staffId); } }><EyeOutlined /></Button>
                                            </Col>
                                            <Col>
                                                <Button className='view-btn-hover' onClick={ () => { return handleRestPasswordClick(staff?.staffId); } }>Reset Password</Button>
                                            </Col>
                                            <Col>
                                                <Button className='delete-btn-hover' onClick={ () => { return handleDeactivateClick(staff?.staffId); } }>Remove Staff</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            );
                        })
                    }
                </div>
                { userModal() }
                {/* <div className='table-card-list'>
                    { staffs.map(({ abn, first_name, last_name, email, contact_no, address }: any) => {
                        return (
                            <StaffCard key={abn} abn={abn} firstName={first_name} lastName={last_name} contactNo={contact_no} email={email} address={address} />
                        );
                    }) }
                </div> */}
                <Button className='export-btn'> <CSVLink data={ csvData } filename={ 'staff details.csv' }>Export To CSV</CSVLink>
                </Button>
            </ConfigProvider>
        </div>
    );
};

export default StaffTable;
