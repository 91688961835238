import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import apiClient from '../utils/apiClient';
import { IStaff } from '../utils/types';


interface StaffContextValue {
    staffs: IStaff[];
    staff: IStaff | null;
    addStaff: (staff: Omit<IStaff, 'staffId' | 'businessId' | 'site' | 'client' | 'user' | 'clientName' | 'siteName'>) => void;
    deleteStaff: (id: string) => void;
    resetStaffPassword: (id: string) => void;
    updateStaff: (id: string, updatedStaff: IStaff) => void;
    getOneStaff: (id: string) => void;
    setOneStaff: (staff: IStaff | null) => void;
}

export const StaffContext = createContext<StaffContextValue>({
    staffs: [],
    staff: null,
    addStaff: () => {
        throw new Error('StaffProvider not found');
    },
    deleteStaff: () => {
        throw new Error('StaffProvider not found');
    },
    resetStaffPassword: () => {
        throw new Error('StaffProvider not found');
    },
    updateStaff: () => {
        throw new Error('StaffProvider not found');
    },
    getOneStaff: () => {
        throw new Error('StaffProvider not found');
    },
    setOneStaff: () => {
        throw new Error('StaffProvider not found');
    },
});

type StaffContextProviderProps = {
    children: ReactNode;
};

export const StaffProvider = ({ children }: StaffContextProviderProps) => {
    const [staffs, setStaffs] = useState<IStaff[]>([]);
    const [staff, setStaff] = useState<IStaff | null>(null);
    const { state } = useAuthContext();
    const { user } = state;

    useEffect(() => {
        if (user && user?.type === 'manager') {
            fetchStaffs();
        }
    }, [user]);

    const fetchStaffs = async () => {
        try {
            const response = await apiClient.get('/staffs');
            console.log(response.data);
            setStaffs(response.data);
        } catch (error) {
            console.error('Error fetching Staff:', error);
        }
    };

    const addStaff = async (staff: Omit<IStaff, 'staffId' | 'businessId' | 'site' | 'client' | 'user' | 'clientName' | 'siteName'>) => {
        try {
            const response = await apiClient.post('/staffs', staff);
            console.log(response);
            if (response) {
                setStaffs((prevStaffs) => { return [...prevStaffs, response.data.user.staffmember]; });
            }
        } catch (error) {
            console.error('Error adding Staff:', error);
        }
    };

    const deleteStaff = async (id: string) => {
        try {
            await apiClient.delete(`/staffs/${id}`);
            setStaffs((prevStaffs) => { return prevStaffs.filter((staff) => { return staff.staffId !== id; }); });
            console.log('Success fully deleted');
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };
    const resetStaffPassword = async (id: string) => {
        try {
            await apiClient.delete(`/staffs/reset/${id}`);
            console.log('Success fully password reseted');
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    const updateStaff = async (id: string, updatedStaff: IStaff) => {
        try {
            const response = await apiClient.put(`/staffs/${id}`, updatedStaff);
            setStaffs((prevStaffs) => {
                return prevStaffs.map((staff) => { return (staff.staffId === id ? response.data : staff); });
            },
            );
        } catch (error) {
            console.error('Error updating Staff:', error);
        }
    };

    const getOneStaff = async (id: string) => {
        try {
            const response = await apiClient.get(`/staffs/${id}`);
            setStaff(response.data);
        } catch (error) {
            console.error('Error fetching task:', error);
        }
    };

    const setOneStaff = (staff: IStaff | null) => {
        setStaff(staff);
    };

    const contextValue = useMemo<StaffContextValue>(
        () => {
            return {
                staffs,
                staff,
                addStaff,
                deleteStaff,
                updateStaff,
                getOneStaff,
                setOneStaff,
                resetStaffPassword,
            };
        },
        [staffs, staff]);

    return (
        <StaffContext.Provider value={ contextValue }>
            { children }
        </StaffContext.Provider>
    );
};
