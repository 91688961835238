export const MENU_KEYS = {
    REPORTS: 'Reports',
    JOBS: 'Jobs',
    VIEWJOB: 'Job Details',
    ADD_JOB: 'Add Job',
    CLIENTS: 'Clients',
    ADD_CLIENT: 'Add Client',
    VIEWCLIENT: 'View Client',
    STAFF: 'Staff',
    ADD_STAFF: 'Add Staff',
    AUDITREPORTS: 'Site Auditing',
    TASK_LIST: 'Default Task List',
    ADD_TASK: 'Add Task',
    NOTIFICATIONS: 'Notifications',
    PROFILE: 'Profile',
    ANALYTICS: 'Analytics',
    CONTACT: 'Contact Form',
};


export { default as Analytics } from './Analytics/Analytics';
export { default as Clients } from './Clients/Clients';
export { default as AddClient } from './Clients/components/AddClient';
export { default as ClientView } from './Clients/components/ClientView';
export { default as Jobs } from './Jobs/Jobs';
export { default as AddJob } from './Jobs/components/AddJob';
export { default as JobsView } from './Jobs/components/JobsView';
export { default as Notifications } from './Others/Notifications';
export { default as Profile } from './Others/Profile';
export { default as Reports } from './Reports/Reports';
export { default as SiteJobs } from './SiteAuditReports/SiteJobs';
export { default as Staff } from './Staff/Staff';
export { default as AddStaff } from './Staff/components/AddStaff';
export { default as Task } from './Task/Task';
export { default as AddTask } from './Task/components/AddTask';
