/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { Card, Col, ConfigProvider, Row, Table, Typography } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { useContext } from 'react';
import { ClientContext } from '../../../../context';
import { IClient } from '../../../../utils/types';

const { Text } = Typography;

const onChange: TableProps<IClient>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
};

type clientTableProps = {
    data: IClient[],
    onViewClientClicked: () => void
}

const ClientsTable = (props: clientTableProps) => {

    const { data, onViewClientClicked } = props;
    const { setOneClient } = useContext(ClientContext);
    const columns: ColumnsType<IClient> = [
        // {
        //     title: 'ABN',
        //     dataIndex: 'abn',
        //     defaultSortOrder: 'descend',
        //     width: '7%',
        //     sorter: (a, b) => {
        //         const abnA = a.abn?.toString();
        //         const abnB = b.abn?.toString();
        //         return abnA?.localeCompare(abnB);
        //     },
        // },
        {
            title: 'Client Name',
            dataIndex: 'name',
            defaultSortOrder: 'descend',
            width: '15%',
            // ...getColumnSearchProps('name'),
        },
        {
            title: 'Contact Person',
            dataIndex: 'contactPerson',
            defaultSortOrder: 'descend',
            width: '15%',
            // ...getColumnSearchProps('clientPerson'),
        },
        {
            title: 'Contact No',
            dataIndex: 'contactNo',
            defaultSortOrder: 'descend',
            width: '10%',
            // responsive: ['lg']
        },
        {
            title: 'Email',
            dataIndex: 'email',
            defaultSortOrder: 'descend',
            width: '15%',
            responsive: ['lg'],
        },
        // {
        //     title: 'Action',
        //     dataIndex: '',
        //     defaultSortOrder: 'descend',
        //     width: '10%',
        //     render: (text, record) => {
        //         return <Button className='view-btn' onClick={ () => { return handleViewClick(record.clientId); } }>View More</Button>;
        //     },
        // },


    ];

    // const handleViewClick: any = (clientId: string) => {
    //     const clientData = data.find((client: IClient) => { return client.clientId === clientId; });
    //     if (clientData) {
    //         setOneClient(clientData);
    //         onViewClientClicked();
    //     }
    // };

    return (
        <div className='other-table'>
            <ConfigProvider theme={ { token: { colorPrimary: '#5a82f9' } } }>
                <Table columns={ columns } onChange={ onChange }
                    pagination={ { pageSize: 10 } } size={ 'small' } dataSource={ data }
                    onRow={ (record, rowIndex) => {
                        return {
                            onClick: (event) => {
                                setOneClient(record);
                                onViewClientClicked();
                            },
                        };
                    } }
                />
                <div className='table-card-list'>
                    { data.map((item: any) => {
                        return (
                            <Card key={ item.clientId } className="table-card" bordered={ true }
                                onClick={ () => {
                                    setOneClient(item);
                                    onViewClientClicked();
                                } }
                            >
                                <Row className='table-card-row'>
                                    <Col className="input-label-text" span={ 10 }>
                                        <Text>ABN:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item.abn }</Text>
                                    </Col>
                                </Row>
                                <Row className='table-card-row'>
                                    <Col className="input-label-text" span={ 10 }>
                                        <Text>Name:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item.name }</Text>
                                    </Col>
                                </Row>
                                <Row className='table-card-row'>
                                    <Col className="input-label-text" span={ 10 }>
                                        <Text>Contact Name:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item.contactPerson }</Text>
                                    </Col>
                                </Row>
                                <Row className='table-card-row'>
                                    <Col className="input-label-text" span={ 10 }>
                                        <Text>Contact No:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item.contactNo }</Text>
                                    </Col>
                                </Row>
                                <Row className='table-card-row'>
                                    <Col className="input-label-text" span={ 10 }>
                                        <Text>Email:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item.email }</Text>
                                    </Col>
                                </Row>
                                {/* <Row className='table-card-row'>
                                    <Col className="input-label-text" span={ 10 }>
                                        <Text>Client Site Name:</Text>
                                    </Col> */}
                                {/* <Col span={ 14 }>
                                        <Text>{ item.site_name }</Text>
                                    </Col> */}
                                {/* </Row> */ }
                                {/* <Row className='table-card-row'>
                                    <Col span={ 10 }>
                                        <Text>Client Site Address:</Text>
                                    </Col>
                                    <Col span={14}>
                                        <Text>{item.site_address}</Text>
                                    </Col>
                                </Row> */}

                                {/* <div className="float-right">
                                    <Button className='view-btn' onClick={() => { return handleViewClick(item.clientId); }}>View</Button>
                                </div> */}
                            </Card>
                        );
                    }) }
                </div>
                {/* <Button className='export-btn'>Export To Excel</Button> */ }
            </ConfigProvider>
        </div>);
};

export default ClientsTable;