/* eslint-disable @typescript-eslint/no-unused-vars */
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Select, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ClientContext, StaffContext } from '../../../../context';
import { ISite, IStaff } from '../../../../utils/types';
import Footer from '../../Others/Footer';

const { Option } = Select;
const { Title } = Typography;

interface StaffViewProps {
    onBreadClick: () => void,
    onStaffClick: () => void,
}

const AddStaff = (props: StaffViewProps) => {
    const { onBreadClick, onStaffClick } = props;
    const isMobile = useMediaQuery({ maxWidth: 576 });
    const { clients } = useContext(ClientContext);
    const [selectedClient, setSelectedClient] = useState<string | undefined>(undefined);
    const [selectedClientsSites, setSelectedClientsSites] = useState<ISite[] | undefined>(undefined);
    const { addStaff } = useContext(StaffContext);
    const [staffType, setStaffType] = useState('staffMember');

    const handleClientChange = (value: string) => {
        setSelectedClient(value);
    };

    useEffect(() => {
        console.log(selectedClient);
        const selectedClientData = selectedClient ? clients.find((client) => { return client.clientId === selectedClient; }) : undefined;
        console.log(selectedClientData);

        if (selectedClientData) {
            setSelectedClientsSites(selectedClientData.site);
        } else {
            setSelectedClientsSites([]);
        }
    }, [clients, selectedClient]);

    const handleOnSubmit = (values: any) => {
        const { firstName, lastName, contactNo, email, address, client, site, type } = values;

        const staffData: Omit<IStaff, 'staffId' | 'businessId' | 'site' | 'client' | 'user' | 'clientName' | 'siteName'> = {
            firstName,
            lastName,
            contactNo,
            email,
            address,
            type,
            clientId: client,
            siteId: site,
        };

        addStaff(staffData);
        onStaffClick();
    };

    const handleStaffTypeChnage = (value: string) => {
        setStaffType(value);
    }


    return (
        <Layout className='report-card'>
            <Row className='header-row'>
                <Col span={ 20 }>
                    <Title level={ 2 }>Add Staff</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={ onBreadClick }>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={ onStaffClick }>Staff</Breadcrumb.Item>
                        <Breadcrumb.Item className='bread-active'>Add Staff</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col span={ 24 }>
                    <Form
                        labelCol={ { span: 6 } }
                        wrapperCol={ { span: 17 } }
                        className='basic-form double-col-form'
                        layout={ isMobile ? 'vertical' : 'horizontal' }
                        onFinish={ handleOnSubmit }
                    >
                        <Row className='double-column-from-row'>
                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item
                                    label="First Name"
                                    name="firstName"
                                    rules={ [{ required: true, message: 'Please input the first name!' }] }
                                >
                                    <Input placeholder='Please enter first name here!' />
                                </Form.Item>
                            </Col>
                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item
                                    label="Last Name"
                                    name="lastName"
                                    rules={ [{ required: true, message: 'Please input the last name!' }] }
                                >
                                    <Input placeholder='Please enter last name here!' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='double-column-from-row'>
                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={ [{ required: true, message: 'Please input the email!' },
                                    { type: 'email', message: 'Please input a valid email!' }] }
                                >
                                    <Input placeholder='Please enter email here!' />
                                </Form.Item>
                            </Col>
                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item
                                    label="Contact No"
                                    name="contactNo"
                                    rules={ [{ required: true, message: 'Please input the contactNo!' }] }
                                >
                                    <Input placeholder='Please enter contactNo here!' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='double-column-from-row'>
                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item
                                    label="Address"
                                    name="address"
                                    rules={ [{ required: true, message: 'Please input the address!' }] }
                                >
                                    <Input placeholder='Please enter address here!' />
                                </Form.Item>
                            </Col>
                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item
                                    label="Staff Type"
                                    name="type"
                                    rules={ [{ required: true, message: 'Please select a staff type!' }] }
                                    initialValue='staffMember'
                                >
                                    <Select placeholder="Please select staff type" onChange={ handleStaffTypeChnage } >
                                        <Option value='staffMember'>
                                            Staff Member
                                        </Option>
                                        <Option value='siteManager'>
                                            Site Manager
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        { staffType === 'siteManager' && <Row className='double-column-from-row'>
                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item
                                    label="Client Name"
                                    name="client"
                                    rules={ [{ required: true, message: 'Please select a client!' }] }
                                >
                                    <Select placeholder="Please select a client" onChange={ handleClientChange }>
                                        { clients.map((client) => {
                                            return (
                                                <Option key={ client.clientId } value={ client.clientId }>
                                                    { client.name }
                                                </Option>
                                            );
                                        }) }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                <Form.Item
                                    label="Site Name"
                                    name="site"
                                    rules={ [{ required: true, message: 'Please select a site!' }] }
                                >
                                    <Select placeholder="Please select a site" disabled={ !selectedClient }>
                                        { (selectedClientsSites && selectedClient !== undefined && selectedClientsSites.length > 0) ?
                                            selectedClientsSites.map((site) => {
                                                return (
                                                    <Option key={ site.siteId } value={ site.siteId }>
                                                        { site.siteName }
                                                    </Option>
                                                );
                                            })
                                            : <Option key="empty" value="" disabled>
                                                No sites available
                                            </Option>
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row> }
                        <Row className='form-submit-row'>
                            <Button className='view-btn' htmlType="submit">Invite</Button>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Footer />
        </Layout>
    );
};

export default AddStaff;
