import { Button, Card, Col, ConfigProvider, Modal, Row, Table, Typography } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import React, { useContext, useState } from 'react';
import { TaskContext } from '../../../../context';
import { ITask } from '../../../../utils/types';

const { Text } = Typography;

const onChange: TableProps<ITask>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
};

type TaskTableProps = {
    data: ITask[];
    onTaskEditClick: () => void;
};

const TaskTable: React.FC<TaskTableProps> = (props: TaskTableProps) => {
    const { onTaskEditClick, data } = props;
    const { setOneTask, deleteTask, tasks } = useContext(TaskContext);
    // const [currentPage, setCurrentPage] = useState(1);
    // const itemsPerPage = 6;
    // const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const data = allData.slice(indexOfFirstItem, indexOfLastItem);
    const deleteTaskModal = (taskId: number) => {
        Modal.confirm({
            title: 'Remove Task',
            content: 'Are you sure you want to remove this task?',
            okText: 'Remove',
            onOk: () => {
                deleteTask(taskId);
            },
            okType: 'danger',
            onCancel: () => {
                // Handle cancel event if needed
            },
        });
    };

    const columns: ColumnsType<ITask> = [
        {
            title: 'Area',
            dataIndex: 'area',
            defaultSortOrder: 'descend',
            width: '10%',
        },
        {
            title: 'Task',
            dataIndex: 'task',
            defaultSortOrder: 'descend',
            width: '35%',
        },
        {
            title: 'Outcome',
            dataIndex: 'outcome',
            defaultSortOrder: 'descend',
            width: '25%',
        },
        {
            title: 'Client',
            dataIndex: 'client',
            defaultSortOrder: 'descend',
            width: '8%',
            render: (client) => {
                return client ? `${client.name}` : 'N/A';
            },
        },
        {
            title: 'Site Name',
            dataIndex: 'site',
            defaultSortOrder: 'descend',
            width: '8%',
            render: (site) => {
                return site ? `${site?.siteName}` : 'N/A';
            },
        },
        {
            title: 'Action',
            dataIndex: 'outcome',
            defaultSortOrder: 'descend',
            render: (_, task: ITask) => {
                return (
                    <Button
                        className="view-btn"
                        onClick={ () => {
                            handleEdit(task);
                        } }
                    >
                        Edit
                    </Button>
                );
            },
        },
        {
            title: '',
            dataIndex: 'outcome',
            defaultSortOrder: 'descend',
            render: (_, task: ITask) => {
                return (
                    <Button
                        className='delete-btn-hover'
                        onClick={ () => {
                            handleDelete(task.taskId);
                        } }
                    >
                        Delete
                    </Button>
                );
            },
        },
    ];

    const handleEdit = (task: ITask) => {
        setOneTask(task);
        onTaskEditClick();
    };
    const handleDelete = (taskId: number) => {
        const taskData = tasks.find((task) => {
            return task.taskId === taskId;
        });
        if (taskData) {
            deleteTaskModal(taskData.taskId);
        }
    };

    return (
        <div className="other-table">
            <ConfigProvider theme={ { token: { colorPrimary: '#5a82f9' } } }>
                <Table columns={ columns } onChange={ onChange } dataSource={ data }
                    // pagination={ {
                    //     current: currentPage,
                    //     pageSize: itemsPerPage,
                    //     total: allData.length,
                    //     onChange: (page) => setCurrentPage(page),
                    // } }
                    pagination={ {
                        defaultPageSize: 15, // Set the default page size to 15
                        pageSizeOptions: ['15', '30', '50'], // Optional: Specify additional page size options
                        showSizeChanger: true, // Optional: Allow users to change page size
                        // showQuickJumper: true, // Optional: Allow users to jump to a specific page
                        // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, // Optional: Display total number of items
                    } }
                    size={ 'small' } />
                <div className="table-card-list">
                    { data.slice(0, 15).map((item: ITask) => {
                        return (
                            <Card key={ item.taskId } className="table-card" bordered={ true }>
                                <Row className="table-card-row">
                                    <Col span={ 10 }>
                                        <Text>Area:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item.area }</Text>
                                    </Col>
                                </Row>
                                <Row className="table-card-row">
                                    <Col span={ 10 }>
                                        <Text>Task:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item.task }</Text>
                                    </Col>
                                </Row>
                                <Row className="table-card-row">
                                    <Col span={ 10 }>
                                        <Text>Outcome:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item.outcome }</Text>
                                    </Col>
                                </Row>
                                <Row className="table-card-row">
                                    <Col span={ 10 }>
                                        <Text>Outcome:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item.outcome }</Text>
                                    </Col>
                                </Row>
                                <Row className="table-card-row">
                                    <Col span={ 10 }>
                                        <Text>Client:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item?.client?.name }</Text>
                                    </Col>
                                </Row>
                                <Row className="table-card-row">
                                    <Col span={ 10 }>
                                        <Text>Site:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item?.site?.siteName }</Text>
                                    </Col>
                                </Row>
                                <Row className="table-card-row">
                                    <Col span={ 10 }>
                                        <Text>Action:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Button
                                            className="view-btn"
                                            onClick={ () => {
                                                handleEdit(item);
                                            } }
                                        >
                                            Edit
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        );
                    }) }
                </div>
            </ConfigProvider>
        </div>
    );
};

export default TaskTable;
