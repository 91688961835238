import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import apiClient from '../utils/apiClient';
import { IJob } from '../utils/types';


interface JobContextValue {
    jobs: IJob[];
    allJobs: IJob[];
    job: IJob | null;
    addJob: (job: any) => void;
    cancelOnlyJob: (jobId: number) => void;
    cancelRecurringJob: (jobId: number) => void;
    deleteJob: (id: number) => void;
    updateJob: (id: number, updatedJob: IJob) => void;
    getOneJob: (id: number) => void;
    setOneJob: (job: IJob | null) => void;
}

export const JobContext = createContext<JobContextValue>({
    jobs: [],
    allJobs: [],
    job: null,
    addJob: () => {
        throw new Error('JobProvider not found');
    },
    cancelOnlyJob: () => {
        throw new Error('JobProvider not found');
    },
    cancelRecurringJob: () => {
        throw new Error('JobProvider not found');
    },
    deleteJob: () => {
        throw new Error('JobProvider not found');
    },
    updateJob: () => {
        throw new Error('JobProvider not found');
    },
    getOneJob: () => {
        throw new Error('JobProvider not found');
    },
    setOneJob: () => {
        throw new Error('JobProvider not found');
    },
});

type JobContextProviderProps = {
    children: ReactNode;
};

export const JobProvider = ({ children }: JobContextProviderProps) => {
    const [jobs, setJobs] = useState<IJob[]>([]);
    const [job, setJob] = useState<IJob | null>(null);
    const [allJobs, setAllJobs] = useState<IJob[]>([]);
    const { state } = useAuthContext();

    const { user, business } = state;

    useEffect(() => {
        if (user) {
            fetchJobs();
        }
    }, [user]);


    const fetchJobs = async () => {
        try {

            if (user?.type === 'manager') {
                const jobs = await apiClient.get('/jobs');
                setJobs(jobs.data);
                const allJobsRes = await apiClient.get('/jobs/business/all');
                setAllJobs(allJobsRes.data);
            } else if (user?.type === 'client') {
                const jobs = await apiClient.get('/jobs/client');
                setJobs(jobs.data);
                const allJobsRes = await apiClient.get('/jobs/client/all');
                setAllJobs(allJobsRes.data);
            }
            else if (user?.type === 'siteManager') {
                const jobs = await apiClient.get(`/jobs/site/${business?.siteId}`);
                setJobs(jobs.data);
                const allJobsRes = await apiClient.get(`/jobs/site/all/${business?.siteId}`);
                setAllJobs(allJobsRes.data);
            }
        } catch (error) {
            console.error('Error fetching Job:', error);
        }
    };

    const addJob = async (job: any) => {
        console.log("Created Job", job);
        try {
            const response = await apiClient.post('/jobs', job);
            setJobs((prevJobs) => { return prevJobs.concat(response.data); });
        } catch (error) {
            console.error('Error adding Job:', error);
        }
    };
    const cancelOnlyJob = async (jobId: number) => {
        try {
            const response = await apiClient.put(`/jobs/${jobId}/cancelOne`);
            setJobs((prevJobs) => {
                return prevJobs.map((job) => { return (job.jobId === jobId ? response.data : job); });
            });
        } catch (error) {
            console.error('Error adding Job:', error);
        }
    };
    const cancelRecurringJob = async (jobId: number) => {
        try {
            const response = await apiClient.put(`/jobs/${jobId}/cancelRecuring`);
            const canceledJobs = response.data;
            setJobs((prevJobs) => {
                return prevJobs.map((job) => {
                    const canceledJob = canceledJobs.find((canceledJob: IJob) => { return canceledJob.jobId === job.jobId; });
                    if (canceledJob) {
                        return canceledJob; // Replace the job with the canceled job from the response
                    }
                    return job; // Keep the other jobs unchanged
                });
            });
        } catch (error) {
            console.error('Error adding Job:', error);
        }
    };

    const deleteJob = async (id: number) => {
        try {
            await apiClient.delete(`/jobs/${id}`);
            setJobs((prevJobs) => { return prevJobs.filter((job) => { return job.jobId !== id; }); });
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    const updateJob = async (id: number, updatedJob: IJob) => {
        try {
            const response = await apiClient.put(`/jobs/${id}`, updatedJob);
            setJobs((prevJobs) => {
                return prevJobs.map((job) => { return (job.jobId === id ? response.data : job); });
            },
            );
        } catch (error) {
            console.error('Error updating Job:', error);
        }
    };

    const getOneJob = async (id: number) => {
        try {
            const response = await apiClient.get(`/jobs/${id}`);
            setJob(response.data);
        } catch (error) {
            console.error('Error fetching task:', error);
        }
    };

    const setOneJob = (job: IJob | null) => {
        setJob(job);
    };

    const contextValue = useMemo<JobContextValue>(
        () => {
            return {
                jobs,
                allJobs,
                job,
                addJob,
                cancelOnlyJob,
                cancelRecurringJob,
                deleteJob,
                updateJob,
                getOneJob,
                setOneJob,
            };
        },
        [jobs, job, allJobs]);

    return (
        <JobContext.Provider value={ contextValue }>
            { children }
        </JobContext.Provider>
    );
};
