/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

interface DataRow {
    task: string;
    images: File[];
    comment: string;
    rating: string;
    area: string;
}
interface PDFDocumentProps {
    dataRows: DataRow[];
    client: any;
    site: any;
}
const companyLogo = 'https://smikappbucket.s3.ap-southeast-2.amazonaws.com/logo/Screenshot+2023-06-15+092559.png';

//related to pdf file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const PDFFile: React.FC<PDFDocumentProps> = ({ dataRows, client, site }) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based (0 = January)
    const currentDay = currentDate.getDate();

    const getRatingStyle=(rating:string)=>{
        if(rating==='Poor'){
            return styles.tableCellTextPoor;
        }
        else if(rating==='Fair'){
            return styles.tableCellTextFair;
        }
        else if(rating==='Excellent'){
            return styles.tableCellTextExcellent;
        }
        else{
            return styles.tableCellGood;
        }
    };

    return (
        <Document>
            <Page size="A4" style={ styles.page } >
                {/* start header section */ }
                <View style={ styles.header } fixed >
                    <View style={ styles.column }>
                        <Text style={ styles.text }>Client: { client.name }</Text>
                        <Text style={ styles.text }>Site: { site?.siteName } </Text>
                        <Text style={ styles.text }>Address: { site.siteAddress }</Text>
                        <Text style={ styles.text }>Email: { client.email }</Text>
                    </View>
                    <View style={ styles.logoContainer }>
                        <Image src={ companyLogo } style={ styles.logo } />
                    </View>
                    <View style={ styles.column }>
                        <Text style={ styles.text }>KC Facility Services</Text>
                        <Text style={ styles.text }>Phone: 1300 141 114</Text>
                        <Text style={ styles.text }>Address: 533-535 Nepean Hwy, Bonbeach, VIC 3196, Australia</Text>
                        <Text style={ styles.text }>Fax: (03) 8677 5419</Text>
                    </View>
                </View>
                {/* end header section */ }

                <View style={ styles.section }>
                    <View style={ styles.rowCardsContainer }>
                        <View style={ styles.cardLarge }>
                            <Text style={ [styles.title] }>Site Audit Report - { site?.siteName } |
                                { currentYear }-{ currentMonth }-{ currentDay }</Text>
                        </View>
                        <View>
                            { dataRows.map((dataRow: DataRow, index: number) => {

                                return (
                                    <View key={ index } style={ styles.table } >
                                        {/* Table Headers */ }
                                        <View wrap={ false }>
                                            <View style={ styles.tableRow }>
                                                <View style={ styles.tableHeader }>
                                                    <Text style={ styles.tableHeaderText }>Task Name</Text>
                                                </View>
                                                <View style={ styles.tableHeader }>
                                                    <Text style={ styles.tableHeaderText }>Rating</Text>
                                                </View>
                                                <View style={ styles.tableHeader }>
                                                    <Text style={ styles.tableHeaderText }>Comment</Text>
                                                </View>
                                            </View>
                                            {/* Table Rows */ }

                                            <View style={ styles.tableRow }>
                                                {/* Merged Cell */ }
                                                <View style={ [styles.tableCell, { flex: 3, backgroundColor: '#91c9ed' }] }>
                                                    <Text style={ [styles.tableCellText, { marginLeft: 5, textAlign: 'left' }] }>
                                                        { dataRow?.area }
                                                    </Text>
                                                </View>
                                            </View>

                                            <View style={ styles.tableRow }>

                                                <View style={ styles.tableCell }>
                                                    <Text style={ styles.tableCellText }>
                                                        { dataRow?.task }
                                                    </Text>
                                                </View>
                                                <View style={ [styles.tableCell ,getRatingStyle(dataRow.rating)]}>
                                                    <Text style={ [styles.tableCellText] }>{ dataRow?.rating || 'good' }</Text>
                                                </View>
                                                <View style={ styles.tableCell }>
                                                    <Text style={ styles.tableCellText }>{ dataRow?.comment }</Text>
                                                </View>
                                            </View>
                                        </View>

                                        <View style={ styles.imageContainer } >
                                            { dataRow?.images?.map((image: Blob
                                                | MediaSource, imageIndex: React.Key | null | undefined) => {
                                                return <Image key={ imageIndex } style={ styles.image }
                                                    src={ URL.createObjectURL(image) } 
                                                    //@ts-ignore
                                                    wrap={ false } />;
                                            }) }
                                        </View>

                                    </View>
                                );
                            }) }
                        </View>
                    </View>
                </View>


                <View style={ styles.header } wrap={ false }>
                    <View style={ styles.column }>
                        <Text style={ styles.text }>Completed by</Text>
                        <View style={ styles.card }>
                        </View>

                        <Text style={ styles.text }>Audit date/time</Text>
                        <View style={ styles.card }>
                        </View>

                        <Text style={ styles.text }>Notes</Text>
                        <View style={ styles.textCard }>
                        </View>
                    </View>

                    <View style={ styles.column }>
                        <Text style={ styles.text }>Signed by</Text>
                        <View style={ styles.card }>
                        </View>

                        <Text style={ styles.text }>Signed date/time</Text>
                        <View style={ styles.card }>
                        </View>

                        <Text style={ styles.text }>Notes</Text>
                        <View style={ styles.textCard }>
                        </View>
                    </View>
                </View>
                <Text style={ styles.pageNumber } render={ ({ pageNumber, totalPages }) => {
                    return (
                        'Scores:  POOR | FAIR | GOOD | EXCELLENT                         ' + `${pageNumber} / ${totalPages}`
                    );
                } } fixed />

            </Page>
        </Document>
    );
};

// pdf file styles
const styles = StyleSheet.create({
    tableCellTextPoor: {
        backgroundColor: 'red', // Red color for "Poor"
    },
    tableCellTextFair: {
        backgroundColor: 'yellow', // Orange color for "Fair"
    },
    tableCellGood:{
        backgroundColor:'green',
    },
    tableCellTextExcellent: {
        backgroundColor: '#1d1e4d', // Blue color for "info"
        color:'white',
    },
    label: {
        marginBottom: 5,
        fontWeight: 'bold',
    },
    value: {
        marginBottom: 15,
    },
    pageNumber: {
        height: 29,
        paddingTop: 35,
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
        marginTop: 10,
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    dataRow: {
        marginBottom: 10,
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    header: {
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize:15,
    },
    column: {
        flex: 1,
    },
    logoContainer: {
        flex: 1,
        alignItems: 'center',
    },
    logo: {
        width: 100,
        height: 100,
    },
    text: {
        fontSize: 12,
        marginBottom: 5,
    },
    title: {
        fontSize: 15,
        fontWeight: 'bold',
        paddingTop: 5,
        paddingBottom: 5,
        textAlign: 'center',
        backgroundColor: '#2c4f7d',
        color: 'white',
    },
    rowCardsContainer: {
        marginBottom: 20,
    },
    rowCard: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    card: {
        width: '90%', // Adjust as needed
        padding: 10,
        borderWidth: 1,
        borderColor: '#000000',
    },
    textCard: {
        width: '90%', // Adjust as needed
        padding: 30,
        borderWidth: 1,
        borderColor: '#000000',
    },

    cardLarge: {
        width: '100%', // Adjust as needed
        padding: 5,
        borderWidth: 1,
        borderColor: '#000000',
        backgroundColor: '#2c4f7d',
    },

    cardText: {
        fontSize: 12,
    },
    imageContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 20,
        borderWidth: 0,
    },
    image: {
        width: '20%', // Adjust as needed
        height: 100,
        margin: 5,
    },
    table: {
        flexDirection: 'column',
        marginTop: 20,
        borderWidth: 1,
        marginBottom: 40,
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableHeader: {
        flex: 1,
        padding: 10,
        borderWidth: 1,
        backgroundColor: '#CCCCCC',
    },
    tableHeaderText: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    tableCell: {
        borderWidth: 1,
        flex: 1,
        padding: 10,
    },
    tableCellText: {
        fontSize: 12,
        textAlign: 'left',
        marginLeft: 5,
    },

});

export default PDFFile;