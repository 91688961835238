/* eslint-disable max-len */
import { Card, Col, ConfigProvider, Divider, Row, Table, Typography } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { ITask } from '../../../../utils/types';

const { Text } = Typography;

const onChange: TableProps<ITask>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
};

type jobViewTaskTableProps = {
    tasks: ITask[]
}

const JobViewTaskTable = (props: jobViewTaskTableProps) => {

    const { tasks } = props;
    const columns: ColumnsType<ITask> = [

        {
            title: 'Area',
            dataIndex: 'area',
            defaultSortOrder: 'descend',
            width: '15%',
            // ...getColumnSearchProps('area'),
        },
        {
            title: 'Task',
            dataIndex: 'task',
            defaultSortOrder: 'descend',
            width: '25%',
            align: 'left'
        },
        {
            title: 'Outcome',
            dataIndex: 'outcome',
            defaultSortOrder: 'descend',
            width: '40%',
            align:'left'
        },
    ];

    return (
        <div className='other-table'>
            <ConfigProvider theme={ { token: { colorPrimary: '#5a82f9' } } }>
                <Divider orientation='left'>
                    Task List
                </Divider>
                <Table columns={ columns } onChange={ onChange } dataSource={ tasks }
                    pagination={ { pageSize: 4 } } size={ 'small' } />
                <div className='table-card-list'>
                    { tasks.map((item: ITask) => {
                        return (
                            <Card key={ item.taskId } className="table-card" bordered={ true }>
                                <Row className='table-card-row'>
                                    <Col span={ 10 }>
                                        <Text>Area:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item.area }</Text>
                                    </Col>
                                </Row>
                                <Row className='table-card-row'>
                                    <Col span={ 10 }>
                                        <Text>Task:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item.task }</Text>
                                    </Col>
                                </Row>
                                <Row className='table-card-row'>
                                    <Col span={ 10 }>
                                        <Text>Outcome:</Text>
                                    </Col>
                                    <Col span={ 14 }>
                                        <Text>{ item.outcome }</Text>
                                    </Col>
                                </Row>
                            </Card>
                        );
                    }) }
                </div>
            </ConfigProvider>
        </div>);
};

export default JobViewTaskTable;
