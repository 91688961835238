/* eslint-disable indent */
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Typography, message, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Images from '../../../assests/Images/Image';
import { passwordValidate, useLogin, userResetPassword, userVerifyEmail } from '../action';
import ForgetPassword from './ForgetPassword';
import ResendOtp from './ResendOtp';

const { Title, Text } = Typography;

const LOGIN_USER_STATUS = {
    LOGIN: 'Login',
    PASSWORD: 'Password Reset',
    NOT_CONFIRMED: 'Not Confirmed',
    RESET_PASSWROD: 'Reset Password',
};
const LoginForm = () => {
    const navigate = useNavigate();
    const { login } = useLogin();
    const [form, setForm] = useState(LOGIN_USER_STATUS.LOGIN);
    const [loading, setloading] = useState(false);
    const [error, setError] = useState('');
    const [forgetPasswordModal, setForgetPasswordModal] = useState(false);
    const [resendOtpModal, setResendOtpModal] = useState(false);
    const handleOnSubmit = async (values: any) => {
        const { email, password } = values;
        setloading(true);
        try {
            await login(email, password);
            setloading(false);
            navigate('/dashboard');
        } catch (error: any) {
            const errorMsg = error.response?.data?.message || error.message;
            console.log(errorMsg);
            if (errorMsg) {
                if (errorMsg === 'User not found') {
                    notification.error({
                        message: 'Error',
                        description: 'Username is incorrect.',
                    });
                } else if (errorMsg === 'Username or password is incorrect') {
                    notification.error({
                        message: 'Error',
                        description: 'Username or password is incorrect.',
                    });
                } else {
                    setError(errorMsg);
                }
            }
            setloading(false);
        }
    };

    const handleOnVerify = async (values: any) => {
        const { email, code } = values;
        setloading(true);
        try {
            await userVerifyEmail(email, code);
            setloading(false);
            notification.success({
                message: 'Success',
                description: 'You have Successfully verify your email.',
            });
            setForm(LOGIN_USER_STATUS.LOGIN);
        } catch (error: any) {
            setloading(false);
            console.log('Error on Verification', error);
            message.error('Invalid Verification Code');
            notification.error({
                message: 'Error',
                description: 'Invalid verification code.',
            });
        }
    };
    const handleOnForgetVerify = async (values: any) => {
        const { email, code } = values;
        setloading(true);
        try {
            await userVerifyEmail(email, code);
            setloading(false);
            notification.success({
                message: 'Success',
                description: 'You have Successfully verify your email.',
            });
            setForm(LOGIN_USER_STATUS.RESET_PASSWROD);
        } catch (error: any) {
            setloading(false);
            console.log('Error on Verification', error);
            message.error('Invalid Verification Code');
            notification.error({
                message: 'Error',
                description: 'Invalid verification code.',
            });
        }
    };

    const handlePasswordReset = async (values: any) => {
        const { password } = values;
        const email = localStorage.getItem('email');
        try {
            if (email) {
                await userResetPassword(email, password);
            }
            setloading(false);
            notification.success({
                message: 'Success',
                description: 'You have Successfully reseted you password.',
            });
            setForm(LOGIN_USER_STATUS.LOGIN);
        } catch (error: any) {
            setloading(false);
            notification.error({
                message: 'Error',
                description: 'Password not reseted successfully.',
            });
        }
    };

    useEffect(() => {
        if (error === 'Email is not verified') {
            setForm(LOGIN_USER_STATUS.NOT_CONFIRMED);
        } else if (error === 'Password should be reset' || error === 'Password is forget') {
            setForm(LOGIN_USER_STATUS.PASSWORD);
        } else if (error) {
            notification.error({
                message: 'Error',
                description: 'Username or password is incorrect.',
            });
        }
    }, [error]);



    const handleForgetPassword = () => {
        setForgetPasswordModal(true);
    };

    const handleResendOtpModal = () => {
        setResendOtpModal(true);
    };

    const loginUserForm = () => {
        return (<Col className='form-card'>
            <Row>
                <Col span={ 24 }>
                    <img src={ Images.KCWeb } className='login-logo' alt='logo'/>
                </Col>
            </Row>
            <Row>
                <Col span={ 24 }>
                    <Title level={ 2 }>Login</Title>
                    <Text type="secondary">Please login to continue to our dashboard</Text>
                </Col>
                { }
                <Col span={ 24 } className='form'>
                    <Form
                        initialValues={ { email: localStorage.getItem('email') } }
                        autoComplete="off"
                        layout="vertical"
                        requiredMark={ false }
                        className='form-container'
                        onFinish={ handleOnSubmit }
                    >
                        <Form.Item
                            name="email"
                            rules={ [{ type: 'email', required: true, message: 'Please input your email here!' }] }
                        >
                            <Input defaultValue={ 'test@gmail.com' } placeholder='Email Address'
                                size={ 'large' } prefix={ <MailOutlined /> } />
                        </Form.Item>
                        <Form.Item
                            className='login-password-field'
                            name="password"
                            rules={ [{
                                required: true,
                                message: 'Please input your password here!',
                            }, ({ getFieldValue }) => { return (passwordValidate(getFieldValue('password'))); }] }
                        >
                            <Input.Password placeholder='Password' 
                            type="password" size={ 'large' } prefix={ <LockOutlined /> } visibilityToggle={ true } />
                        </Form.Item>
                        {/* <Form.Item>
                            <Button type="text" className='forget-password'
                                onClick={ handleForgetPassword } >Forget Password?</Button>
                        </Form.Item> */}
                        <Row>
                            <Col span={ 24 } className='submit'>
                                <Button type="primary" htmlType="submit" className='submit-btn' loading={ loading }>
                                    Login
                                </Button>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col className='create-one'>
                                <p>Do not have an account?</p>
                                <Button type="text" onClick={ () => { navigate('/signup'); } }>Create One</Button>
                            </Col>
                        </Row> */}
                    </Form>
                </Col>
            </Row>
        </Col>);
    };

    const userNotConfirmed = () => {
        return (<Col className='form-card'>
            <Row>
                <Col span={ 24 }>
                    <img src={ Images.KCWeb } className='login-logo' alt='logo'/>
                </Col>
            </Row>
            <Row>
                <Col span={ 24 }>
                    <Title level={ 2 }>Verify Email Address</Title>
                    <Text type="secondary">Please enter the code recieved to the mail
                        for verify your mail address</Text>
                </Col>
                <Col span={ 24 } className='form'>
                    <Form
                        initialValues={ { email: localStorage.getItem('email') } }
                        autoComplete="off"
                        layout="vertical"
                        requiredMark={ false }
                        className='form-container'
                        onFinish={ handleOnVerify }
                    >
                        <Form.Item
                            name="email"
                            rules={ [{ type: 'email', required: true, message: 'Please input your email here!' }] }
                        >
                            <Input placeholder='Email Address' size={ 'large' } prefix={ <MailOutlined /> } />
                        </Form.Item>
                        <Form.Item
                            className='login-password-field'
                            name="code"
                            rules={ [{
                                required: true,
                                message: 'Please input your verify code here!',
                            },
                            ] }
                        >
                            <Input placeholder='Verify Code' size={ 'large' } prefix={ <LockOutlined /> } />
                        </Form.Item>
                        <Form.Item>
                            <Button type="text" className='forget-password' onClick={ handleResendOtpModal }>
                                Not Recieved the Code Resend
                            </Button>
                        </Form.Item>
                        <Row>
                            <Col span={ 24 } className='submit'>
                                <Button type="primary" htmlType="submit" className='submit-btn' loading={ loading }>
                                    Verify Email
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='create-one'>
                                <p>Do not have an account?</p>
                                <Button type="text" onClick={ () => { navigate('/signup'); } }>Create One</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Col>);
    };
    const passwordResetFormVerify = () => {
        return (<Col className='form-card'>
            <Row>
                <Col span={ 24 }>
                    <img src={ Images.KCWeb } className='login-logo' alt='logo' />
                </Col>
            </Row>
            <Row>
                <Col span={ 24 }>
                    <Title level={ 2 }>Verify Email Address</Title>
                    <Text type="secondary">Please enter the code recieved to the mail
                        for reset your password</Text>
                </Col>
                <Col span={ 24 } className='form'>
                    <Form
                        initialValues={ { email: localStorage.getItem('email') } }
                        autoComplete="off"
                        layout="vertical"
                        requiredMark={ false }
                        className='form-container'
                        onFinish={ handleOnForgetVerify }
                    >
                        <Form.Item
                            name="email"
                            rules={ [{ type: 'email', required: true, message: 'Please input your email here!' }] }
                        >
                            <Input placeholder='Email Address' size={ 'large' } prefix={ <MailOutlined /> } />
                        </Form.Item>
                        <Form.Item
                            className='login-password-field'
                            name="code"
                            rules={ [{
                                required: true,
                                message: 'Please input your verify code here!',
                            },
                            ] }
                        >
                            <Input placeholder='Verify Code' size={ 'large' } prefix={ <LockOutlined /> } />
                        </Form.Item>
                        <Form.Item>
                            <Button type="text" className='forget-password' onClick={ handleResendOtpModal } >Not Recieved the Code Resend</Button>
                        </Form.Item>
                        <Row>
                            <Col span={ 24 } className='submit'>
                                <Button type="primary" htmlType="submit" className='submit-btn'
                                    loading={ loading }>
                                    Verify Email
                                </Button>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col className='create-one'>
                                <p>Do not have an account?</p>
                                <Button type="text" onClick={ () => { navigate('/signup'); } }>Create One</Button>
                            </Col>
                        </Row> */}
                    </Form>
                </Col>
            </Row>
        </Col>);
    };
    const passwordResetForm = () => {
        return (<Col className='form-card'>
            <Row>
                <Col span={ 24 }>
                    <img src={ Images.KCWeb } className='login-logo' alt='logo'/>
                </Col>
            </Row>
            <Row>
                <Col span={ 24 }>
                    <Title level={ 2 }>Enter your new Password</Title>
                    <Text type="secondary">Please enter the new password</Text>
                </Col>
                <Col span={ 24 } className='form'>
                    <Form
                        autoComplete="off"
                        layout="vertical"
                        requiredMark={ false }
                        className='form-container'
                        onFinish={ handlePasswordReset }
                    >
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={ [
                                { required: true, message: 'Please enter your password' },
                                { min: 8, message: 'Password must be at least 8 characters' },
                            ] }
                        >
                            <Input.Password placeholder="Password" size="large" prefix={ <LockOutlined /> } />
                        </Form.Item>
                        <Form.Item
                            label="Confirm Password"
                            name="confirmPassword"
                            dependencies={ ['password'] }
                            rules={ [
                                { required: true, message: 'Please confirm your password' },
                                ({ getFieldValue }) => {
                                    return {
                                        validator (_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Passwords do not match'));
                                        },
                                    };
                                },
                            ] }
                        >
                            <Input.Password placeholder="Confirm Password" size="large" prefix={ <LockOutlined /> } />
                        </Form.Item>
                        <Row>
                            <Col span={ 24 } className='submit'>
                                <Button type="primary" htmlType="submit" className='submit-btn' loading={ loading }>
                                    Update Password
                                </Button>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col className='create-one'>
                                <p>Do not have an account?</p>
                                <Button type="text" onClick={ () => { navigate('/signup'); } }>Create One</Button>
                            </Col>
                        </Row> */}
                    </Form>
                </Col>
            </Row>
        </Col>);
    };


    const getBody = () => {
        switch (form) {
            case LOGIN_USER_STATUS.LOGIN:
                return loginUserForm();
            case LOGIN_USER_STATUS.NOT_CONFIRMED:
                return userNotConfirmed();
            case LOGIN_USER_STATUS.PASSWORD:
                return passwordResetFormVerify();
            case LOGIN_USER_STATUS.RESET_PASSWROD:
                return passwordResetForm();
            default:
                return loginUserForm();
        };
    };

    return (
        <Row>
            { getBody() }
            { forgetPasswordModal && <ForgetPassword onCancel={ () => {
                setForgetPasswordModal(false);
            } } onSuccess={ () => {
                setForgetPasswordModal(false);
                setForm(LOGIN_USER_STATUS.PASSWORD);
            } } /> }
            { resendOtpModal && <ResendOtp onCancel={ () => { return setResendOtpModal(false); } } /> }
        </Row>
    );
};

export default LoginForm;