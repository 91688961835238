/* eslint-disable @typescript-eslint/no-unused-vars */
import { PlusCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Input, Layout, Row, Typography } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { StaffContext } from '../../../context';
import { IStaff } from '../../../utils/types';
import Footer from '../Others/Footer';
import StaffTable from './components/StaffTable';

const { Title } = Typography;
const { Search } = Input;

type StaffViewProps = {
    onBreadClick: () => void,
    onAddStaffClick: () => void
}
const Staff = (props: StaffViewProps) => {
    const { onBreadClick, onAddStaffClick } = props;
    const { staffs } = useContext(StaffContext);
    const [searchResult, setSearchResult] = useState<IStaff[]>(staffs);
    const [searchValue, setSearchValue] = useState('');


    const onSearch = useCallback((value: string) => {
        setSearchValue(value);
        if (value === '') {
            setSearchResult(staffs);
            return;
        }
        const temp: IStaff[] = staffs.filter((item: IStaff) => {
            return (
                item?.firstName.toLowerCase().includes(value.toLowerCase()) ||
                item?.lastName.toLowerCase().includes(value.toLowerCase()) ||
                item?.contactNo.toLowerCase().includes(value.toLowerCase()) ||
                item?.email.toLowerCase().includes(value.toLowerCase())
            );
        });
        setSearchResult(temp);
    }, [staffs]);

    useEffect(() => {
        onSearch(searchValue);
    }, [onSearch, searchValue]);

    return (
        <Layout className='report-card'>
            <div className='header-row'>
                <div className='header-col'>
                    <div className="breadcrumb">
                        <Title level={ 2 }>Staff</Title>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={ onBreadClick }>Dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item className='bread-active'>Staff</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='btn-con btn-show-md'>
                        <Button className='view-btn' onClick={ onAddStaffClick }><PlusCircleOutlined size={ 24 } />Add Staff</Button>
                    </div>
                </div>
                <div className="header-col">
                    <Search
                        className="search-bar"
                        placeholder="Search by name"
                        onSearch={ onSearch }
                        enterButton
                        value={ searchValue }
                        onChange={ (e) => { return setSearchValue(e.target.value); } }
                    />
                    <div className='btn-con btn-show-lg'>
                        <Button className='view-btn' onClick={ onAddStaffClick }><PlusCircleOutlined size={ 24 } />Add Staff</Button>
                    </div>
                </div>
            </div>
            <Row>
                <StaffTable
                    data={ searchResult }
                />
            </Row>
            <Footer />

        </Layout>
    );
};

export default Staff;