/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Breadcrumb, Button, Col, ConfigProvider, DatePicker,
    Form,
    InputNumber,
    Layout,
    Modal,
    Row, Select, TimePicker, Typography, notification
} from 'antd';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { RRule } from 'rrule';
import { ClientContext, JobContext, StaffContext, TaskContext } from '../../../../context';
import { formatDate } from '../../../../utils/timeUtils';
import { ISite } from '../../../../utils/types';
import Footer from '../../Others/Footer';
import JobAddTaskTable from './JobTaskTable';

const { Title } = Typography;
const { Option } = Select;

type AddJobProps = {
    onBreadClick: () => void,
    onJobClick: () => void,
}

const AddJob = (props: AddJobProps) => {
    const [form] = Form.useForm();
    const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
    const { onBreadClick, onJobClick } = props;
    const isMobile = useMediaQuery({ maxWidth: 576 });
    const { clients } = useContext(ClientContext);
    const { staffs } = useContext(StaffContext);
    const [selectedClientsSites, setSelectedClientsSites] = useState<ISite[] | undefined>(undefined);
    const { addJob } = useContext(JobContext);
    const [selectedClient, setSelectedClient] = useState<string | undefined>(undefined);
    const [showEndDate, setShowEndDate] = useState(false);
    const [showDayPicker, setShowDayPicker] = useState(false);
    const [showCustomDateModal, setShowCustomDateModal] = useState(false);
    const { selectedJobTasks } = useContext(TaskContext);
    const [siteId, setSiteId] = useState<number | null>();
    const [intervals, setIntervals] = useState<number>(1);
    const [duration, setDuration] = useState<string>('RRule.MONTHLY');
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [customEndDate, setCustomEndDate] = useState();
    const [customeDates, setCustomDates] = useState<string[]>([]);
    const [customFrequency, setCustomFrequency] = useState('');
    const handleSiteChange = (value: number) => {
        setSiteId(value);
    };

    const handleClientChange = (value: string) => {
        setSelectedClient(value);
    };
    useEffect(() => {
        const selectedClientData = selectedClient ? clients.find((client) => { return client.clientId === selectedClient; }) : undefined;
        if (selectedClientData) {
            setSelectedClientsSites(selectedClientData.site);
        } else {
            setSelectedClientsSites([]);
        }
    }, [clients, selectedClient]);

    const handleFrequencyChange = (value: string) => {
        if (value === 'Once') {
            setShowEndDate(false);
        } else {
            setShowEndDate(true);
        }
        if (value === 'Custom') {
            console.log("Custom Select");
            setShowEndDate(false);
            setShowCustomDateModal(true);
        } else {
            setShowCustomDateModal(false);
        }
    };

    useEffect(() => {
        if (selectedStartDate) {
            console.log('The date you selected', selectedStartDate.format('YYYY-MM-DD'));
        }
    }, [selectedStartDate])


    const handleDaysChange = (value: string[]) => {
        setSelectedDays(value);
    };

    const disablePrevoiusDates = (current: any) => {
        const currentDate = moment().startOf('day');
        return current && current < currentDate;
    };

    const disableStartPrevoiusDates = (current: any) => {
        const currentDate = moment().startOf('day');
        const selectedStartDatePlusOne = selectedStartDate?.clone().add(1, 'day').startOf('day');
        return current && (current < currentDate || current < selectedStartDatePlusOne);
    };

    const handleRruleFreq = (duration: string) => {
        if (duration === 'RRule.DAILY') {
            return RRule.DAILY;
        } else if (duration === 'RRule.WEEKLY') {
            return RRule.WEEKLY;
        } else if (duration === 'RRule.MONTHLY') {
            return RRule.MONTHLY;
        }
    }


    const getRRuleDaysArray = (selectedDays: any[]) => {
        const rruleDays = selectedDays.map((dayNumber: any) => {
            // Map the selected day numbers to RRule days (RRule.MO, RRule.TU, etc.)
            const rruleDay = [
                RRule.SU, // Sunday
                RRule.MO, // Monday
                RRule.TU, // Tuesday
                RRule.WE, // Wednesday
                RRule.TH, // Thursday
                RRule.FR, // Friday
                RRule.SA, // Saturday
            ][dayNumber];
            return rruleDay;
        });
        console.log("RRule Days", rruleDays);
        return rruleDays;
    };

    const handleOnSubmit = async (values: any) => {
        const { date, startTime, endTime, client, siteId, staff, frequency, endDate, days } = values;
        // const inputDate = moment(selectedStartDate);
        // console.log("Input date", date?.toISOString().slice(0, 10));
        if (frequency === 'Custom') {
            const inputDate = moment(date?.toISOString().slice(0, 10));
            console.log('Input Dates:', inputDate.format('YYYY-MM-DD'));
            customeDates.map((customDate: string) => {
                //     // Format the dates
                const formattedStartTime = startTime?.toISOString();
                const formattedEndTime = endTime?.toISOString();
                const startDateTime = customDate + 'T' + moment(formattedStartTime).format('HH:mm:ss') + '.00Z';
                const endDateTime = customDate + 'T' + moment(formattedEndTime).format('HH:mm:ss') + '.00Z';
                const jobData: any = {
                    date: customDate,
                    startTime: startDateTime,
                    endTime: endDateTime,
                    clientId: client,
                    site: selectedClientsSites?.find((site) => { return site.siteId === siteId; })?.siteId,
                    staff: staff,
                    frequency: customFrequency,
                    tasks: selectedJobTasks,
                };
                addJob(jobData);
            })
            notification.success({
                message: 'Success',
                description: 'You have successfully added the Job.',
            });
            onJobClick();
        }
        else {
            const formattedDate = date.format('YYYY-MM-DD');
            console.log("The Date you selected", formattedDate);
            const formattedEndDate = endDate?.toISOString().slice(0, 10);
            const formattedStartTime = startTime?.toISOString();
            const formattedEndTime = endTime?.toISOString();
            const startDateTime = formattedDate + 'T' + moment(formattedStartTime).format('HH:mm:ss') + '.00Z';
            const endDateTime = formattedDate + 'T' + moment(formattedEndTime).format('HH:mm:ss') + '.00Z';
            const jobData: any = {
                date: formattedDate,
                endDate: formattedEndDate,
                startTime: startDateTime,
                endTime: endDateTime,
                clientId: client,
                site: selectedClientsSites?.find((site) => { return site.siteId === siteId; })?.siteId,
                staff: staff,
                frequency,
                tasks: selectedJobTasks,
            };
            addJob(jobData);
            notification.success({
                message: 'Success',
                description: 'You have successfully added the Job.',
            });
            onJobClick();
        }
    };

    useEffect(() => {
        if (selectedStartDate) { console.log("Selected Start Date", selectedStartDate.toDate()) }
        console.log("No of intervals", intervals);
        console.log("Custom End Date", customEndDate);
        console.log("Duration", duration);
    }, [intervals, customEndDate, duration, selectedStartDate])


    const handleEndDateChnage = (value: any) => {
        if (value) {
            setCustomEndDate(value.toDate());
        }
    }

    const handleCustomDates = () => {
        if (duration === 'RRule.MONTHLY') {
            console.log("Selected Start Date", selectedStartDate.toDate())
            const rrule = new RRule({
                freq: handleRruleFreq(duration),
                dtstart: selectedStartDate.toDate(),
                until: customEndDate,
                interval: intervals,
            });
            console.log("Get Monthly Text", rrule.toText())
            console.log("Get Monthly Dates", rrule.all().map(formatDate));
            const dateList = rrule.all().map(formatDate)
            setCustomDates(dateList);
            if (intervals === 3) {
                setCustomFrequency('Quarterly');
            } else if (intervals === 6) {
                setCustomFrequency('Half Yearly');
            } else if (intervals === 12) {
                setCustomFrequency('Annually');
            } else {
                setCustomFrequency('Custom');
            }
            setShowCustomDateModal(false);
        } else if (duration === 'RRule.WEEKLY') {
            console.log("Selected Start Date", selectedStartDate.toDate())
            const rrule = new RRule({
                freq: handleRruleFreq(duration),
                byweekday: getRRuleDaysArray(selectedDays),
                dtstart: selectedStartDate.toDate(),
                until: customEndDate,
                interval: intervals,
            });
            console.log("Get Weekly Text", rrule.toText())
            console.log("Get Weekly Dates", rrule.all().map(formatDate));
            const dateList = rrule.all().map(formatDate)
            setCustomDates(dateList);
            if (intervals === 2) {
                setCustomFrequency('Fortnightly');
            } else {
                setCustomFrequency('Custom');
            }
            setShowCustomDateModal(false);
        }
    }


    const hanldeCustomDateModal = () => {
        return (
            <Modal
                title="Custom Date"
                open={ showCustomDateModal }
                okText="Submit"
                onOk={ () => handleCustomDates() }
                onCancel={ () => {
                    setShowCustomDateModal(false);
                } }
                centered={ true }
            >
                <Form className="modal-form" >
                    <Row justify='space-between'>
                        <Col lg={ 4 } md={ 24 } >
                            <Row justify='space-between'>
                                <Form.Item label="Repeat Every:" >
                                    <InputNumber min={ 1 } max={ 12 } defaultValue={ 1 } onChange={ (value) => { value && setIntervals(value) } } />
                                </Form.Item>
                            </Row>
                        </Col>
                        <Col lg={ 12 } md={ 24 }>
                            <Form.Item label="Frequency">
                                <Select defaultValue={ 'RRule.MONTHLY' } style={ { width: '100%' } } onChange={ (value) => {
                                    value && setDuration(value)
                                } }
                                    options={ [
                                        { value: 'RRule.WEEKLY', label: 'Weekly' },
                                        { value: 'RRule.MONTHLY', label: 'Month(s)' }] }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    { duration && duration === 'RRule.WEEKLY' && <Row>
                        <Col span={ 24 }>
                            <Form.Item
                                label="Custom Days"
                                name="days"
                                rules={ [{ required: true, message: 'Please select the days!' }] }
                            >
                                <Select
                                    placeholder="Please select the days"
                                    mode="multiple"
                                    style={ { width: '100%' } }
                                    onChange={ handleDaysChange }
                                    options={ [
                                        { value: '0', label: 'Sunday' },
                                        { value: '1', label: 'Monday' },
                                        { value: '2', label: 'Tuesday' },
                                        { value: '3', label: 'Wednesday' },
                                        { value: '4', label: 'Thursday' },
                                        { value: '5', label: 'Friday' },
                                        { value: '6', label: 'Saturday' },
                                    ] }
                                />
                            </Form.Item>
                        </Col>
                    </Row> }
                    <Row>
                        <Col span={ 24 }>
                            <Form.Item
                                label=" End Date"
                                name="endDate"
                                rules={ [
                                    {
                                        required: true,
                                        message: 'Please select the end date!',
                                    },
                                ] }
                            >
                                <DatePicker
                                    style={ { minWidth: '100%' } }
                                    format="DD-MM-YYYY"
                                    placeholder="Please select the job end date"
                                    onChange={ handleEndDateChnage }
                                    disabledDate={ disableStartPrevoiusDates }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }

    return (
        <Layout className='report-card'>
            <Row className='header-row'>
                <Col span={ 20 }>
                    <Title level={ 2 }>Add Job</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={ onBreadClick }>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={ onJobClick }>Jobs</Breadcrumb.Item>
                        <Breadcrumb.Item className='bread-active'>Add Job</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col span={ 24 }>
                    <ConfigProvider theme={ { token: { colorPrimary: '#5a82f9' } } }>
                        <Form
                            form={ form }
                            labelCol={ { span: 7 } }
                            wrapperCol={ { span: 16 } }
                            className='basic-form double-col-form'
                            layout={ isMobile ? 'vertical' : 'horizontal' }
                            onFinish={ handleOnSubmit }
                        >
                            <Row className='double-column-from-row'>
                                <Col lg={ 8 } md={ 12 } sm={ 24 } xs={ 24 }>
                                    <Form.Item label="Date" name="date"
                                        rules={ [{ required: true, message: 'Please enter the date!' }] }
                                    >
                                        <DatePicker style={ { minWidth: '100%' } } format="DD-MM-YYYY"
                                            onChange={ (date) => { return setSelectedStartDate(date); } }
                                            disabledDate={ disablePrevoiusDates }
                                            placeholder='Please select the job date' />
                                    </Form.Item>
                                </Col>
                                <Col lg={ 8 } md={ 12 } sm={ 24 } xs={ 24 }>
                                    <Form.Item label="Start Time" name="startTime"
                                        rules={ [{ required: true, message: 'Please input the start time!' }] }
                                    >
                                        <TimePicker style={ { minWidth: '100%' } }
                                            // onChange={handleStartTimeChange}
                                            placeholder='Please input the job start time'
                                            use12Hours minuteStep={ 15 } format="h:mm a"
                                            showNow={ false }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={ 8 } md={ 12 } sm={ 24 } xs={ 24 }>
                                    <Form.Item label="End Time" name="endTime"
                                        rules={ [{ required: true, message: 'Please input the end time!' }] }
                                    >
                                        <TimePicker style={ { minWidth: '100%' } }
                                            placeholder='Please input the job end time'
                                            use12Hours minuteStep={ 15 } format="h:mm a" showNow={ false }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='double-column-from-row'>
                                <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                    <Form.Item label="Client Name" name="client"
                                        rules={ [{ required: true, message: 'Please select the client!' }] }
                                    >
                                        <Select placeholder='Please select the client'
                                            onChange={ handleClientChange }
                                            style={ { width: '100%' } }>
                                            { clients.sort((a, b) => `${a?.name}`.localeCompare(`${b?.name}`))
                                                .map((client) => {
                                                    return (
                                                        <Option key={ client.clientId } value={ client.clientId }>
                                                            { client.name }
                                                        </Option>
                                                    );
                                                }) }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                    <Form.Item label="Site Name" name="siteId"
                                        rules={ [{ required: true, message: 'Please select the site!' }] }

                                    >
                                        <Select placeholder='Please select the site'
                                            style={ { width: '100%' } }
                                            disabled={ !selectedClient }
                                            onChange={ handleSiteChange }>
                                            { (selectedClientsSites && selectedClient !== undefined && selectedClientsSites.length > 0) ?
                                                selectedClientsSites.sort((a, b) => `${a?.siteName}`.localeCompare(`${b?.siteName}`)).map((site) => {
                                                    return (
                                                        <Option key={ site.siteId } value={ site.siteId }>
                                                            { site.siteName }
                                                        </Option>
                                                    );
                                                })
                                                : <Option key="empty" value="" disabled>
                                                    No sites available
                                                </Option>
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='double-column-from-row'>
                                <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                    <Form.Item label="Assign Staff" name="staff"
                                        rules={ [{ required: true, message: 'Please select the staff!' }] }
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder='Please select the staff'
                                            style={ { width: '100%' } }

                                        >
                                            { staffs.sort((a, b) => `${a?.firstName} ${a?.lastName}`.localeCompare(`${b?.firstName} ${b?.lastName}`)).map((staff) => {
                                                return (
                                                    <Option key={ staff.staffId } value={ staff.staffId }>
                                                        { staff?.firstName } { staff?.lastName }
                                                    </Option>
                                                );
                                            }) }

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                    <Form.Item label="Frequency" name="frequency"
                                        rules={ [{ required: true, message: 'Please input the job frequency!' }] }
                                        initialValue='Once'
                                    >
                                        <Select
                                            placeholder='Please select the job frequency'
                                            style={ { width: '100%' } }
                                            onChange={ handleFrequencyChange }
                                            options={ [
                                                { value: 'Once', label: 'Once' },
                                                { value: 'Daily', label: 'Daily' },
                                                { value: 'Weekly', label: 'Weekly' },
                                                { value: 'Monthly', label: 'Monthly' },
                                                { value: 'Custom', label: 'Custom' },
                                            ] }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            { showEndDate && <Row className="double-column-from-row">
                                { showDayPicker &&
                                    <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                        <Form.Item label="Custom Days" name="days"
                                            rules={ [{ required: true, message: 'Please select the days!' }] }
                                        >
                                            <Select
                                                placeholder='Please select the days'
                                                mode="multiple"
                                                style={ { width: '100%' } }
                                                onChange={ handleDaysChange }
                                                options={ [
                                                    { value: '0', label: 'Sunday' },
                                                    { value: '1', label: 'Monday' },
                                                    { value: '2', label: 'Tuesday' },
                                                    { value: '3', label: 'Wednesday' },
                                                    { value: '4', label: 'Thursday' },
                                                    { value: '5', label: 'Friday' },
                                                    { value: '6', label: 'Saturday' },
                                                ] }
                                            />
                                        </Form.Item>
                                    </Col>
                                }
                                <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                    <Form.Item
                                        label="End Date"
                                        name="endDate"
                                        rules={ [
                                            {
                                                required: showEndDate,
                                                message: 'Please select the end date!',
                                            },
                                        ] }
                                    >
                                        <DatePicker
                                            style={ { minWidth: '100%' } }
                                            format="DD-MM-YYYY"
                                            placeholder="Please select the job end date"
                                            disabled={ !showEndDate }
                                            disabledDate={ disableStartPrevoiusDates }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row> }
                            <JobAddTaskTable siteId={ siteId } />
                            <Row className='form-submit-row'>
                                <Button className='view-btn' htmlType="submit"> Submit </Button>
                            </Row>
                        </Form>
                        { showCustomDateModal && hanldeCustomDateModal() }
                    </ConfigProvider>
                </Col>
            </Row >
            <Footer />
        </Layout>
    );
};

export default AddJob;