import avatar from './avatar.png';
import banner from './banner.jpg';
import banner2 from './banner2.jpg';
import logoOld from './logo.png';
import logo from './logo3.png';
import KCWeb from './KCF.webp';
import noimage from './noimage.jpg';
export default {
    banner,
    logo,
    banner2,
    avatar,
    KCWeb,
    logoOld,
    noimage
}; 
