import AWS from 'aws-sdk';
import { useState } from 'react';

AWS.config.update({
    accessKeyId: 'AKIAZIGW4DC2PXRSU57J',
    secretAccessKey: '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',
    region: 'ap-southeast-2',
    signatureVersion: 'v4',
});
const s3 = new AWS.S3();
export const uploadToS3 = async (file: File) => {
    const selectedFile = file as File;
    const fileName = selectedFile.name;
    const params = {
        Bucket: 'smikappbucket',
        Key: `logo/${Date.now()}.${fileName}`,
        Body: file,
    };
    try {
        const { Location } = await s3.upload(params).promise();
        console.log('Uploaded to S3:', Location);
        return Location;
    } catch (error) {
        console.error('Error uploading to S3:', error);
        throw error;
    }
};

export default function ImageUploader () {

    const [imageUrl, setImageUrl] = useState<null | string>(null);
    const [file, setFile] = useState(null);

    const handleFileSelect = (e: any) => {
        setFile(e.target.files[0]);
    };
    const handleUpload = async () => {
        if (!file) {
            return;
        }

        try {
            const uploadedImageUrl = await uploadToS3(file);
            setImageUrl(uploadedImageUrl);
        } catch (error) {
            // Handle error
        }
    };

    return (
        <div style={ { marginTop: '150px' } }>
            <h1>Test Image Upload</h1>
            <input type="file" onChange={ handleFileSelect } />
            { file && (
                <div style={ { marginTop: '10px' } }>
                    <button onClick={ handleUpload }>Upload</button>
                </div>
            ) }
            { imageUrl && (
                <div style={ { marginTop: '10px' } }>
                    <img src={ imageUrl } alt="uploaded" />
                </div>
            ) }
        </div>
    );
}