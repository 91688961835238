/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Pie } from '@ant-design/plots';

const PieChart = () => {
    const data = [
        {
            type: 'Springvale',
            value: 27,
        },
        {
            type: 'Wooles',
            value: 25,
        },
        {
            type: 'Cooles',
            value: 18,
        },
        {
            type: 'Site',
            value: 15,
        },
        {
            type: 'Springvale 2',
            value: 47,
        },
        {
            type: 'Wooles 2',
            value: 45,
        },
        {
            type: 'Cooles 2',
            value: 38,
        },
        {
            type: 'Site 2',
            value: 35,
        },
    ];

    // @ts-expect-error
    const config = { label: { type: 'inner', content: ({ percent }) => {return `${(percent * 100).toFixed(0)}%`;},
        offset: '-30%',
        style: {
            fontSize: 14,
            textAlign: 'center',
        },
    },
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    // color:['#ff6348','#ffa502','#2ed573','#3742fa'],
    radius: 0.9,
    interactions: [
        {
            type: 'element-active',
        },
    ],
    };

    // @ts-expect-error
    return (<div className='chart'><Pie {...config} /></div>);
};

export default PieChart;
