import { Button, Col, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import Images from '../../../assests/Images/Image';

const { Title, Text } = Typography;

export default function Success () {
    const navigate = useNavigate();
    return (
        <Row>
            <Col className='form-card'>
                <Row>
                    <Col span={ 24 }>
                        <img src={ Images.logo } alt='logo'/>
                    </Col>
                </Row>
                <Row>
                    <Col span={ 24 }>
                        <Title level={ 2 }>Successfully Registered</Title>
                        <Row justify='center'>
                            <Col span={ 18 }>
                                <Text type="secondary">Please enter the code recieved to the mail
                                    for verify your mail address</Text></Col>
                        </Row>
                        <Row style={ { marginTop: '2em' } }>
                            <Col span={ 24 } className='submit'>
                                <Button htmlType="submit" className='submit-btn' onClick={ () => { return navigate('/'); } }>
                                    Back To Home Page
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
