/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Col, Divider, Form, Image, Input, Layout, Row, Spin, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Images from '../../../assests/Images/Image';
import { useAuthContext } from '../../../hooks/useAuthContext';
import Footer from './Footer';

import images from '../../../assests/Images/Image';

import { uploadImageToS3 } from '../../../utils/s3Utils';
import { managerUpdateData, userResetPassword } from './action';
const { Title } = Typography;

const Profile = () => {
    const isMobile = useMediaQuery({ maxWidth: 576 });
    const [updateForm, setUpdateForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const { state, dispatch } = useAuthContext();
    const { user, business } = state;

    const [profilePictureFile, setProfilePictureFile] = useState();

    useEffect(() => {
        setProfilePictureFile(business.business.logo);
        console.log('logo', business.business.logo);

        setLoading(true);
        if(user.type = 'manager'){
            form.setFieldsValue({
                businessName: business.business.name,
                abn: business.business.abn,
                contactNo: user.manager?.contactNo,
                email: user.email,
                firstName: user.manager?.firstName,
                lastName: user.manager?.lastName,
                logo: business.logo,
            });
        }
        setLoading(false);
    }, [form, user, business]);

    const handleImageChange = async (info: any) => {
        console.log(info);
        try {
            // form.setFieldsValue({ profilePictureFile: info.file });
            setProfilePictureFile(info.file);

        } catch (error) {
            console.error(error);
        }
    };

    const handlePasswordUpdate = async (values: any) => {
        const { password } = values;
        const email = user.email;
        try {
            if (email) {
                await userResetPassword(email, password);
            }
            setLoading(false);
            notification.success({
                message: 'Success',
                description: 'You have Successfully updated your password.',
            });
        } catch (error: any) {
            setLoading(false);
            notification.error({
                message: 'Error',
                description: 'Password not reseted successfully.',
            });
        }
    };

    const handleManagerDateUpdate = async (values: any) => {
        const userId = user.userId;
        const userDataString = localStorage.getItem('userData');
        try {

            if (profilePictureFile) {
                const url = await uploadImageToS3(profilePictureFile, 'businessProfilePictures');
                // values.logo = url;
                console.log(url);
                values.logo = url;
            }
            const response = await managerUpdateData(values, userId);
            if (response && userDataString) {
                const userData = JSON.parse(userDataString);
                userData.user.manager.firstName = values.firstName;
                userData.user.manager.lastName = values.lastName;
                userData.user.manager.contactNo = values.contactNo;
                userData.business.business.abn = values.abn;
                userData.business.business.name = values.businessName;
                localStorage.setItem('userData', JSON.stringify(userData));
                dispatch({ type: 'LOGIN', payload: userData });
                setUpdateForm(false);
            }
            notification.success({
                message: 'Success',
                description: 'You have Successfully updated your profile.',
            });
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Unable to update your profile.',
            });
        }
    };

    return (
        <Layout className="report-card">
            <Row>
                <Col>
                    <Title level={2}>Business Profile</Title>
                </Col>
            </Row>
            <Row className="profile">
                <Col span={24} className="profile-form-height-col">
                    <Row className="profile-form">
                        {loading ? (
                            <Spin />
                        ) : (
                            <Form
                                form={ form }
                                labelCol={ { span: 8 } }
                                wrapperCol={ { span: 12 } }
                                requiredMark={ true }
                                className="basic-form double-col-form"
                                layout={isMobile ? 'vertical' : 'horizontal'}
                                style={{ height: 'fit-content' }}
                                onFinish={handleManagerDateUpdate}
                            >
                                <Row gutter={ 16 } style={ { flexWrap: 'wrap', alignItems: 'center' } }>
                                    <Col lg={ 4 } sm={ 24 } xs={ 24 } className="logo-container">
                                        <div className="business-logo">
                                            {/* <Image
                                                // crossOrigin="anonymous" 
                                                src={profilePictureFile}
                                                // onError={(e) => {
                                                //     e.currentTarget.src = Image.KCweb;
                                                // }}
                                            /> */}
                                            <img src={images.KCWeb} style={{ width: '100%', height: '100%', objectFit: 'fill' }} alt="client" />

                                        </div>
                                       
                                    </Col>
                                    <Col lg={ 20 } sm={ 24 } xs={ 24 } style={ { paddingTop: '2em' } }>
                                        <Row className="double-column-from-row">
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    label="Business Name"
                                                    name="businessName"
                                                    rules={[{ required: true, message: 'Please input the Business Name!' }]}
                                                >
                                                    <Input placeholder="Please enter the business name!" disabled={!updateForm} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    label="ABN"
                                                    name="abn"
                                                    rules={[{ required: false, message: 'Please input the abn!' }]}
                                                >
                                                    <Input placeholder="Please enter abn here!" disabled={!updateForm} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="double-column-from-row">
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    label="Contact No"
                                                    name="contactNo"
                                                    rules={[{ required: true, message: 'Please input the Contact No!' }]}
                                                >
                                                    <Input placeholder="Please enter the contact no!" disabled={!updateForm} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    label="Email"
                                                    name="email"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input the email!',
                                                        },
                                                        { type: 'email', message: 'Please input a ' },
                                                    ]}
                                                >
                                                    <Input placeholder="Please enter the email!" disabled />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={24} style={{ marginTop: '.5em' }}>
                                        <Row className="double-column-from-row" style={{ flexWrap: 'wrap' }}>
                                            <Col span={24}>
                                                <Divider orientation="left" orientationMargin={0} style={{ fontSize: '22px' }}>
                                                    Owner Personal Details
                                                </Divider>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    label="First Name"
                                                    name="firstName"
                                                    rules={[{ required: true, message: 'Please input the First Name!' }]}
                                                >
                                                    <Input placeholder="Please enter the first name!" disabled={!updateForm} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    label="Last Name"
                                                    name="lastName"
                                                    rules={[{ required: true, message: 'Please input the last name!' }]}
                                                >
                                                    <Input placeholder="Please enter last name here!" disabled={!updateForm} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="form-submit-row">
                                            {updateForm && (
                                                <Button className="view-btn" htmlType="submit">
                                                    {' '}
                                                    Update{' '}
                                                </Button>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                        {
                            !updateForm && user.type === 'manager' && <Col className="form-submit-row">
                                <Button
                                    className="view-btn"
                                    onClick={() => {
                                        return setUpdateForm(true);
                                    }}
                                >
                                    Edit Profile
                                </Button>
                            </Col>
                        }
                    </Row>
                </Col>
                <Col span={24} className="profile-form-height-col-password">
                    <Row className="profile-form">
                        <Col span={24}>
                            <Form
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 15 }}
                                requiredMark={false}
                                className="basic-form double-col-form"
                                layout={isMobile ? 'vertical' : 'horizontal'}
                                style={{ height: 'fit-content' }}
                                onFinish={handlePasswordUpdate}
                            >
                                <Row>
                                    <Divider orientation="left" orientationMargin={0} style={{ fontSize: '22px' }}>
                                        Change Your Password
                                    </Divider>
                                </Row>
                                <Row className="profile-form">
                                    <Col lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="Password"
                                            name="password"
                                            rules={[
                                                { required: true, message: 'Please enter your password' },
                                                { min: 8, message: 'Password must be at least 8 characters' },
                                            ]}
                                        >
                                            <Input.Password placeholder="Please enter the password" />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label="Confirm Password"
                                            name="newpassword"
                                            dependencies={['password']}
                                            rules={[
                                                { required: true, message: 'Please confirm your password' },
                                                ({ getFieldValue }) => {
                                                    return {
                                                        validator(_: any, value: any) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('Passwords do not match'));
                                                        },
                                                    };
                                                },
                                            ]}
                                        >
                                            <Input.Password placeholder="Please enter the new password" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="form-submit-row">
                                    <Button className="view-btn" htmlType="submit">
                                        {' '}
                                        Update Password{' '}
                                    </Button>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {!isMobile && <Footer />}
        </Layout>
    );
};

export default Profile;
